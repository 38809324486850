export function supplierOrderClassificationMappingsUrl(
  salesOrderClassificationMappingsPath: string
) {
  return salesOrderClassificationMappingsPath
}

export function createSupplierOrderClassificationMappingsPath(
  salesOrderClassificationMappingsPath: string
) {
  return salesOrderClassificationMappingsPath + `/new`
}

export function updateSupplierOrderClassificationMappingsUrl(
  salesOrderClassificationMappingsPath,
  mappingId
) {
  return salesOrderClassificationMappingsPath + `/${mappingId}`
}

export function editSupplierOrderClassificationUrl(
  salesOrderClassificationMappingsPath,
  mappingId
) {
  return salesOrderClassificationMappingsPath + `/${mappingId}/edit`
}

export function deleteSupplierOrderClassificationMappingPath(
  salesOrderClassificationMappingsPath: string,
  mappingId: string
) {
  return salesOrderClassificationMappingsPath + `/${mappingId}`
}
