import { SurveyQuestion } from "sharedTypes"

export const isAnswerLengthValid = (
  question: SurveyQuestion,
  answer: string
) => {
  const minimum = question.minimumLength || 1
  const maximum = question.maximumLength
  const answerLength = answer.trim().length
  return answerLength >= minimum && (!maximum || answerLength <= maximum)
}
