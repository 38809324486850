import React from "react"
import { CarrierAuthorization } from "../../../../../../graphql/__generated__/graphql"
import { AuthorizationDetailsDefinition } from "../constants/authorizationDetailsDefinitions"
import classNames from "classnames"
import * as styles from "./AuthorizationDetailsSection.module.scss"
import { get } from "lodash"

interface AuthorizationDetailsProps {
  authorization: CarrierAuthorization
  dataDefinition: AuthorizationDetailsDefinition
}

export const AuthorizationDetailsSection = ({
  authorization,
  dataDefinition,
}: AuthorizationDetailsProps) => {
  const getPropFromAuth = (prop: string) => {
    return get(authorization, prop) || "-"
  }

  return (
    <div className="d-flex flex-row canopy-mb-16x">
      <div
        className={classNames(
          "canopy-typography-heading-medium canopy-mr-10x",
          styles.sectionTitle
        )}
      >
        {dataDefinition.title}
      </div>
      <div className={styles.sectionBody}>
        {dataDefinition.data.map((data) => (
          <div key={data.title} className={styles.sectionBodyItem}>
            <div
              className={classNames(
                "canopy-typography-body-small canopy-typography-font-weight-bold",
                styles.sectionBodyTitle
              )}
            >
              {data.title}
            </div>
            <div
              className={classNames(
                "canopy-typography-body-small",
                styles.sectionBodyValue
              )}
            >
              {data.valueGetter
                ? data.valueGetter(authorization) || "-"
                : getPropFromAuth(data.key)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
