import React from "react"
import InternalClinicalOrganizationUserForm from "./InternalClinicalOrganizationUserForm"
import { User, ClinicalOrganizationsEmployment } from "sharedTypes"
import * as api from "./api"
import * as urls from "./urls"
import { navigate } from "utilities/navigation"

interface Props {
  defaultTimeZone: string
  user: User
  employments: ClinicalOrganizationsEmployment[]
}

function ClinicalOrganizationUserForm({
  user,
  defaultTimeZone,
  employments,
}: Props) {
  const createUser = (params) => {
    return api.createUser(params).then(() => {
      navigate(urls.usersUrl())
    })
  }
  const updateUser = (params) => {
    const { userId } = user
    return api.updateUser(userId, params).then(() => {
      navigate(urls.userUrl(userId ? userId : ""))
    })
  }
  return (
    <InternalClinicalOrganizationUserForm
      user={user}
      defaultTimeZone={defaultTimeZone}
      employments={employments}
      createUser={createUser}
      updateUser={updateUser}
    />
  )
}

export default ClinicalOrganizationUserForm
