import React, { useContext, useEffect } from "react"
import { RadioButtons } from "components/form"
import { ServiceTier, Supplier } from "sharedTypes"
import { PackageConfiguration } from "../../sharedTypes"
import { BasicSupplierHelpUrl } from "applications/Workflow/urls/external"
import {
  filterOutPreferredSuppliers,
  sortPreferredSuppliersByName,
  sortPreferredSuppliersByRank,
} from "../../utilities/supplier"
import { EventCategory, trackEvent } from "utilities/tracking"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import SupplierLabel from "./SupplierLabel"
import { AccordionDetails } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {
  Accordion,
  AccordionSummary,
} from "../SearchByProductSupplierSelector/Accordion"
import { PreferredSupplierContext } from "../../hooks/usePreferredSupplier"

export type SupplierSelectorProps = {
  packageConfiguration: PackageConfiguration
  showServiceTier: boolean
}

const SupplierSelector: React.FC<SupplierSelectorProps> = ({
  packageConfiguration,
  showServiceTier,
}) => {
  const { preferredSuppliers, omitOtherSupplierInfoText } = useContext(
    PreferredSupplierContext
  )
  const { availableSuppliers, supplier } = packageConfiguration
  const selectedSupplier =
    supplier &&
    availableSuppliers.find((s) => s.externalId === supplier.externalId)

  const preferredSupplierIds = preferredSuppliers.map(
    (ps) => ps.supplier.externalId
  )

  const hasPreferredSuppliers = preferredSupplierIds.length > 0

  const preferredSupplierSelected = preferredSupplierIds.includes(
    supplier?.externalId
  )

  useEffect(() => {
    if (preferredSupplierSelected) {
      trackEvent(EventCategory.PreferredSupplier, "autoselect")
    }
  }, [preferredSupplierSelected])

  const supplierOption = (supplier: Supplier) => {
    return {
      label: (
        <SupplierLabel
          isPreferredSupplier={preferredSupplierIds.includes(
            supplier.externalId
          )}
          supplier={supplier}
          showServiceTier={showServiceTier}
          hasPreferredSuppliers={hasPreferredSuppliers}
        />
      ),
      value: supplier.externalId,
    }
  }

  const notPreferredOptions = filterOutPreferredSuppliers(
    preferredSupplierIds,
    availableSuppliers
  ).map((s) => supplierOption(s))

  const sortFunction = preferredSuppliers.find((ps) => ps.rank !== null)
    ? sortPreferredSuppliersByRank
    : sortPreferredSuppliersByName

  const preferredOptions = preferredSuppliers
    .sort(sortFunction)
    .map((s) => supplierOption(s.supplier))

  const trackSupplierSelect = () => {
    trackEvent(EventCategory.ProductConfiguration, "supplier-radio-clicked")
  }

  const preferredSupplierLabel = hasPreferredSuppliers
    ? "Health Plan Preferred Suppliers"
    : "Health Plan Preferred Supplier"

  const radioSupertext = (forPreferredSupplier) => {
    const message = forPreferredSupplier
      ? "These suppliers are most likely to accept and fulfill your order due to insurance requirements."
      : "These suppliers may be unable to accept your order due to insurance requirements."

    return (
      <div className="canopy-pbe-12x d-flex flex-row align-items-end canopy-typography-body-small">
        <CanopyIcon
          name={forPreferredSupplier ? "shield-check" : "shield-slash"}
          size="medium"
          fill={
            forPreferredSupplier
              ? "canopyColorTextSuccess"
              : "canopyColorTextDisabled"
          }
          className="canopy-pie-2x"
        />
        {message}
      </div>
    )
  }

  return (
    <>
      {hasPreferredSuppliers && (
        <>
          <RadioButtons
            label={preferredSupplierLabel}
            name="supplierId"
            supertext={radioSupertext(hasPreferredSuppliers)}
            options={preferredOptions}
            onChange={trackSupplierSelect}
          />
          <Accordion className="canopy-mbe-12x">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className="font-weight-bold">Other Suppliers</span>
            </AccordionSummary>
            <AccordionDetails className="d-block">
              {/* TODO: remove omitOtherSupplierInfoText and related code in WF-28013 */}
              {!omitOtherSupplierInfoText && radioSupertext(false)}
              <RadioButtons
                name="supplierId"
                options={notPreferredOptions}
                onChange={trackSupplierSelect}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {!hasPreferredSuppliers && (
        <RadioButtons
          label="Supplier"
          name="supplierId"
          options={notPreferredOptions}
          onChange={trackSupplierSelect}
        />
      )}

      {selectedSupplier && selectedSupplier.serviceTier === ServiceTier.Basic && (
        <div className="well well-warning well-expand">
          {selectedSupplier.name} does not have messaging and status updates
          enabled on Parachute.&nbsp;
          <a href={BasicSupplierHelpUrl} target="_blank" rel="noreferrer">
            Learn More
          </a>
        </div>
      )}
    </>
  )
}

export default SupplierSelector
