import React from "react"
import {
  SendBackRecipient,
  RejectFormValues,
  SelectedRecipient,
} from "./sharedTypes"
import SendBackRecipientsSelector from "./SendBackRecipientsSelector"
import { validateSendBack } from "./utilities"
import { Form, Checkboxes, TextArea } from "components/form"
import { useFormikContext } from "formik"
import { ParachuteTheme, useStyles } from "themes/theme"
import { GroupedSendBackRecipients } from "applications/Workflow/containers/Review/components/SupplierSendBack/SupplierSendBackForm"

type Props = {
  cancel(): void
  defaultFacilityRecipient: SendBackRecipient
  hasFacilityRecipients: boolean
  searchFacilityRecipients(
    term: string
  ): Promise<SendBackRecipient[] | GroupedSendBackRecipients[]>
  supplierReject(
    values: RejectFormValues,
    setErrors: (errors: string) => void
  ): void
  setSearchRecipientRecommendations: (recommendationIds: string[]) => void
  selectedRecipients: SelectedRecipient[]
}
const customeReasonStyles = (theme: ParachuteTheme) => ({
  textArea: {
    marginTop: theme.spacing(1),
  },
})
const CustomReasonInput = () => {
  const classes = useStyles(customeReasonStyles)
  const { values } = useFormikContext<RejectFormValues>()
  if (!values.reasons.includes("Other")) return null
  return (
    <div className={classes.textArea}>
      <TextArea
        placeholder="Describe rejection reason"
        name="customReason"
        rows={1}
      />
    </div>
  )
}
const SupplierSendBackReasons = [
  {
    label: "Out of insurance network",
    value: "Out of insurance network",
  },
  {
    label: "Patient has received same and similar",
    value: "Patient has received same and similar",
  },
  {
    label: "Out of service area",
    value: "Out of service area",
  },
  {
    label: "Product or service not available",
    value: "Product or service not available",
  },
  {
    label: "Other",
    value: "Other",
    addOn: CustomReasonInput,
    addOnPosition: "inside",
  },
]

function SupplierRejectFormSection({
  cancel,
  defaultFacilityRecipient,
  hasFacilityRecipients,
  searchFacilityRecipients,
  supplierReject,
  setSearchRecipientRecommendations,
}: Props) {
  const initialValues: RejectFormValues = {
    instructions: "",
    reasons: [],
    recipients: [
      {
        channel: "followable",
        destination: defaultFacilityRecipient?.value || "",
      },
    ],
  }
  const renderSubmitButton = ({ disabled }) => (
    <button
      type="submit"
      className="btn btn-danger"
      data-analytics-context="Supplier Reject"
      data-analytics="supplier-reject-clicked"
      disabled={disabled}
    >
      Reject
    </button>
  )

  const onSubmit = (values: RejectFormValues, { setErrors }) => {
    supplierReject(values, setErrors)
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateSendBack}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ isValid, setFieldValue, values }) => (
        <>
          <p className="text-left">
            Are you sure you want to reject this order? You will lose access to
            the order and the facility will be able to choose a different
            supplier.
          </p>
          <SendBackRecipientsSelector
            setFieldValue={setFieldValue}
            defaultFacilityRecipient={defaultFacilityRecipient}
            searchFacilityRecipients={searchFacilityRecipients}
            hasFacilityRecipients={hasFacilityRecipients}
            showChannelSelector={false}
            setSearchRecipientRecommendations={
              setSearchRecipientRecommendations
            }
            selectedRecipients={values.recipients}
          />
          <Checkboxes
            options={SupplierSendBackReasons}
            unframed
            name="reasons"
            label="Reject Reason(s)"
          />
          <TextArea
            label="Note for Facility (Optional)"
            placeholder="Add a note or comment to the referral"
            name="instructions"
            rows={2}
          />
          <div className="text-right">
            <button type="button" className="btn btn-link" onClick={cancel}>
              Cancel
            </button>
            {renderSubmitButton({ disabled: !isValid })}
          </div>
        </>
      )}
    </Form>
  )
}

export default SupplierRejectFormSection
