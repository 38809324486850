import React, { useState, useCallback } from "react"
import { Field as FormikField } from "formik"
import {
  CanopyComboboxField,
  CanopyComboboxFieldProps,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import {
  buildOptionFromValue,
  buildUserGeneratedOption,
  getOptionValues,
} from "./utils"

export type ClassificationIdDropdownProps = {
  existingClassificationIds: string[]
  setFormValue: (field: string, value: string) => void
  formValue: string
  onBlur: () => void
  feedbackMessage?: CanopyComboboxFieldProps["feedbackMessage"]
}

const ClassificationIdDropdown: React.FC<ClassificationIdDropdownProps> = ({
  existingClassificationIds,
  setFormValue: setFormStateValue,
  formValue,
  onBlur,
  feedbackMessage,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<OptionItem[]>(
    existingClassificationIds.map(buildOptionFromValue)
  )
  const [filteredOptions, setFilteredOptions] = useState<OptionItem[]>(
    dropdownOptions
  )
  const [value, setValue] = useState<OptionItem>(
    buildOptionFromValue(formValue)
  )

  const filterOptions = useCallback(
    (enteredValue: string) => {
      if (enteredValue.trim().length > 0) {
        const filteredOptions = dropdownOptions.filter((option) =>
          option.value.toLowerCase().includes(enteredValue.toLowerCase())
        )

        if (!getOptionValues(filteredOptions).includes(enteredValue)) {
          const userGeneratedOption = buildUserGeneratedOption(enteredValue)
          filteredOptions.push(userGeneratedOption)
        }

        setFilteredOptions(filteredOptions)
      } else {
        // reset to the default set of options
        setFilteredOptions(dropdownOptions)
      }
    },
    [setFilteredOptions, dropdownOptions]
  )

  const handleOptionSelection = useCallback(
    (option: OptionItem | null) => {
      if (option && !getOptionValues(dropdownOptions).includes(option.value)) {
        // The set of options doesn't include this value, add it
        setDropdownOptions([...dropdownOptions, option])
      }

      if (option) {
        setValue({ label: option.value, value: option.value })
        setFormStateValue("classificationId", option.value)
      } else {
        setValue(buildOptionFromValue(""))
        setFormStateValue("classificationId", "")
      }
    },
    [dropdownOptions, setDropdownOptions, setFormStateValue]
  )

  return (
    <FormikField
      as={CanopyComboboxField}
      className="form-group"
      onChange={handleOptionSelection}
      onInputChange={filterOptions}
      options={filteredOptions}
      inputProps={{ type: "number" }}
      value={value}
      label="Classification ID"
      placeholder="Select or create a classification ID"
      required
      onBlur={onBlur}
      feedbackMessage={feedbackMessage}
    />
  )
}

export default ClassificationIdDropdown
