import React from "react"
import DataRow from "../../../../../../../components/DataRow"
import { CanopyButton } from "@parachutehealth/canopy-button"
import ReviewCard from "../../ReviewCard/ReviewCard"
import { useQuery } from "@apollo/client"
import { GetCarrierAuthorizationById } from "./graphql/getCarrierAuthorizationById"
import { sendError } from "../../../../../../../utilities/error"
import { carrierAuthorizationDetailsUrl } from "../../../../../urls"
import { format } from "../../../../../../../utilities/date"
import { withApollo } from "hocs/withApollo"

export interface CarrierAuthorizationSectionContentProps {
  carrierAuthorizationId: string
  expanded: boolean
}

const CarrierAuthorizationSectionContent = ({
  carrierAuthorizationId,
  expanded,
}: CarrierAuthorizationSectionContentProps) => {
  const { data } = useQuery(GetCarrierAuthorizationById, {
    variables: { id: carrierAuthorizationId },
    onError: () => {
      sendError(
        `Error fetching carrier authorization ${carrierAuthorizationId}`
      )
    },
  })

  const carrierAuthorization = data?.carrierAuthorization

  // Jira to improve and standardize UTC time handling https://parachutehealth.atlassian.net/browse/WF-28239
  const utcTime = (dateTimeString) => {
    const utcTime = new Date(dateTimeString)
    const timeZoneOffsetMS = utcTime.getTimezoneOffset() * 60 * 1000
    return utcTime.setTime(utcTime.getTime() + timeZoneOffsetMS)
  }
  const prettyPrintSubmitAt =
    carrierAuthorization?.requestSubmitDateTime &&
    format(
      utcTime(carrierAuthorization.requestSubmitDateTime),
      "MM/DD/YYYY, HH:mm UTC"
    )

  return (
    <ReviewCard
      title="Authorization"
      expanded={expanded}
      summary={carrierAuthorizationId}
    >
      <DataRow label="Request ID" value={carrierAuthorizationId} />
      <DataRow label="Submitted" value={prettyPrintSubmitAt || "-"} />
      <CanopyButton
        as="a"
        variant="secondary"
        size="small"
        href={carrierAuthorizationDetailsUrl(carrierAuthorizationId)}
        target="_blank"
        fullWidth
        className="canopy-mbs-8x"
        iconEnd="arrow-up-right-from-square"
      >
        View auth request
      </CanopyButton>
    </ReviewCard>
  )
}

export default withApollo(CarrierAuthorizationSectionContent)
