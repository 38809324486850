import React, { useState } from "react"
import chatDisabledPhone from "chat-disabled-phone.png"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  canopyColorTextDisabled,
  canopyColorInteractiveBackgroundPrimaryDefault,
} from "@parachutehealth/canopy-tokens-color"
import { EventCategory, trackEvent } from "utilities/tracking"
import Cookies from "js-cookie"
import { Nullable } from "sharedTypes"

const formatDate = (timestamp: string) => {
  const date = new Date(parseInt(timestamp))

  return date.toLocaleDateString("en-US", { month: "short", day: "numeric" })
}

const persistChatRequestedDate = (
  setChatRequestedDate: React.Dispatch<React.SetStateAction<Nullable<string>>>
) => {
  trackEvent(EventCategory.PatientPortalChat, "requested-patient-chat")
  const timestamp = Date.now().toString()

  Cookies.set("chatRequested", timestamp, {
    expires: 365,
  })

  setChatRequestedDate(timestamp || null)
}

const ChatDisabled: React.FC<{ supplierName: string }> = ({ supplierName }) => {
  const [chatRequestedDate, setChatRequestedDate] = useState<Nullable<string>>(
    () => {
      const cookie = Cookies.get("chatRequested")
      if (cookie) {
        return cookie
      } else {
        return null
      }
    }
  )

  return (
    <div className="h-75 canopy-px-12x d-flex flex-column justify-content-center align-items-center">
      <div style={{ maxWidth: "96%" }}>
        <img style={{ maxWidth: "100%" }} src={chatDisabledPhone} />
      </div>
      <h2 className="canopy-pbs-8x">Speed up your process!</h2>
      <p className="text-center">
        Delivered securely through the Order Tracker, patients are notified via
        SMS, with real-time read receipts showing when messages are opened.
        Teams can set up templates for common requests.
      </p>

      {!chatRequestedDate && (
        <CanopyButton
          onClick={() => persistChatRequestedDate(setChatRequestedDate)}
        >
          Request chat feature
        </CanopyButton>
      )}

      {chatRequestedDate && (
        <>
          <p style={{ textAlign: "center", color: canopyColorTextDisabled }}>
            {`Requested on ${formatDate(chatRequestedDate)}`}
          </p>
          <p
            style={{
              textAlign: "center",
              color: canopyColorInteractiveBackgroundPrimaryDefault,
            }}
          >
            {`A Parachute representative will reach out to an admin user at
           ${supplierName} to discuss activation`}
          </p>
        </>
      )}
    </div>
  )
}

export default ChatDisabled
