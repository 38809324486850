import React from "react"
import InternalCsrInboxStateAiDetails from "applications/CsrInboxStateAiDetails/components/InternalCsrInboxStateAiDetails"

type Props = {
  aiIntakeEnabled: boolean
  documentation: {
    title?: string
    summary?: string
    qualificationStatus?: "qualified" | "undetermined"
  }
}
const CsrInboxStateAiDetails = ({ aiIntakeEnabled, documentation }: Props) => {
  if (!aiIntakeEnabled) {
    return null
  }

  return <InternalCsrInboxStateAiDetails {...documentation} />
}

export default CsrInboxStateAiDetails
