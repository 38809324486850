// @team @facilitators
import { CatalogSearchType } from "../../../sharedTypes"

export const isProductSearchTab = (
  catalogSearchType: CatalogSearchType
): boolean => {
  return (
    catalogSearchType === CatalogSearchType.PackageFilter ||
    catalogSearchType === CatalogSearchType.CombinedSearchDefaultToProductSearch
  )
}

export const isCombinedCatalogSearchType = (
  combinedCatalogSearchEnabled: boolean,
  catalogSearchType: CatalogSearchType
): boolean => {
  if (
    catalogSearchType === CatalogSearchType.PackageFilter ||
    catalogSearchType === CatalogSearchType.SkuQuickAdd
  ) {
    return false
  }

  return combinedCatalogSearchEnabled
}
