import { post, get } from "services/api"
import { makeUrlProvider } from "./urls"
import { getEmployerId, getEmployerType, EmployerType } from "utilities/url"

export const makeApi = (employerType: EmployerType, employerId: string) => {
  const urls = makeUrlProvider(
    getEmployerType(employerType),
    getEmployerId(employerId)
  )
  return {
    createDmeOrder: createDmeOrder(urls.createDmeOrderUrl),
    patientValidate: patientValidate(urls.patientValidateUrl),
    fetchSuppliers: fetchSuppliers(urls.suppliersUrl),
  }
}

type CreateOrderParams = {
  clinicalFacilityId: string
  doctorId: string
  masterPatientId: string | undefined | null
  patient: Record<string, any> | undefined | null
  documentIds: string[]
  supplierLegalEntityId: string | undefined | null
  externalSystemBranchId: string | undefined | null
  externalSalesUserId: string | undefined | null
  legalEntityAssignmentMethod: string | undefined | null
}

const createDmeOrder = (url) => ({
  clinicalFacilityId,
  doctorId,
  masterPatientId = null,
  patient = {},
  documentIds = [],
  supplierLegalEntityId = null,
  externalSystemBranchId = null,
  externalSalesUserId = null,
  legalEntityAssignmentMethod = null,
}: CreateOrderParams) => {
  const params = {
    clinicalFacilityId,
    doctorId,
    documentIds,
    masterPatientId,
    patient,
    supplierLegalEntityId,
    externalSystemBranchId,
    externalSalesUserId,
    legalEntityAssignmentMethod,
  }

  return post(url, {
    dmeOrder: params,
  }).catch(({ response }) => Promise.reject(response.data))
}

type PreCreateOrderWithoutFacilityParams = {
  masterPatientId: string | undefined | null
  patient: Record<string, any> | undefined | null
}
export const preCreateOrder = (
  url,
  params: CreateOrderParams | PreCreateOrderWithoutFacilityParams
) =>
  post(url, { order: params }).catch(({ response }) =>
    Promise.reject(response.data)
  )

const patientValidate = (url) => (patient) => {
  return post(url, { patient }).catch(({ response }) =>
    Promise.reject(response.data)
  )
}

const fetchSuppliers = (url) => () => {
  return get(url).catch(({ response }) => Promise.reject(response.data))
}
