// @ts-strict-ignore
import React, { useState, useEffect } from "react"
import { Field, FormikErrors } from "formik"
import { wrap } from "utilities/array"
import MostCommonCallout from "../components/MostCommonCallout"
import Checkboxes from "components/form/Checkboxes"
import TextArea from "components/form/TextArea"
import { SurveyAnswerType, SurveyOption, SurveyQuestion } from "sharedTypes"
import { InitialValues, Values } from "../sharedTypes"
import { isTab, isEnter } from "utilities/browser/event"
import { SaveButton } from "../components/SaveButton"
import QuestionTitle from "../components/QuestionTitle"

const OTHER_VALUE = "OTHER"

// parsing the string is currently the IQ recommended way of identifying this choice option hence the below
const OPTUM_ADD_COMMENT = "Other clinical information (add comment)"

function isDefaultChoice(choice, question) {
  return (
    question.defaultChoices &&
    question.defaultChoices.find(
      (defaultChoice) => defaultChoice === choice.code
    )
  )
}

const createAddOn = (field, form, choiceCode, dirty) => {
  if (!field.value.includes(choiceCode)) {
    return null
  }
  return (
    <TextArea
      name="otherAnswer"
      onKeyDown={(event) =>
        dirty && (isTab(event) || isEnter(event)) && form.handleSubmit()
      }
    />
  )
}

const getOptions = (question, answers, autoFocus, showMostCommon, dirty) => {
  answers = wrap(answers)
  const options: SurveyOption[] = question.choices.map((choice) => {
    const option: SurveyOption = {
      label: choice.label,
      value: choice.code,
    }
    if (showMostCommon && isDefaultChoice(choice, question)) {
      option.callout = <MostCommonCallout />
    }
    if (choice.label === OPTUM_ADD_COMMENT) {
      option.addOnPosition = "inside"
      option.addOn = ({ field, form }) =>
        createAddOn(field, form, choice.code, dirty)
    }
    return option
  })

  // non-optum flow
  if (question.other) {
    options.push({
      label: question.other,
      value: OTHER_VALUE,
      addOnPosition: "inside",
      addOn: ({ field, form }) => createAddOn(field, form, OTHER_VALUE, dirty),
    })
  }

  return options
}

type Props = {
  question: SurveyQuestion
  setFieldValue(field: string, value, shouldValidate?: boolean): void
  inputId: string
  isSubmitting: boolean
  showMostCommon?: boolean
  dirty: boolean
  requiresConfirmation: boolean
  errors: FormikErrors<Values>
  valid: boolean
  missingRequiredInformation: boolean
}

const validate = (values: Values) => {
  const answerValue = values.answerValue as string[]
  if (answerValue.includes(OTHER_VALUE) && !values.otherAnswer) {
    return { otherAnswer: "is invalid" }
  }
  return {}
}

const getInitialValues: (question: SurveyQuestion) => InitialValues = (
  question
) => {
  const staticChoices: string[] = question.choices
    ? question.choices.map(({ code }) => code)
    : []

  let allAnswers: string[] | number
  if (typeof question.answerValue === "string") {
    allAnswers = [question.answerValue]
  } else {
    allAnswers = question.answerValue || []
  }

  let otherAnswer: string | undefined
  let answerValue: string[] = []
  if (allAnswers && typeof allAnswers !== "number") {
    otherAnswer = allAnswers.find((a) => !staticChoices.includes(a)) || ""
    answerValue = allAnswers.map((answer) =>
      answer === otherAnswer ? OTHER_VALUE : answer
    )
  }

  const na = question.answerType === SurveyAnswerType.Na

  return { na, otherAnswer, answerValue }
}

const formatAnswer = (values: Values) => {
  const { na, otherAnswer } = values
  if (na) {
    return { answerType: SurveyAnswerType.Na, answerValue: null }
  }

  const answerValue: string[] = values.answerValue as string[]
  const answers: (string | null | undefined)[] = [
    ...answerValue.filter((a) => a !== OTHER_VALUE),
    answerValue.includes(OTHER_VALUE) ? otherAnswer : null,
  ].filter(Boolean)

  return { answerType: SurveyAnswerType.Value, answerValue: answers }
}

const QuestionSelectMulti: React.SFC<Props> = ({
  question,
  setFieldValue,
  inputId,
  isSubmitting,
  showMostCommon,
  dirty,
  errors,
  valid,
  missingRequiredInformation,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true)
  useEffect(() => {
    setIsFirstRender(false)
  }, [])

  const options = getOptions(
    question,
    question.answerValue || [],
    !isFirstRender,
    showMostCommon,
    dirty
  )

  const renderSaveButton: boolean = dirty && !errors.answerValue

  return (
    <>
      <QuestionTitle
        questionTitle={question.title}
        questionText={question.text}
        tooltips={question.tooltips}
        questionId={inputId}
      />
      <Checkboxes
        id={inputId}
        name="answerValue"
        options={options}
        onChange={() => {
          setFieldValue("na", false)
        }}
        disabled={isSubmitting}
      />
      {question.naEnabled && (
        <Field name="na">
          {({ form, field }) => (
            <>
              <hr className="canopy-mbs-0 canopy-mbe-8x" />
              <div className="checkbox radio unframed primary block">
                <input
                  {...field}
                  type="checkbox"
                  id={`${inputId}-na`}
                  checked={field.value}
                  onChange={(event) => {
                    if (event.target.checked) {
                      form.setFieldValue("answerValue", [])
                      form.setFieldValue("otherAnswer", "")
                    }
                    field.onChange(event)
                  }}
                />
                <label htmlFor={`${inputId}-na`}>
                  {question.naLabel || "None of these"}
                  {showMostCommon &&
                    question.defaultChoices?.includes("na") && (
                      <MostCommonCallout />
                    )}
                </label>
              </div>
            </>
          )}
        </Field>
      )}
      {renderSaveButton && (
        <SaveButton
          isSubmitting={isSubmitting}
          valid={valid}
          missingRequiredInformation={missingRequiredInformation}
          question={question}
        />
      )}
    </>
  )
}

export {
  QuestionSelectMulti as QuestionComponent,
  validate,
  getInitialValues,
  formatAnswer,
}
