// @team @cat-crew
import React, { ComponentProps, createContext, useState } from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { canopySpace4X } from "@parachutehealth/canopy-tokens-space"

type NoticeContext = {
  showNotice: (
    title: ComponentProps<typeof CanopyNotice>["title"],
    variant: ComponentProps<typeof CanopyNotice>["variant"],
    actions?: ComponentProps<typeof CanopyNotice>["actions"],
    closable?: ComponentProps<typeof CanopyNotice>["closable"]
  ) => void
  clearNotice: () => void
}

export const NoticeContext = createContext<NoticeContext>({
  showNotice: () => {},
  clearNotice: () => {},
})

export type NoticeState = {
  open: boolean
  title: ComponentProps<typeof CanopyNotice>["title"]
  variant: ComponentProps<typeof CanopyNotice>["variant"]
  actions?: ComponentProps<typeof CanopyNotice>["actions"]
  closable?: ComponentProps<typeof CanopyNotice>["closable"]
}

export const NoticeProvider = ({ children }) => {
  const [noticeState, setNoticeState] = useState<NoticeState>({
    open: false,
    title: "",
    variant: "success",
    closable: false,
  })

  const showNotice = (
    title: ComponentProps<typeof CanopyNotice>["title"],
    variant: ComponentProps<typeof CanopyNotice>["variant"],
    actions?: ComponentProps<typeof CanopyNotice>["actions"],
    closable?: ComponentProps<typeof CanopyNotice>["closable"]
  ) => {
    setNoticeState({ open: true, title, variant, actions, closable })
  }

  const clearNotice = () => {
    setNoticeState({
      ...noticeState,
      open: false,
      title: "",
      actions: undefined,
      closable: undefined,
    })
  }

  // Only discovery or success Notices can be closed
  // Error, warning, and info Notices should persist as the information is important to the user
  const isClosable =
    noticeState.variant === "discovery" || noticeState.variant === "success"

  return (
    <NoticeContext.Provider value={{ showNotice, clearNotice }}>
      {noticeState.open &&
        (isClosable ? (
          <CanopyNotice
            onClose={() => setNoticeState({ ...noticeState, open: false })}
            closable={noticeState.closable}
            variant={
              // TS isn't accepting the isClosable check above as satisfactory that it's only "discovery" or "success",
              // Adding redundant check here to satisfy it
              noticeState.variant === "discovery" ? "discovery" : "success"
            }
            actions={noticeState.actions}
            style={{
              marginBottom: canopySpace4X,
            }}
            title={noticeState.title}
          />
        ) : (
          <CanopyNotice
            variant={noticeState.variant}
            actions={noticeState.actions}
            style={{
              marginBottom: canopySpace4X,
            }}
            title={noticeState.title}
          />
        ))}
      {children}
    </NoticeContext.Provider>
  )
}

const wrap = (Component) => (props) => (
  <NoticeProvider>
    <Component {...props} />
  </NoticeProvider>
)

export const withNoticeProvider = (Component) => wrap(Component)
