import { get, put } from "services/api"
import { searchDiagnosesUrl, updateDiagnosesUrl } from "../../../urls"
import { ResourceUpdateResponse } from "../api"

export type Diagnosis = {
  codeId: string
  description: string
  codeLabel: string
}

export const updateDiagnoses = async (
  csrInboxStateId: string,
  supplierOrganizationId: string,
  diagnoses: { codeId: string }[]
): Promise<ResourceUpdateResponse<keyof Diagnosis>> => {
  return put(updateDiagnosesUrl(supplierOrganizationId, csrInboxStateId), {
    diagnoses,
  })
    .then(() => ({ success: true }))
    .catch((e) => ({ errors: e.response?.data?.errors || {} }))
}

export const getDiagnoses = async (
  csrInjsonboxStateId: string,
  supplierOrganizationId: string,
  searchTerm: string
): Promise<{ codeId: string; description: string; codeLabel: string }[]> => {
  return get(searchDiagnosesUrl(supplierOrganizationId, csrInjsonboxStateId), {
    searchTerm: searchTerm,
  })
    .then((res) => {
      return (
        res.data?.map((a) => ({
          codeId: a.code,
          description: a.description,
          codeLabel: a.codeLabel,
        })) || []
      )
    })
    .catch(() => [])
}
