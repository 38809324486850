import React from "react"
import ActionsTitleSection, {
  ActionsTitleSectionProps,
} from "./ActionsTitleSection"
import OrderAlerts, { Props as orderAlertsProps } from "./OrderAlerts"
import { Permissions } from "../../sharedTypes"
import { DmeOrder, Employer, EmployerType, OrderStatus } from "sharedTypes"
import classNames from "classnames"
import cx from "classnames"
import SubmitButton from "../SubmitButton"
import { Link } from "react-router-dom"
import {
  reviewRejectPath,
  reviewSendBackFacilityWarningPath,
  reviewSendBackPath,
  supplierTransferPath,
} from "applications/Workflow/routes"
import { EventCategory, trackEvent } from "utilities/tracking"
import SupplierEditButton from "../SupplierEditButton"
import GenerateInviteLinkButton from "../GenerateInviteLinkButton"
import ActionButton from "../ActionButton"
import ReorderForm from "applications/PatientProfile/components/ReorderForm"
import AuthRequestButton from "../AuthRequestButton"
import { useFeatureFlags } from "components/FeatureFlagContext"

type Props = {
  dmeOrder: DmeOrder
  currentEmployer: Employer
  actionTitleProps: ActionsTitleSectionProps
  orderAlertsProps: orderAlertsProps
  showReorder: boolean
  showSubmit: boolean
  canSubmit: boolean
  permissions: Permissions
  supplierEdit(): Promise<void>
  supplierUnaccept(): Promise<void>
  submit(): Promise<void>
  submissionPending: boolean
}

const SupplierActions: React.FC<Props> = (props) => {
  const {
    dmeOrder,
    currentEmployer,
    actionTitleProps: actionTitleProps,
    orderAlertsProps,
    showReorder,
    showSubmit,
    canSubmit,
    permissions,
    supplierEdit,
    supplierUnaccept,
    submit,
    submissionPending,
  } = props

  const warnUserBeforeSendBack =
    dmeOrder.createdVia === "supplier_api" &&
    dmeOrder.clinicalFacility.tempFacilityOrDoctorOffice

  const sendBackButtonLinkPath = warnUserBeforeSendBack
    ? reviewSendBackFacilityWarningPath()
    : reviewSendBackPath()

  const { isFeatureEnabled } = useFeatureFlags()

  const showAuthRequest =
    dmeOrder.orderStatus === OrderStatus.Accepted &&
    currentEmployer.employerType === EmployerType.Supplier &&
    isFeatureEnabled("authRequestsPage")

  return (
    <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
      <div className="card">
        <div className="horizontal-divider">
          <ActionsTitleSection {...actionTitleProps} />
        </div>
        <div className="horizontal-divider">
          <OrderAlerts {...orderAlertsProps} />
        </div>
        <div>
          {showAuthRequest && (
            <div className="well-wide">
              <AuthRequestButton
                carrierAuthorizationId={
                  dmeOrder.parachuteInitiatedCarrierAuthorizationId
                }
                dmeOrderId={dmeOrder.id}
              />
            </div>
          )}
          {showReorder && (
            <div className="well-wide">
              <ReorderForm
                dmeOrderId={dmeOrder.id}
                buttonClassName={classNames(
                  "btn-block",
                  showAuthRequest ? "btn-brand-o" : "btn-brand"
                )}
              />
            </div>
          )}
          {showSubmit && (
            <SubmitButton
              submissionPending={submissionPending}
              currentEmployer={currentEmployer}
              submit={submit}
              disabled={!canSubmit}
              supplierGeneratedPriorToPushBack={
                dmeOrder.supplierGeneratedPriorToPushBack
              }
            />
          )}
          {permissions.supplierSendBack && (
            <Link
              className={classNames("btn btn-block", {
                "btn-brand-o": !dmeOrder.supplierGeneratedPriorToPushBack,
                "btn-brand": dmeOrder.supplierGeneratedPriorToPushBack,
                disabled: submissionPending,
              })}
              to={sendBackButtonLinkPath}
            >
              Push Order to Facility
            </Link>
          )}
          {permissions.showSupplierTransfer && (
            <Link
              className={classNames("btn btn-block", {
                "btn-brand-o":
                  !dmeOrder.supplierGeneratedPriorToPushBack &&
                  permissions.enableSupplierTransfer,
                "btn-brand":
                  dmeOrder.supplierGeneratedPriorToPushBack &&
                  permissions.enableSupplierTransfer,
                disabled:
                  !permissions.enableSupplierTransfer || submissionPending,
              })}
              to={supplierTransferPath()}
              onClick={() =>
                trackEvent(
                  EventCategory.SupplierTransfer,
                  "supplier-transfer-modal-opened"
                )
              }
            >
              Transfer to Another Supplier
            </Link>
          )}
          {permissions.supplierSendBack && permissions.supplierReject && (
            <Link
              className={classNames("btn btn-block text-danger border-0", {
                disabled: submissionPending,
              })}
              to={reviewRejectPath()}
            >
              Reject Order
            </Link>
          )}
          {permissions.supplierEdit && (
            <SupplierEditButton onClick={supplierEdit} />
          )}
          {permissions.supplierGenerateInvite && <GenerateInviteLinkButton />}
          {permissions.supplierUnaccept && (
            <ActionButton
              className="btn btn-brand-o btn-block"
              onClick={supplierUnaccept}
              type="button"
            >
              Send Back to Review
            </ActionButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default SupplierActions
