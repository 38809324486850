// @ts-strict-ignore
import React from "react"
import { Field, FormikValues } from "formik"
import TextLengthCheck from "../components/TextLengthCheck"
import Checkbox from "../form/Checkbox"
import { isTabOrEnter } from "utilities/browser/event"
import TextArea from "components/TextArea"
import { SurveyAnswerType, SurveyQuestion } from "sharedTypes"
import ErrorText from "../../../../../components/form/ErrorText"
import cx from "classnames"
import { SaveButton } from "../components/SaveButton"
import QuestionTitle from "../components/QuestionTitle"
import { isAnswerLengthValid } from "../utilities/validation"
import { InitialValues, Values } from "../sharedTypes"

type Props = {
  dirty: boolean
  question: SurveyQuestion
  values: FormikValues
  inputId: string
  isSubmitting: boolean
  valid: boolean
  missingRequiredInformation: boolean
}

const validate = (values: Values, question: SurveyQuestion) => {
  const isValid =
    isAnswerLengthValid(question, values.answerValue as string) || values.na
  if (isValid) {
    return {}
  }
  return { answerValue: "Field is required" }
}

const getInitialValues: (question: SurveyQuestion) => InitialValues = (
  question
) => {
  let answerValue: string = ""
  let na: boolean = false
  if (question.answerType === SurveyAnswerType.Na) {
    na = true
  } else {
    answerValue = (question.answerValue as string) || ""
  }
  return { answerValue, na }
}

const formatAnswer = (values: Values) => {
  const { answerValue, na } = values
  if (na) {
    return { answerType: SurveyAnswerType.Na, answerValue: null }
  }
  return { answerValue, answerType: SurveyAnswerType.Value }
}

const QuestionInputText = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      question,
      inputId,
      dirty,
      isSubmitting,
      valid,
      missingRequiredInformation,
    },
    ref
  ) => {
    return (
      <>
        <Field name="answerValue">
          {({ form, field }) => (
            <div
              className={cx("form-group", {
                "has-error": form.errors.answerValue && !form.values.na,
              })}
            >
              <QuestionTitle
                questionTitle={question.title}
                questionText={question.text}
                tooltips={question.tooltips}
                questionId={inputId}
              />
              <TextArea
                ref={ref}
                {...field}
                id={inputId}
                className="form-control textarea"
                disabled={form.values.na || form.isSubmitting}
                onKeyDown={(event) => {
                  if (isTabOrEnter(event)) {
                    form.handleSubmit()
                  }
                }}
              />
              {!question.naEnabled && (
                <ErrorText error={form.errors.answerValue} />
              )}
              <TextLengthCheck question={question} answer={field.value} />
            </div>
          )}
        </Field>
        {question.naEnabled && (
          <Field name="na">
            {({ form, field }) => (
              <Checkbox
                {...field}
                id={`na-${question.questionId}`}
                checked={!!form.values.na}
                label={question.naLabel}
                onChange={(checked) => {
                  form.setFieldValue("answerValue", "")
                  form.setFieldValue("na", checked)
                }}
              />
            )}
          </Field>
        )}
        {dirty && (
          <SaveButton
            isSubmitting={isSubmitting}
            valid={valid}
            missingRequiredInformation={missingRequiredInformation}
            question={question}
          />
        )}
      </>
    )
  }
)

export {
  QuestionInputText as QuestionComponent,
  validate,
  getInitialValues,
  formatAnswer,
}
