import React from "react"
import { FormikErrors } from "formik"
import { useFormikParachute } from "../../../../../shared/hooks/useFormikParachute/useFormikParachute"
import { ConsignmentCloset } from "sharedTypes"
import { closetLabel } from "../utils"
import { handleError } from "utilities/error"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"

type ConsignmentClosetNudgeModalProps = {
  /**
   * An array of options displayed as radio buttons on the form
   */
  closets: ConsignmentCloset[]
  /**
   * Function called when "Skip for now" or close buttons are clicked
   */
  onClose: () => void
  /**
   * Function called when the form is submitted (and all fields are valid)
   */
  onSubmit: (id: string) => Promise<void>
  /**
   * When true, displays the modal
   */
  open: boolean
}

interface ConsignmentClosetNudgeModalFields {
  consignmentClosetId?: string
}

const ConsignmentClosetNudgeModal = (
  props: ConsignmentClosetNudgeModalProps
): JSX.Element => {
  const { closets, open, onClose, onSubmit } = props

  const formik = useFormikParachute<ConsignmentClosetNudgeModalFields>({
    initialValues: {
      consignmentClosetId: "",
    },
    onSubmit: (values) => {
      if (values.consignmentClosetId) {
        onSubmit(values.consignmentClosetId).catch(handleError)
      }
    },
    validate: (values) => {
      const errors: FormikErrors<ConsignmentClosetNudgeModalFields> = {}
      if (!values.consignmentClosetId) {
        errors.consignmentClosetId =
          "Set a default consignment closet or skip for now"
      }
      return errors
    },
  })

  const closetOptions = closets.map((closet) => ({
    label: closetLabel(closet),
    value: closet.externalId,
  }))

  return (
    <CanopyDialogModal
      header="Ordering consignment products?"
      onClose={onClose}
      open={open}
      primaryFooterButton={
        <CanopyButton
          form="consignmentClosetNudgeModalForm"
          loading={formik.isSubmitting}
          loadingText="Saving default consignment closet..."
          type="submit"
        >
          Save
        </CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton onClick={onClose} variant="secondary">
          Skip for now
        </CanopyButton>
      }
    >
      <CanopyForm
        id="consignmentClosetNudgeModalForm"
        onSubmit={formik.handleSubmit}
      >
        <CanopyRadioInputField
          feedbackMessage={formik.errors.consignmentClosetId}
          label="Set a default consignment closet"
          name="consignmentClosetId"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          options={closetOptions}
        />
      </CanopyForm>
    </CanopyDialogModal>
  )
}

export default ConsignmentClosetNudgeModal
