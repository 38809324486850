import React from "react"
import { ClinicalFacility, EmployerType, Severity } from "sharedTypes"
import { DashboardDmeOrder } from "../../../sharedTypes"
import classNames from "classnames"
import ProgressDonut from "components/ProgressDonut"
import DeliveryStatus from "components/DeliveryStatus"
import SignatureStatus from "components/SignatureStatus"
import DeliveryDates from "components/DeliveryDates"
import { workflowsUrl } from "applications/Workflow/urls"
import { fullName } from "utilities/person"
import { getDeliveryStatusSeverity } from "utilities/deliveryStatus"
import { getSignatureStatusSeverity } from "utilities/signatureStatus"
import { getSeverity } from "utilities/swatch"
import LastComment from "./LastComment"
import MissingDocument from "components/MissingDocument"

type Props = {
  dmeOrder: DashboardDmeOrder
  clinicalFacility: ClinicalFacility
  unreadEventCount?: number
  showProgress?: boolean
  showSwatch?: boolean
  showMissingDocument?: boolean
}

function OrderCard({
  clinicalFacility,
  dmeOrder,
  unreadEventCount,
  showProgress,
  showSwatch,
  showMissingDocument,
}: Props) {
  const { fulfillments, createdAt } = dmeOrder
  const deliveryStatuses = fulfillments.map(
    (fulfillment) => fulfillment.deliveryStatus
  )
  const deliveryStatusSeverity =
    dmeOrder.status === "Snoozed"
      ? Severity.Snoozed
      : getSeverity(deliveryStatuses.map(getDeliveryStatusSeverity))
  const severity = getSeverity([
    getSignatureStatusSeverity(dmeOrder.signatureStatus),
    deliveryStatusSeverity,
  ])
  const deliveryStatusText =
    dmeOrder.status === "Snoozed"
      ? `Snoozed until ${dmeOrder.snooze?.snoozedUntil}`
      : dmeOrder.status

  return (
    <a
      className={classNames(
        "card canopy-m-0 canopy-mbe-8x",
        showSwatch && `card-${severity}`
      )}
      href={workflowsUrl(dmeOrder.externalId)}
      data-testid="dashboard-order-card"
    >
      {unreadEventCount && <div className="card-badge">{unreadEventCount}</div>}
      <div className="clearfix">
        <div className="float-left">
          <h4 className="bold">{fullName(dmeOrder.patient)}</h4>
        </div>
        <div className="float-right">
          {showProgress && (
            <ProgressDonut
              className="progress-circle-s v-align-top canopy-mis-2x"
              percentage={dmeOrder.progress}
            />
          )}
        </div>
      </div>
      <DeliveryStatus
        statusLevel={deliveryStatusSeverity}
        statusText={deliveryStatusText}
      />
      <SignatureStatus
        signatureStatus={dmeOrder.signatureStatus}
        orderStatus={dmeOrder.orderStatus}
        employerType={EmployerType.ClinicalFacility}
      />
      {showMissingDocument && (
        <MissingDocument
          missingDocument={dmeOrder.unsatisfiedManualDocumentation}
        />
      )}
      {dmeOrder.followers.length > 0 && (
        <div className="font-subparagraph canopy-mbe-2x">
          <span className="color-dark-gray canopy-mie-2x">Followers:</span>
          {dmeOrder.followers.map((follower) => follower.name).join(" | ")}
        </div>
      )}
      <div className="font-subparagraph canopy-mbe-2x">
        <span className="color-dark-gray canopy-mie-2x">Delivery:</span>
        <DeliveryDates fulfillments={fulfillments} createdAt={createdAt} />
      </div>
      {clinicalFacility.multiSupplier && dmeOrder.supplier && (
        <div className="font-subparagraph canopy-mbe-2x">
          <span className="color-dark-gray canopy-mie-2x">Supplier:</span>
          {dmeOrder.supplier.name}
        </div>
      )}
      {dmeOrder.categories.length > 0 && (
        <div className="font-subparagraph canopy-mbe-2x">
          <span className="color-dark-gray canopy-mie-2x">Categories:</span>
          {dmeOrder.categories.join(", ")}
        </div>
      )}
      {dmeOrder.lastComment && (
        <LastComment lastComment={dmeOrder.lastComment} />
      )}
    </a>
  )
}

export default OrderCard
