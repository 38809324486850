import {
  DmeOrder,
  LineItemGroup,
  PreferredSupplier,
  Supplier,
} from "sharedTypes"
import { getPreferredSuppliers } from "../../../api"
import {
  primaryPolicyFor,
  stateForBillingRouting,
  zipForBillingRouting,
} from "applications/Workflow/utilities/payment"

type PreferredSupplierInfo = {
  order: DmeOrder
  lineItemGroup: LineItemGroup
}

export function filterOutPreferredSuppliers(
  preferredSupplierIds: string[],
  availableSuppliers: Supplier[]
): Supplier[] {
  return availableSuppliers.filter(
    (s) => !preferredSupplierIds.includes(s.externalId)
  )
}

export function fetchPreferredSuppliers({
  order,
  lineItemGroup,
}: PreferredSupplierInfo): Promise<{
  preferredSuppliers: PreferredSupplier[]
  omitOtherSupplierInfoText: boolean
}> {
  const primaryPolicy = primaryPolicyFor(order)

  if (!primaryPolicy?.insuranceProductId) {
    return Promise.resolve({
      preferredSuppliers: [],
      omitOtherSupplierInfoText: false,
    })
  }

  const preferredSuppliersParams = {
    packageId: lineItemGroup.packageId,
    state: stateForBillingRouting(order),
    zip: zipForBillingRouting(order),
  }

  return getPreferredSuppliers(
    primaryPolicy.insuranceProductId,
    preferredSuppliersParams
  )
}

export function sortSuppliersByName(a: Supplier, b: Supplier): number {
  return a.name.localeCompare(b.name)
}

export function sortPreferredSuppliersByName(
  a: PreferredSupplier,
  b: PreferredSupplier
): number {
  return sortSuppliersByName(a.supplier, b.supplier)
}

export function sortPreferredSuppliersByRank(
  a: PreferredSupplier,
  b: PreferredSupplier
): number {
  return (a.rank || Infinity) - (b.rank || Infinity)
}
