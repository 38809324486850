import React, { useEffect, useState } from "react"
import InternalCarrierAuthorizationDashboard from "./InternalCarrierAuthorizationDashboard"
import { handleError } from "../../utilities/error"
import { Filters } from "./sharedTypes"
import { Record } from "components/SelectFilter"
import { unique } from "../../utilities/array"
import { ApiContext } from "./apiContext"
import { makeApi, fetchInitialDashboardData } from "./api"
import withInitialData from "components/withInitialData"

type Props = {
  supplierId: string
  initialData: {
    statuses: Record[]
    filters: Filters
    counts: { [attr: string]: number }
  }
}

const CarrierAuthorizationDashboard = (props: Props) => {
  const supplierId = props.supplierId
  const [records, setRecords] = useState([])
  const [filters, setFilters] = useState(props.initialData.filters)
  const [loading, setLoading] = useState(true)
  const [counts, setCounts] = useState(props.initialData.counts)
  const api = makeApi(supplierId)

  const getRecords = (values, options) => {
    setLoading(true)
    setFilters(values)
    return api
      .getAuthorizations(values)
      .then((response) => {
        if (options?.append) {
          setRecords((records) => unique([...records, ...response.records]))
        } else {
          setRecords(response.records)
          setFilters(response.filters)
          setCounts(response.counts)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        handleError(error)
      })
  }

  useEffect(() => {
    getRecords(filters, { append: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId])

  return (
    <ApiContext.Provider value={api}>
      <InternalCarrierAuthorizationDashboard
        authorizations={records}
        loading={loading}
        filters={filters}
        counts={counts}
        getRecords={getRecords}
        statuses={props.initialData.statuses}
      />
    </ApiContext.Provider>
  )
}

const fetchInitialData = (props): Promise<Props["initialData"]> => {
  return fetchInitialDashboardData(props).then((response) => response)
}

export default withInitialData(fetchInitialData)(CarrierAuthorizationDashboard)
