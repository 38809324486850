import React from "react"
import Modal from "components/Modal"
import Button from "components/form/Button"
import { FacilityOption, SupplierLegalEntity } from "sharedTypes"
import DoctorSuggestion from "./DoctorSuggestion"
import { fullName } from "utilities/person"
import { Form, Select } from "components/form"
import { Employee } from "../sharedTypes"
import FacilitySelect from "./FacilitySelect"
import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"

type Props = {
  fetchDoctors(
    term
  ): Promise<
    [{ doctorId: string; firstName: string; lastName: string; npi: string }]
  >
  fetchFacilities(term): Promise<FacilityOption[]>
  fetchLegalEntities(term): Promise<[{ id: string; name: string }]>
  fetchExternalSystemBranches(term): Promise<[{ id: string; name: string }]>
  fetchEmployees(
    term
  ): Promise<[{ userId: string; firstName: string; lastName: string }]>
  isSubmitting: boolean
  closeModal(): void
  showModal: boolean
  hasMissingFields: boolean
  modalTitle: string
  showFacilityError: boolean
  recommendedFacilities: FacilityOption[]
  requiresExternalSystemBranch: boolean
  selectFacility(clinicalFacilityId): void
  selectDoctor(doctorId): void
  selectLegalEntity(legalEntityId): void
  selectExternalSystemBranch(externalSystemBranchId): void
  selectSalesRep(salesRepId): void
  showDoctorSelect: boolean
  showFulfillmentAgreementFields: boolean
  submit(): void
  submitButtonText: string
  defaultDoctor?: {
    doctorId: string
    firstName: string
    lastName: string
    npi: string
    clinicalOrganizations: string[]
  }
  defaultLegalEntity: SupplierLegalEntity
  defaultSalesRep: Employee
  requiresLegalEntity: boolean
  hasInvalidFacility?: boolean
}
export const SELECT_DOCTOR_PLACEHOLDER = "Search by name or NPI..."
export const SELECT_FACILITY_PLACEHOLDER =
  "Search by facility name, address or NPI..."
export const SELECT_LEGAL_ENTITY_PLACEHOLDER = "Search by name"
export const SELECT_BRIGHT_TREE_BRANCH_PLACEHOLDER =
  "Select Brightree Branch for this Facility"
export const SELECT_SALES_REP_PLACEHOLDER =
  "Search for sales rep by name or email"

const DoctorSelect = ({
  disabled,
  fetchDoctors,
  defaultDoctor,
  selectDoctor,
}) => {
  const doctorLabel = (doctor: {
    firstName: string
    lastName: string
    npi: string
  }) => `${fullName(doctor)} - ${doctor.npi}`
  const getDoctors = (term) => {
    return fetchDoctors(term).then((results) => {
      return results.map((result) => ({
        label: doctorLabel(result),
        value: result.doctorId,
        ...result,
      }))
    })
  }

  const defaultDoctorOptions = () => {
    if (defaultDoctor?.doctorId) {
      return [
        {
          label: doctorLabel(defaultDoctor),
          value: defaultDoctor.doctorId,
          ...defaultDoctor,
        },
      ]
    }
    return []
  }
  return (
    <>
      <h4 className="bold canopy-mbe-12x">
        Select a Clinician and/or a Facility for this order.
      </h4>
      <Select
        name="doctorId"
        label="Clinician"
        isDisabled={disabled}
        isSearchable
        isClearable
        placeholder={SELECT_DOCTOR_PLACEHOLDER}
        minLength={3}
        options={defaultDoctorOptions()}
        onChange={selectDoctor}
        fetchOptions={getDoctors}
        renderOption={(doctor, { query }) => (
          <DoctorSuggestion doctor={doctor} query={query} />
        )}
      />
    </>
  )
}

const LegalEntitySelect = ({
  defaultLegalEntity,
  disabled,
  fetchLegalEntities,
  selectLegalEntity,
}) => {
  const getLegalEntities = (term) => {
    return fetchLegalEntities(term).then((results) => {
      return results.map((result) => ({ label: result.name, value: result.id }))
    })
  }
  const defaultLegalEntityOptions = defaultLegalEntity.id
    ? [
        {
          label: defaultLegalEntity.nameWithLabel,
          value: defaultLegalEntity.id,
        },
      ]
    : []
  return (
    <Select
      label="Legal Entity"
      isDisabled={disabled}
      isSearchable
      isClearable
      placeholder={SELECT_LEGAL_ENTITY_PLACEHOLDER}
      minLength={2}
      name="legalEntityId"
      options={defaultLegalEntityOptions}
      onChange={selectLegalEntity}
      fetchOptions={getLegalEntities}
    />
  )
}

const ExternalSystemBranchSelect = ({
  fetchExternalSystemBranches,
  disabled,
  selectExternalSystemBranch,
}) => {
  const getExternalSystemBranches = (term) => {
    return fetchExternalSystemBranches(term).then((results) => {
      return results.map((result) => ({ label: result.name, value: result.id }))
    })
  }
  return (
    <Select
      isSearchable
      isDisabled={disabled}
      isClearable
      minLength={0}
      label="Brightree Branch"
      placeholder={SELECT_BRIGHT_TREE_BRANCH_PLACEHOLDER}
      name="externalSystemBranchId"
      onChange={selectExternalSystemBranch}
      fetchOptions={getExternalSystemBranches}
    />
  )
}

const SalesRepSelect = ({
  fetchEmployees,
  defaultSalesRep,
  disabled,
  selectSalesRep,
}) => {
  const getEmployees = (term) => {
    return fetchEmployees(term).then((results) => {
      return results.map((result) => ({
        label: fullName(result),
        value: result.userId,
      }))
    })
  }

  const css = `
    .SalesRepSelect .form-group {
      width: 100%;
    }
  `

  const defaultSalesRepOptions = defaultSalesRep.userId
    ? [
        {
          label: `${fullName(defaultSalesRep)} (default)`,
          value: defaultSalesRep.userId,
        },
      ]
    : []
  return (
    <>
      <style>{css}</style>
      <CanopyFormFieldGroup
        label="Sales Rep"
        description="Assigns the sales rep on the order but not on the facility"
        className="SalesRepSelect"
        optional={true}
        aria-label="Sales Rep Selection"
      >
        {({ primaryControlId }) => (
          <Select
            id={primaryControlId}
            isDisabled={disabled}
            isSearchable
            isClearable
            placeholder={SELECT_SALES_REP_PLACEHOLDER}
            minLength={2}
            name="salesRepId"
            options={defaultSalesRepOptions}
            onChange={selectSalesRep}
            fetchOptions={getEmployees}
          />
        )}
      </CanopyFormFieldGroup>
    </>
  )
}

function InternalFacilitySelectForm(props: Props) {
  const {
    fetchDoctors,
    fetchFacilities,
    fetchLegalEntities,
    fetchExternalSystemBranches,
    fetchEmployees,
    submit,
    submitButtonText,
    isSubmitting,
    closeModal,
    showModal,
    hasMissingFields,
    showFacilityError,
    recommendedFacilities,
    defaultDoctor,
    selectFacility,
    selectDoctor,
    selectSalesRep,
    selectLegalEntity,
    selectExternalSystemBranch,
    showDoctorSelect,
    showFulfillmentAgreementFields,
    defaultLegalEntity,
    defaultSalesRep,
    requiresLegalEntity,
    modalTitle,
    requiresExternalSystemBranch,
    hasInvalidFacility,
  } = props
  return (
    <>
      <Modal title={modalTitle} show={showModal} cancel={closeModal} size="md">
        <Modal.Body>
          <Form
            initialValues={{
              doctorId: defaultDoctor?.doctorId,
            }}
          >
            {showDoctorSelect && (
              <DoctorSelect
                defaultDoctor={defaultDoctor}
                disabled={hasInvalidFacility}
                fetchDoctors={fetchDoctors}
                selectDoctor={selectDoctor}
              />
            )}
            <FacilitySelect
              fetchFacilities={fetchFacilities}
              recommendedFacilities={recommendedFacilities}
              selectFacility={selectFacility}
              defaultFacility={undefined}
            />
          </Form>
          {showFacilityError && (
            <div className="color-danger font-xs">
              Facility is invalid and can not be added. Please contact
              support@parachutehealth.com
            </div>
          )}
          {showFulfillmentAgreementFields && (
            <>
              <div className="canopy-mbs-12x canopy-mbe-12x bt bc-g" />
              <Form
                initialValues={{
                  legalEntityId: defaultLegalEntity.id,
                  salesRepId: defaultSalesRep.userId,
                }}
              >
                {requiresLegalEntity && (
                  <LegalEntitySelect
                    defaultLegalEntity={defaultLegalEntity}
                    disabled={hasInvalidFacility}
                    fetchLegalEntities={fetchLegalEntities}
                    selectLegalEntity={selectLegalEntity}
                  />
                )}
                {requiresExternalSystemBranch && (
                  <ExternalSystemBranchSelect
                    selectExternalSystemBranch={selectExternalSystemBranch}
                    disabled={hasInvalidFacility}
                    fetchExternalSystemBranches={fetchExternalSystemBranches}
                  />
                )}
                <SalesRepSelect
                  selectSalesRep={selectSalesRep}
                  disabled={hasInvalidFacility}
                  fetchEmployees={fetchEmployees}
                  defaultSalesRep={defaultSalesRep}
                />
              </Form>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            className="btn-primary"
            loading={isSubmitting}
            disabled={hasMissingFields || isSubmitting || hasInvalidFacility}
            onClick={submit}
          >
            {submitButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InternalFacilitySelectForm
