import React, { useContext } from "react"
import CmsDrawer from "../../../../components/CmsDrawer"
import {
  CatalogCustomAttribute,
  CatalogCustomAttributeOption,
} from "../../../../types/sharedTypes"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { StaticFieldDefinition } from "../../../../types/form/sharedTypes"
import { generateInitialValues } from "../../../../utilities/form"
import { updateAttributeOption } from "../../../../api/attributeOptions"
import Form from "../../../../../../components/form/Form"
import FormSegment from "../../../../components/Formik/FormSegment"
import { CanopyButton } from "@parachutehealth/canopy-button"
import classNames from "classnames"
import * as styles from "../EditAttributeOptionDrawer/index.module.scss"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

type Props = {
  customAttribute: CatalogCustomAttribute
  customAttributeOption: CatalogCustomAttributeOption
  open?: boolean
  onSave?: (attribute: CatalogCustomAttributeOption) => void
  onClose: () => void
}

const EditAttributeOptionDrawer: React.FC<Props> = ({
  customAttribute,
  customAttributeOption,
  open = false,
  onClose,
  onSave,
}: Props): React.JSX.Element => {
  const { showNotice } = useContext(NoticeContext)

  const staticFields: StaticFieldDefinition[] = [
    {
      label: "Attribute Value",
      name: "description",
      type: "text",
      required: true,
    },
  ]

  const initialValues = generateInitialValues(
    staticFields,
    customAttributeOption
  )

  const handleSubmit = async (
    params: Record<string, any>,
    { setErrors }: any
  ) => {
    const response = await updateAttributeOption(
      customAttribute.externalId,
      customAttributeOption.externalId,
      params
    )
    if (response.errors) {
      setErrors(response.errors)
    } else {
      onClose()
      if (onSave) onSave(response.customAttributeOption)
      showNotice(
        `Successfully updated option ${response.customAttributeOption.description}`,
        "success"
      )
    }
  }

  const numberValueFields: StaticFieldDefinition[] = [
    {
      label: "Packaging",
      name: "packaging",
      type: "select",
    },
    {
      label: "Value",
      name: "value",
      type: "select",
      required: true,
    },
    {
      label: "Unit of measure",
      name: "unitOfMeasure",
      type: "select",
    },
  ]

  return (
    <>
      <CmsDrawer open={open} title="Edit Attribute value" onClose={onClose}>
        <Form
          initialValues={
            customAttribute.valueType === "number" ? {} : initialValues
          }
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <>
              {customAttribute.valueType === "number" ? (
                <div className={classNames(styles.formBody)}>
                  <FormSegment>{[...numberValueFields]}</FormSegment>
                </div>
              ) : (
                <FormSegment>{staticFields}</FormSegment>
              )}
              <CanopyNotice
                variant="warning"
                title="Updating this value will change all custom Attributes currently in use."
                className="canopy-mbe-10x"
              />
              <CanopyButton
                size="small"
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </CanopyButton>
              <CanopyButton
                className="canopy-mis-4x"
                size="small"
                variant="tertiary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </CanopyButton>
            </>
          )}
        </Form>
      </CmsDrawer>
    </>
  )
}

export default EditAttributeOptionDrawer
