// @team @paypals

import { gql, TypedDocumentNode } from "@apollo/client"
import {
  CarrierAuthCreateMutation,
  CarrierAuthCreateMutationVariables,
} from "../../../../../graphql/__generated__/graphql"

export const carrierAuthCreateMutation: TypedDocumentNode<
  CarrierAuthCreateMutation,
  CarrierAuthCreateMutationVariables
> = gql`
  mutation CarrierAuthCreate($dmeOrderId: ID!) {
    carrierAuthorizationCreate(input: { dmeOrderId: $dmeOrderId }) {
      carrierAuthorization {
        id
      }
    }
  }
`
