export const formatHcpcsModifiersString = (hcpcsDetails: {
  [key: string]: string
}) => {
  return Object.values(hcpcsDetails).reduce(
    (agg: string, curr, currentIndex): string => {
      if (currentIndex === Object.values(hcpcsDetails).length - 1) {
        agg += `${curr}`
      } else {
        agg += `${curr}, `
      }
      return agg
    },
    ""
  )
}

export const formatHcpcsModifiersObject = (hcpcsModifiersString: string) => {
  return hcpcsModifiersString
    .split(",")
    .map((val) => val.trim())
    .reduce((agg, curr, currIndex) => {
      agg[`mod${currIndex + 1}`] = curr
      return agg
    }, {})
}
