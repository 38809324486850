import { OptionItem } from "@parachutehealth/canopy-combobox-field"

export function buildUserGeneratedOption(
  userGeneratedValue: string
): OptionItem {
  const dynamicOptionLabel = `Add "${userGeneratedValue}" as a new classification ID`

  const userGeneratedOption = {
    value: userGeneratedValue,
    label: dynamicOptionLabel,
  }

  return userGeneratedOption
}

export function getOptionValues(options: OptionItem[]): OptionItem["value"][] {
  return options.map((option) => option.value)
}

export function buildOptionFromValue(value: string): OptionItem {
  return { label: value, value }
}
