// @ts-strict-ignore
import React from "react"
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  EmployerType,
  OrderStatus,
} from "sharedTypes"
import { FormData } from "applications/FulfillmentEditor/components/FulfillmentForm"
import FulfillmentEditor from "../../FulfillmentEditor"
import { FulfillmentStatuses, ReviewTitle } from "./components"
import { Permissions } from "../../../sharedTypes"
import MultipleFulfillmentsNotice from "./components/MultipleFulfillmentsNotice"

export type ActionsTitleSectionProps = {
  dmeOrder: DmeOrder
  warnings: DmeOrderWarning[]
  employer: Employer
  showSubmit: boolean
  canSubmit: boolean
  selfSign: boolean
  updateFulfillment(id: string, values: FormData): Promise<void>
  submissionPending: boolean
  submit(): Promise<void>
  permissions: Permissions
}

const ActionsTitleSection = (props: ActionsTitleSectionProps) => {
  const { dmeOrder, warnings, employer, updateFulfillment } = props
  const isFacility = employer.employerType === EmployerType.ClinicalFacility
  const isSupplier = [
    EmployerType.Supplier,
    EmployerType.SupplierOrganization,
  ].includes(employer.employerType)

  const titleRightChildContent = () => {
    if (
      dmeOrder.permissions.updateDeliveryData &&
      dmeOrder.lineItemGroups.length > 0
    ) {
      return (
        <FulfillmentEditor
          dmeOrder={dmeOrder}
          updateFulfillment={updateFulfillment}
          linkClassNames="float-right canopy-mbs-8x"
        />
      )
    }

    if (dmeOrder.orderStatus === OrderStatus.SupplierReview && isFacility) {
      return (
        <div className="text-right">
          <div className="pill pill-success canopy-mbs-8x text-left">
            <i className="fas fa-check-circle" /> Order submitted
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <ReviewTitle
        orderStatus={dmeOrder.orderStatus}
        warnings={warnings}
        employer={employer}
        rightChild={titleRightChildContent()}
        supplier={dmeOrder.supplier}
      />
      {dmeOrder.orderStatus === OrderStatus.Accepted && (
        <div className="canopy-pis-8x">
          <MultipleFulfillmentsNotice
            fulfillments={dmeOrder.fulfillments}
            isSupplier={isSupplier}
          />
          <FulfillmentStatuses dmeOrder={dmeOrder} />
        </div>
      )}
    </>
  )
}

export default ActionsTitleSection
