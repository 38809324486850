// @ts-strict-ignore
import React from "react"
import { Field, FormikErrors, useFormikContext } from "formik"
import NumberField from "components/form/NumberField"
import { InitialValues, Values } from "../sharedTypes"
import { DefaultStep, SurveyAnswerType, SurveyQuestion } from "sharedTypes"
import Checkbox from "../form/Checkbox"
import { isNumber } from "lodash"
import { hasTruthyProperty } from "../../../../../utilities/object"
import { SaveButton } from "../components/SaveButton"
import QuestionTitle from "../components/QuestionTitle"

type Props = {
  inputId: string
  question: SurveyQuestion
  isSubmitting: boolean
  valid: boolean
  dirty: boolean
  errors: FormikErrors<Values>
  missingRequiredInformation: boolean
}

interface NumericQuestionAnswerValues {
  answerValue: number | string
  na?: boolean
}

const validate = (values: Values, question: SurveyQuestion) => {
  if (
    typeof values.answerValue === "number" ||
    values.na ||
    (question.naEnabled && values.answerValue === "")
  ) {
    return {}
  }
  return { answerValue: "valid number is required" }
}

const getInitialValues: (question: SurveyQuestion) => InitialValues = (
  question
) => {
  if (question.answerType === SurveyAnswerType.Na) {
    return { answerValue: "", na: true }
  } else {
    return {
      answerValue:
        typeof question.answerValue === "string"
          ? Number(question.answerValue)
          : "",
    }
  }
}

const formatAnswer = (values: Values) => {
  const { answerValue, na } = values
  if (na) {
    return { answerType: SurveyAnswerType.Na, answerValue: "" }
  }
  return { answerValue, answerType: SurveyAnswerType.Value }
}

const QuestionInputNumber = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      inputId,
      question,
      isSubmitting,
      valid,
      dirty,
      errors,
      missingRequiredInformation,
    },
    ref
  ) => {
    const { values } = useFormikContext<NumericQuestionAnswerValues>()
    const { max, maxExclusive, min, minExclusive, step } = question
    const answerValueIsNumber = isNumber(values.answerValue)
    const renderSaveButton: boolean =
      (values.na || values.answerValue !== "") &&
      valid &&
      dirty &&
      !hasTruthyProperty(errors)

    return (
      <>
        <QuestionTitle
          questionTitle={question.title}
          questionText={question.text}
          tooltips={question.tooltips}
          questionId={inputId}
        />
        <NumberField
          name="answerValue"
          id={inputId}
          max={answerValueIsNumber ? max : null}
          min={answerValueIsNumber ? min : null}
          maxExclusive={answerValueIsNumber ? maxExclusive : null}
          minExclusive={answerValueIsNumber ? minExclusive : null}
          step={step || DefaultStep.Any}
          suffix={question.unit}
          disabled={isSubmitting || !!values.na}
          submitOnNavigationKey
          ref={ref}
        />
        {question.naEnabled && (
          <Field name="na">
            {({ form, field }) => (
              <Checkbox
                {...field}
                id={`na-${inputId}`}
                label={question.naLabel}
                checked={!!form.values.na}
                onChange={(checked) => {
                  form
                    .setFieldValue("answerValue", "")
                    .then(() => form.setFieldTouched("answerValue", true))
                  form.setFieldValue("na", checked)
                }}
              />
            )}
          </Field>
        )}
        {renderSaveButton && (
          <SaveButton
            isSubmitting={isSubmitting}
            valid={valid}
            missingRequiredInformation={missingRequiredInformation}
            question={question}
          />
        )}
      </>
    )
  }
)

export {
  QuestionInputNumber as QuestionComponent,
  validate,
  getInitialValues,
  formatAnswer,
}
