import React from "react"
import { QuestionAndAnswers } from "sharedTypes"

type Props = {
  questions: QuestionAndAnswers[]
}

const ReadOnlyQuestionAnswerList = ({ questions }: Props) => {
  return (
    <ul className="list-unstyled">
      {questions.map((question) => (
        <div key={question.text} className="well well-expand">
          <li>
            {question.text && <h3 className="h4 bold">{question.text}</h3>}
          </li>
          <hr className="canopy-my-8x" />
          <li>
            {question.answers.map((answer) => (
              <ul key={answer.value} className="list-unstyled">
                <li>{answer.value}</li>
              </ul>
            ))}
          </li>
        </div>
      ))}
    </ul>
  )
}

export default ReadOnlyQuestionAnswerList
