import React from "react"
import ContentArea from "../../../../components/ContentArea"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"
// import { CatalogCustomAttribute } from "../../../../../../types/sharedTypes"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Form from "../../../../../../components/form/Form"
import { StaticFieldDefinition } from "../../../../types/form/sharedTypes"
import FormSegment from "../../../../components/Formik/FormSegment"
import { Divider } from "@material-ui/core"
import { booleanRadioGroup } from "../../../../components/Formik/DynamicFormField"
import { getAllPackagingLabels } from "applications/Cms/api/packagingLabels"
import { getAllUnits } from "applications/Cms/api/units"
import { useQuery } from "@tanstack/react-query"

// type Props = {
//   onSave?: (attribute: CatalogCustomAttribute) => void
// }

const generalDetailStaticFields: StaticFieldDefinition[] = [
  { label: "Name", name: "name", type: "text", required: true },
  {
    label: "Attribute type",
    name: "valueType",
    type: "radio",
    options: [
      {
        label: "Text, an attribute that consists of freeform characters",
        value: "text",
      },
      { label: "Number, an attribute that contains a number", value: "number" },
    ],
    required: true,
  },
]

const additionalInformationStaticFields: StaticFieldDefinition[] = [
  {
    label: "Internal notes",
    name: "selectionCaption",
    type: "text",
  },
  {
    label: "Enable Attribute auto-selection on optional Products",
    name: "attributeAutoSelectionEnabled",
    type: "radio",
    options: booleanRadioGroup(),
    required: true,
    description:
      "By selecting 'yes', optional Products (Accessories) that contain this Attribute will auto-select this Attribute. Required Products will not be affected.",
  },
]

const AttributeForm: React.FC = (): React.JSX.Element => {
  const { data: packagingLabelOptions } = useQuery({
    queryKey: ["packagingLabelFetch"],
    queryFn: async () => {
      const results = await getAllPackagingLabels()
      return results.packagingLabels.map((result) => ({
        label: result.label,
        value: result.id.toString(),
      }))
    },
  })

  const { data: unitOptions } = useQuery({
    queryKey: ["unitFetch"],
    queryFn: async () => {
      const results = await getAllUnits()
      return results.units.map((result) => ({
        label: result.label,
        value: result.id.toString(),
      }))
    },
  })

  const handleSubmit = async () => {
    console.log("SUBMITTING!!")
  }

  const attributeTypeSettingsFields: StaticFieldDefinition[] = [
    {
      label: "Applicable packaging label",
      description:
        "Select the ways this Attribute can be packaged (e.g. Box, Case)",
      name: "packagingLabel",
      type: "select",
      optional: true,
      options: packagingLabelOptions,
      multiple: true,
    },
    {
      label: "Applicable units of measure",
      description:
        "Select the units that this Attribute comes in (e.g. mL, inches)",
      name: "units",
      type: "select",
      optional: true,
      options: unitOptions,
      multiple: true,
    },
  ]

  return (
    <Form onSubmit={handleSubmit}>
      {({ values }) => (
        <ContentArea variant="medium">
          <h3 className="canopy-typography-heading-large">General details</h3>
          <div className="canopy-mb-10x">
            <CanopyRequiredFieldKey />
          </div>
          <FormSegment>{generalDetailStaticFields}</FormSegment>
          {values.valueType === "number" && (
            <div>
              <Divider />
              <div className="canopy-pt-10x">
                <h3 className="canopy-typography-heading-large">
                  Attribute type settings
                </h3>

                <FormSegment>{attributeTypeSettingsFields}</FormSegment>
              </div>
            </div>
          )}
          <Divider />
          <div className="canopy-pt-10x">
            <h3 className="canopy-typography-heading-large">
              Additional information
            </h3>
            <FormSegment>{additionalInformationStaticFields}</FormSegment>
          </div>
          <CanopyButton size="small" variant="primary" type="submit">
            Save
          </CanopyButton>
          <CanopyButton
            className="canopy-mis-4x"
            size="small"
            variant="tertiary"
            // disabled={isSubmitting}
          >
            Cancel
          </CanopyButton>
        </ContentArea>
      )}
    </Form>
  )
}

export default AttributeForm
