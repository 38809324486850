// @team @cat-crew

import { Supplier } from "../types/sharedTypes"
import { get } from "../../../services/api"

export const getCatalogSupplier = async (
  supplierId: string
): Promise<Supplier> => {
  return await get(`/cms/catalog/suppliers/${supplierId}.json`).then(
    (response) => response.data.supplier
  )
}
