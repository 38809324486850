import React from "react"
import { ClinicalFacility, Doctor } from "../sharedTypes"
import Icon from "components/Icon"
import Confirm from "components/Confirm"
import ClinicalFacilitySearch from "./ClinicalFacilitySearch"

type Props = {
  doctor: Doctor
  removeFacility(facilityId): void
  addFacility(facilityId): void
  searchFacilities(value: string): Promise<ClinicalFacility[]>
}

const FacilitiesList = ({
  doctor,
  removeFacility,
  addFacility,
  searchFacilities,
}: Props) => {
  const clinicalFacilities = doctor.clinicalFacilities
  const closeIcon = "\u2715"
  const signedOrdersCount = (facilityId) => {
    return doctor.signedCounts[facilityId] || 0
  }
  const renderFacility = (facility) => {
    return (
      <div className="col-md-4 col-xl-3" key={facility.id}>
        <div className="well">
          <Confirm
            title="Are you sure?"
            confirmText="Yes, remove"
            cancelText="No, cancel"
            render={(confirm) => {
              return (
                <a
                  className="float-right link-basic remove-doctor"
                  onClick={confirm(() => removeFacility(facility.externalId))}
                >
                  {closeIcon}
                </a>
              )
            }}
          />
          <h4>{facility.name}</h4>
          <div className="color-dark-gray">NPI: {facility.npi}</div>
          <div className="canopy-my-12x">
            <div className="row gutter-10 canopy-mbe-4x">
              <div className="col-1 text-center">
                <Icon
                  type="address-card"
                  className="font-m color-mid-gray bold"
                />
              </div>
              <div className="col-11 truncate">{facility.address}</div>
            </div>
            <div className="row gutter-10">
              <div className="col-1 text-center">
                <Icon type="mobile" className="font-l color-mid-gray bold" />
              </div>
              <div className="col-11 truncate">{facility.phoneNumber}</div>
            </div>
          </div>
          <div>
            <span className="color-mid-gray">Orders Signed</span>
            <span className="float-right">
              {signedOrdersCount(facility.id)}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <hr />
      <h2>Manage Facility Affiliations</h2>
      <div className="row">
        <div className="col-md-6">
          <ClinicalFacilitySearch
            existingFacilities={doctor.clinicalFacilities}
            addFacility={addFacility}
            searchFacilities={searchFacilities}
          />
        </div>
      </div>
      <h3>Affiliated Facilities</h3>
      <div className="row">{clinicalFacilities.map(renderFacility)}</div>
    </div>
  )
}

export default FacilitiesList
