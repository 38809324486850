// @team @facilitators
import React from "react"
import { format, isDateValid } from "utilities/date"
import deduceDate from "utilities/nlpDate"
import { normalizeDate } from "./utilities"
import { DatePicker } from "./DatePicker"

export type DatePickerWidth = "sm" | "auto"

export const DATEPICKER_DATE_FORMAT = "m/d/Y"
export const WEEKDAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

export function onPaste(event: React.ChangeEvent<HTMLInputElement>) {
  const { value } = event.target
  const date = deduceDate(value)
  event.target.value = date ? format(date) : normalizeDate(value)
}

export function formatDate(date) {
  if (!date) {
    return ""
  }
  if (!isDateValid(date)) {
    return ""
  }
  return format(date)
}

export function formatForDateInput(date) {
  if (!date) {
    return
  }
  return format(date, "YYYY-MM-DD")
}

export type DatePickerProps = {
  name?: string
  id?: string
  value?: string
  disabled?: boolean
  className?: string
  onChange?: React.FormEventHandler
  minDate?: string
  maxDate?: string
  disabledDates?: string[]
  hasDateInput?: boolean
  autoFocus?: boolean
  handleNavigationKey?(): void
  width?: DatePickerWidth
  inline?: boolean
}

const DatePickerComponent = (props: DatePickerProps) => {
  return <DatePicker {...props} />
}

export default DatePickerComponent
