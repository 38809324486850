// @team @expanse
import { Fulfillment } from "sharedTypes"

export function areMultipleSalesOrderClassificationsPresent(
  fulfillments: Fulfillment[]
) {
  const uniqueSalesOrderClassificationIds = new Set(
    fulfillments.map((fulfillment) => fulfillment.salesOrderClassificationId)
  )

  return uniqueSalesOrderClassificationIds.size > 1
}
