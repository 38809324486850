// @team @destroythefax
import React, { Dispatch, SetStateAction } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { canopyColorTextDanger } from "@parachutehealth/canopy-tokens-color"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { Form } from "components/form"
import * as styles from "../OrderActionsDropdown/OrderActionsDropdown.module.scss"
import { DmeOrder } from "sharedTypes"
import DatePickerInput from "components/input/DatePickerInput"
import { format, isDateAfter, isDateInPast, isDateValid } from "utilities/date"
import { FormikErrors } from "formik"
import classNames from "classnames"
import OrderActionsDropdownButton from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdownButton"
import { CanopyTextareaField } from "@parachutehealth/canopy-textarea-field"

type Props = {
  snoozeDmeOrder(params): Promise<void>
  dmeOrder: DmeOrder
  setOrderActionsDropdownOpen: Dispatch<SetStateAction<boolean>>
  removeClickOutsideDropdownMenuListener?(): void
}

const SnoozeButton = ({
  snoozeDmeOrder,
  dmeOrder,
  setOrderActionsDropdownOpen,
  removeClickOutsideDropdownMenuListener,
}: Props) => {
  const activatorElementRef = React.useRef<HTMLButtonElement>(null)

  const [showSnoozeModal, setShowSnoozeModal] = React.useState(false)
  const onClick = () => {
    removeClickOutsideDropdownMenuListener &&
      removeClickOutsideDropdownMenuListener()
    openSnoozeModal()
  }
  const openSnoozeModal = () => setShowSnoozeModal(true)
  const closeSnoozeModal = () => {
    setShowSnoozeModal(false)
    setOrderActionsDropdownOpen(false)
  }

  const handleSubmit = (params, { setSubmitting }) => {
    setSubmitting(true)
    snoozeDmeOrder(params).then((_response) => {
      setSubmitting(false)
      closeSnoozeModal()
    })
  }

  const validate = ({ snoozeDate, comment }) => {
    const errors: FormikErrors<{ snoozeDate: string; comment: string }> = {}

    if (isDateInPast(snoozeDate)) {
      errors.snoozeDate = "must be after today"
    }

    if (isDateAfter(snoozeDate, dmeOrder.maxSnoozeDate)) {
      errors.snoozeDate = "must be within 12 months of creating order"
    }

    if (comment.length > 255) {
      errors.comment = "255 character limit reached"
    }

    return errors
  }

  const initialValues = {
    snoozeDate: "",
    comment: "",
  }

  return (
    <>
      <OrderActionsDropdownButton
        iconName="clock-rotate-left"
        title="Snooze&nbsp;order"
        subtitle="Complete the order at a later date"
        onClick={onClick}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        validateOnMount={false}
      >
        {({
          isSubmitting,
          submitForm,
          values,
          setFieldValue,
          errors,
          validateForm,
          handleReset,
          handleChange,
        }) => {
          const hasErrors = Object.keys(errors).length > 0
          const disableSubmit = !isDateValid(values.snoozeDate) || hasErrors
          return (
            <CanopyDialogModal
              className={classNames(
                styles.snoozeModalContainer,
                errors.snoozeDate && styles.hasErrors
              )}
              activatorElementRef={activatorElementRef}
              header="Snooze order"
              open={showSnoozeModal}
              onClose={() => {
                handleReset()
                closeSnoozeModal()
              }}
              primaryFooterButton={
                <CanopyButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={disableSubmit}
                  variant="primary"
                  onClick={submitForm}
                >
                  Snooze order
                </CanopyButton>
              }
              secondaryFooterButton={
                <CanopyButton
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleReset()
                    closeSnoozeModal()
                  }}
                >
                  Cancel
                </CanopyButton>
              }
            >
              <div className={classNames(styles.innerSnoozeModalContainer)}>
                <p className="canopy-mbe-8x">
                  Order will be indicated as snoozed until date selected.
                </p>
                {errors.snoozeDate && (
                  <p
                    style={{ color: canopyColorTextDanger }}
                    className="text-center canopy-typography-body-small"
                  >
                    {errors.snoozeDate}
                  </p>
                )}
                <DatePickerInput
                  name="snooze-date"
                  onChange={(event) => {
                    const element = event.target as HTMLInputElement
                    setFieldValue("snoozeDate", element.value)
                  }}
                  minDate={format(dmeOrder.minSnoozeDate, "YYYY-MM-DD")}
                  maxDate={format(dmeOrder.maxSnoozeDate, "YYYY-MM-DD")}
                  inline
                  handleNavigationKey={validateForm}
                  hasDateInput={false}
                />
                <CanopyTextareaField
                  name="comment"
                  label="Add a comment"
                  feedbackMessage={errors.comment}
                  onChange={handleChange}
                  height="80px"
                  optional
                />
              </div>
            </CanopyDialogModal>
          )
        }}
      </Form>
    </>
  )
}

export default SnoozeButton
