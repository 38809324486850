import { Document, RxDetailsOutput, LineItemGroup } from "sharedTypes"

export type ReviewData = {
  deliveryTicketSignedAt: string
  downloadableDocuments: Document[]
  lineItemGroups: LineItemGroup[]
  rxDetailsOutputs: RxDetailsOutput[]
  permissions: Permissions
  selfSign: boolean
  signatureRequests: SignatureRequest[]
  submission: Submission
  invitation: DmeOrderInvitation
  extraAttestationRequested: boolean
  trackConversionEvents: boolean
  userEmail?: string
  externalQuestionnairesCompleted?: boolean
  externalQuestionnaires?: ExternalQuestionnaire[]
}

export type Permissions = {
  createDocuments: boolean
  pullBack: boolean
  reorder: boolean
  sendSignatureReminder: boolean
  sendSignatureRequest: boolean
  signDeliveryTicket: boolean
  submit: boolean
  supplierEdit: boolean
  supplierGenerateInvite: boolean
  supplierReject: boolean
  supplierSendBack: boolean
  supplierUnaccept: boolean
  update: boolean
  updateClinicalFacility: boolean
  updateCustomAttributeConfiguration: boolean
  updateSupplierLegalEntity: boolean
  updateSignature: boolean
  setUserEmail: boolean
  showSupplierTransfer: boolean
  enableSupplierTransfer: boolean
  archive: boolean
  signUpAsReviewer: boolean
}

export enum ContactChannel {
  Email = "email",
  Fax = "fax",
  Sms = "sms",
  Print = "print",
  Epic = "epic",
  Cerner = "cerner",
  Unknown = "unknown",
}

export type SignatureRequest = {
  contactChannel: ContactChannel
  sentAt: string
}

export type Submission = {
  errorMessage: string
  status: string
  splitOrder: boolean
}

export type AcceptanceResponse = {
  errorMessage: string
  status: string
  redirectUrl: string
}

export type DmeOrderInvitation = {
  url: string
  accessCode: string
  securityCode: string
}

export type UpdateClinicalFacilityAttributes = {
  newClinicalFacilityId: string
  newDoctorId?: string
  supplierLegalEntityId?: string
  externalSystemBranchId?: string
  externalSalesRepId?: string
}

export type CreateSupplierTransferAttributes = {
  replacementSupplierId: string
}

export enum ExternalQuestionnaireSatisfyStatus {
  Recommended = "Recommended",
  NotRecommended = "Not Recommended",
  NotStarted = "Not Started",
  Incomplete = "Incomplete",
}

export type ExternalQuestionnaire = {
  label: string
  actions: object[]
}
