// extracted by mini-css-extract-plugin
var _1 = "#a82387";
var _2 = "#fef5fc";
var _3 = "#efad4e";
var _4 = "#fcf2d8";
var _5 = "#252a2d";
var _6 = "#d02547";
var _7 = "#fee5e6";
var _8 = "#58696f";
var _9 = "#1b86b5";
var _a = "#e4f2fa";
var _b = "#d7dddf";
var _c = "#eef0f1";
var _d = "#f6f8f8";
var _e = "#b5c1c5";
var _f = "#520079";
var _10 = "#f8f4ff";
var _11 = "#16baaa";
var _12 = "#edfdf9";
var _13 = "#fff";
export { _1 as "colorAccent", _2 as "colorAccentLight", _3 as "colorAlert", _4 as "colorAlertLight", _5 as "colorBlack", _6 as "colorDanger", _7 as "colorDangerLight", _8 as "colorDarkGray", _9 as "colorInfo", _a as "colorInfoLight", _b as "colorLightGray", _c as "colorLighterGray", _d as "colorLightestGray", _e as "colorMidGray", _f as "colorPrimary", _10 as "colorPrimaryLight", _11 as "colorSecondary", _12 as "colorSecondaryLight", _13 as "colorWhite" }
