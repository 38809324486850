import { TableColumnDefinition } from "../../SupplierOrganizationDashboard/components/tableColumnDefinitions"
import { DocumentationOrder, isSignedResolution } from "../fetchOrders"
import React from "react"
import { DenialReason } from "../DenialReason"
import CancelButton from "../CancelButton"
import NotificationHistoryPopover from "../NotificationHistoryPopover"
import PatientActivityPopover from "../PatientActivityPopover"
import { formatPhone } from "utilities/phone"
import { LinkWithPreview } from "./LinkWithPreview"
import TruncatedText from "components/TruncatedText"
import { FeatureFlagInput } from "sharedTypes"
import { ShareLink } from "../ShareLink"

const patientAttrs = ["patient_activity", "patient_last_appointment"]

export const documentationOrderTableColumnDefinitions = (
  signatureRequestOrderTrackerEnabled: boolean,
  featureFlags: FeatureFlagInput
): TableColumnDefinition<DocumentationOrder & { key: string }>[] => {
  return [
    {
      render: (o) => o.doctor.name,
      attr: "doctor.name",
      title: "Clinician",
    },
    {
      render: (o) => <PatientActivityPopover order={o} />,
      attr: "patient_activity",
      title: "Patient Activity",
    },
    {
      render: (o) => o.patientLastAppointment,
      attr: "patient_last_appointment",
      title: "Patient Last Appointment",
    },
    {
      render: (o) => (
        <LinkWithPreview
          orderId={o.id}
          disabled={!isSignedResolution(o.signatureResolution)}
        />
      ),
      attr: "documentation_order.document",
      title: "Document",
    },
    {
      render: (o) => <ShareLink order={o} />,
      attr: "shareRequestLink",
      title: "Share Request Link",
    },
    {
      render: (o) => o.doctor.npi,
      attr: "doctor.npi",
      title: "Clinician NPI",
    },
    {
      render: (o) => o.doctor.city,
      attr: "doctor.city",
      title: "Clinician City",
    },
    {
      render: (o) => o.doctor.state,
      attr: "doctor.state",
      title: "Clinician State",
    },
    {
      render: (o) => o.doctor.zip,
      attr: "doctor.zip",
      title: "Clinician ZIP Code",
    },
    {
      render: (o) => o.patient.name,
      attr: "patient.name",
      title: "Patient Name",
    },
    {
      render: (o) => o.patient.dateOfBirth,
      attr: "patient_date_of_birth",
      title: "Patient DOB",
    },
    {
      render: (o) => o.patient.supplierSystemPatientId || "",
      attr: "patient.supplierSystemPatientId",
      title: "External Patient ID",
    },
    {
      render: (o) => o.id,
      attr: "id",
      title: "Order ID",
    },
    {
      render: (o) => o.supplierSystemOrderId || "",
      attr: "supplier_system_order_id",
      title: "External Order ID",
    },
    {
      render: (o) => o.supplier.name,
      attr: "supplier.name",
      title: "Supplier",
    },
    {
      render: (o) => {
        const text =
          o.returnFaxes?.map((number) => formatPhone(number)).join(", ") || ""
        return <TruncatedText text={text} maxLines={2} />
      },
      attr: "returnFaxes",
      title: "Return Fax Number",
    },
    {
      render: (o) => {
        const text = (o.documentationData.items || [])
          .filter((i) => i.hcpcsCode)
          .map((i) => i.hcpcsCode?.trim())
          .join(", ")
        return <TruncatedText text={text} maxLines={2} />
      },
      attr: "hcpcs",
      title: "HCPCS",
    },
    {
      render: (o) => {
        if (!o.signatureResolution) {
          return "Pending"
        } else if (o.signatureResolution.type === "signed") {
          return "Signed"
        } else {
          return "Denied"
        }
      },
      attr: "signatureResolution.type",
      title: "Signature Status",
    },
    {
      render: (o) => o.createdAt,
      attr: "created_at",
      title: "Date Created",
      value: "created_at",
    },
    {
      render: (o) => <NotificationHistoryPopover order={o} />,
      attr: "last_notification",
      title: "Last Notification",
      value: "last_notification",
    },
    {
      render: (o) => o.lastNotificationMethod || null,
      attr: "last_notification_method",
      title: "Last Notification Method",
    },
    {
      render: (o) =>
        o.signatureResolution?.type === "signed"
          ? o.signatureResolution.completedAt
          : null,
      attr: "signatureResolution.signedAt",
      title: "Date Signed",
    },
    {
      render: (o) =>
        o.signatureResolution?.type === "denied"
          ? o.signatureResolution.completedAt
          : null,
      attr: "signatureResolution.deniedAt",
      title: "Date Denied",
    },
    {
      render: (o) => <DenialReason order={o} />,
      attr: "signatureResolution.denialReason",
      title: "Denial Reason",
    },
    {
      render: (o) => <CancelButton order={o} />,
      attr: "cancelButton",
      title: "Cancel",
    },
  ]
    .filter(
      (tc) =>
        !patientAttrs.includes(tc.attr) || signatureRequestOrderTrackerEnabled
    )
    .filter(
      (tc) => tc.attr !== "shareRequestLink" || featureFlags.shareRequestLink
    )
}
