// @team @cat-crew

import { get, patch, post, del } from "../../../services/api"
import {
  CatalogConsignmentCloset,
  CatalogConsignmentClosetStatus,
  CatalogSupplierPackageConfiguration,
  NameAndId,
  Supplier,
} from "../types/sharedTypes"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"
import { ApiResponse } from "./utilities"

export type CatalogConsignmentClosetParams = ServerSideDataGridParams & {
  supplierId?: string
  status?: CatalogConsignmentClosetStatus | "All"
}

export type CatalogConsignmentClosetResponse = {
  catalogConsignmentClosets: CatalogConsignmentCloset[]
  suppliers: Supplier[]
  totalCount: number
  pageSize: number
}

export const getCatalogConsignmentClosets = async (
  params: CatalogConsignmentClosetParams
): Promise<CatalogConsignmentClosetResponse> => {
  return await get(`/cms/catalog/consignment_closets.json`, params).then(
    (response) => {
      return response.data
    }
  )
}

export const getConsignmentCloset = async (
  id: string | number
): Promise<CatalogConsignmentCloset> => {
  return await get(`/cms/catalog/consignment_closets/${id}.json`).then(
    (response) => {
      return response.data.consignmentCloset
    }
  )
}

export const updateCatalogConsignmentCloset = async (
  consignmentClosetId: string,
  params: Record<string, any>
): Promise<
  ApiResponse<{ catalogConsignmentCloset: CatalogConsignmentCloset }>
> => {
  return await patch(
    `/cms/catalog/consignment_closets/${consignmentClosetId}.json`,
    { catalog_consignment_closet: params }
  ).then((response) => response.data)
}

export const duplicateConsignmentClosetSupplierPackageConfiguration = async (
  closetId: string,
  supplierPackageConfigurationId: string,
  params: Record<string, any>
): Promise<
  ApiResponse<{
    supplierPackageConfiguration: CatalogSupplierPackageConfiguration
  }>
> => {
  return await post(
    `/cms/catalog/consignment_closets/${closetId}/supplier_package_configurations/${supplierPackageConfigurationId}/duplicate.json`,
    { catalog_consignment_closet: params }
  )
    .then((response) => response.data)
    .catch((data) => data.response.data)
}

export const destroyConsignmentClosetSupplierPackageConfiguration = async (
  closetId: number,
  spcId: number
) => {
  return await del(
    `/cms/catalog/consignment_closets/${closetId}/supplier_package_configurations/${spcId}.json`
  )
    .then(() => true)
    .catch(() => false)
}
type CreateCatalogConsignmentClosetResponse = ApiResponse<{
  consignmentCloset: CatalogConsignmentCloset
  message?: string
}>

export const createConsignmentCloset = async (
  params: Record<string, any>
): Promise<CreateCatalogConsignmentClosetResponse> => {
  return post("/cms/catalog/consignment_closets.json", params).then(
    (response) => response.data
  )
}

/**
 * To be eligible to have a consignment closet assigned, a supplier first has to have at least
 * one clinical facility (via a fulfillment agreement).  This specialized endpoint (under the aegis
 * of consignment closets) does just that.
 * Note: this doesn't return an entire serialized supplier, just the keys necessary for a dropdown.
 */
export const getConsignmentClosetEligibleSuppliers = async (
  params: Record<string, any>
): Promise<NameAndId[]> => {
  return get(`/cms/catalog/consignment_closets/suppliers.json`, params).then(
    (response) => response.data
  )
}

/**
 * A closet can only be added to a facility that has a fulfillment agreement with a supplier,
 * so this endpoint allows querying (based on search keywords) facilities based upon a specified supplier ID.
 * Note: this doesn't return an entire serialized facility, just the keys necessary for a dropdown.
 */
export const getFacilitiesForSupplier = async (
  supplierId: number,
  params: Record<string, any>
): Promise<NameAndId[]> => {
  return get(`/cms/catalog/consignment_closets/clinical_facilities.json`, {
    ...params,
    supplierId,
  }).then((response) => response.data)
}

export const getSupplierPackageConfigurations = async (
  consignmentClosetId: string | number,
  params: Record<string, any>
): Promise<NameAndId[]> => {
  return get(
    `/cms/catalog/consignment_closets/${consignmentClosetId}/supplier_package_configurations.json`,
    params
  ).then((response) => response.data)
}

export const addSupplierPackageConfiguration = async (
  consignmentClosetId: number,
  supplierPackageConfigId: number
) => {
  return post(
    `/cms/catalog/consignment_closets/${consignmentClosetId}/add_supplier_package_configuration.json`,
    {
      catalog_consignment_closet: {
        supplier_package_configuration_id: supplierPackageConfigId,
      },
    }
  ).then((response) => response.data)
}

export const deleteCatalogConsignmentCloset = async (
  id: string | number
): Promise<void> => {
  return await del(`/cms/catalog/consignment_closets/${id}.json`).then(
    (response) => response.data
  )
}

export const duplicateConsignmentCloset = async (
  id: string,
  params: Record<string, any>
): Promise<CreateCatalogConsignmentClosetResponse> => {
  return post(`/cms/catalog/consignment_closets/${id}/duplicate.json`, {
    catalogConsignmentCloset: params,
  }).then((response) => response.data)
}
