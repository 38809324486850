import React from "react"

const DoctorPendingOrderWorkflow = () => {
  return (
    <>
      <h1>Therapy</h1>
    </>
  )
}

export default DoctorPendingOrderWorkflow
