import React from "react"

import * as styles from "./LineItemDetail.module.scss"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"

interface LineItemDetailProps {
  requiredField: boolean
  title: string
  displayValue: any
}

export const LineItemDetail = ({
  title,
  requiredField,
  displayValue,
}: LineItemDetailProps) => {
  const isRequiredField = () => {
    return requiredField ? (
      <p role="gridcell" className={styles.requiredField}>
        Required
      </p>
    ) : (
      <p role="gridcell"> - </p>
    )
  }

  return (
    <div role="row" className={styles.lineItemDetail}>
      <div role="gridcell" className={styles.lineItemDetailTitle}>
        <h5 className="canopy-typography-font-weight-bold">{title}</h5>
      </div>

      {!isNullOrUndefined(displayValue) ? (
        <p role="gridcell">{displayValue}</p>
      ) : (
        isRequiredField()
      )}
    </div>
  )
}
