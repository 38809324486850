import React, { useEffect, useState } from "react"
import * as api from "./api"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { isOptionItem } from "../../helpers"
import { useErrorContext } from "applications/SupplierOrganizationInboxDocument/error-context"
import { useDebounce } from "hooks/useDebounce"

export const SELECT_BRIGHT_TREE_BRANCH_PLACEHOLDER =
  "Select Brightree Branch for this Facility"

export const ExternalSystemBranchSelect = () => {
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const csrInboxState = useCsrInboxState()
  const [
    externalSystemBranchOptionItem,
    setExternalSystemBranchOptionItem,
  ] = useState<OptionItem>()

  const { displayErrors } = useErrorContext()

  const selectExternalSystemBranch = (value: OptionItem | undefined) => {
    setDisplayFeedbackMessage(!value?.value)
    setExternalSystemBranchOptionItem(value)
    mutateBranch.mutateAsync(value?.value)
  }

  useEffect(() => {
    setExternalSystemBranchOptionItem(
      csrInboxState.externalSystemBranch
        ? {
            label: csrInboxState.externalSystemBranch.name,
            value: csrInboxState.externalSystemBranch.id,
          }
        : undefined
    )
  }, [csrInboxState.externalSystemBranch])

  const { data: options } = useQuery({
    queryKey: ["branches", csrInboxState.id, searchTerm],
    queryFn: async () => {
      if (searchTerm) {
        return fetchExternalSystemBranches(searchTerm)
          .then((results) => {
            return results.map((result) => ({
              label: result.name,
              value: result.id,
            }))
          })
          .catch(() => {
            return []
          })
      }
      return []
    },
  })

  const [displayFeedbackMessage, setDisplayFeedbackMessage] = useState(
    displayErrors
  )

  const mutateBranch = useMutation({
    mutationFn: (value: string | undefined) => {
      return api.updateBranch(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        value || null
      )
    },
  })

  const fetchExternalSystemBranches = useDebounce(async (term) =>
    api.fetchExternalSystemBranches(csrInboxState.supplier.id, term)
  )

  return (
    <CanopyComboboxField
      label="Brightree Branch"
      placeholder={SELECT_BRIGHT_TREE_BRANCH_PLACEHOLDER}
      onInputChange={setSearchTerm}
      feedbackMessage={
        displayFeedbackMessage
          ? "A branch is required to create an order"
          : undefined
      }
      onChange={(newValue) => {
        if (!newValue) {
          selectExternalSystemBranch(undefined)
        } else if (isOptionItem(newValue)) {
          selectExternalSystemBranch(newValue)
        } else {
          console.warn(
            "new value on canopycomboboxfield was not an option item? That shouldn't happen",
            newValue
          )
        }
      }}
      required={true}
      multiple={false}
      options={options || []}
      value={externalSystemBranchOptionItem}
      noOptionsFoundMessage="No branch found for that search"
    />
  )
}
