import React from "react"
import { Tab, Tabs } from "components/Tabs"
import { CatalogSearchTab, CatalogSearchType } from "sharedTypes"
import { isProductSearchTab } from "../../../utilities/catalogSearchTabs"

interface Props {
  catalogSearchType: CatalogSearchType
  setSelectedTab(tab: CatalogSearchTab): void
  selectedTab: CatalogSearchTab
}

const CatalogSearchTabs: React.FC<Props> = ({
  catalogSearchType,
  setSelectedTab,
  selectedTab,
}) => {
  const productTab = (
    <Tab
      key={CatalogSearchTab.PackageFilter}
      selected={selectedTab === CatalogSearchTab.PackageFilter}
      onClick={() => setSelectedTab(CatalogSearchTab.PackageFilter)}
    >
      Product Search
    </Tab>
  )
  const skuTab = (
    <Tab
      key={CatalogSearchTab.SkuQuickAdd}
      selected={selectedTab === CatalogSearchTab.SkuQuickAdd}
      onClick={() => setSelectedTab(CatalogSearchTab.SkuQuickAdd)}
    >
      SKU Lookup
    </Tab>
  )

  return (
    <Tabs className="canopy-pbe-2x bg-lightest-gray">
      {isProductSearchTab(catalogSearchType)
        ? [productTab, skuTab]
        : [skuTab, productTab]}
    </Tabs>
  )
}

export default CatalogSearchTabs
