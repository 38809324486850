export enum CancelReasons {
  NotMyPatient = "not_my_patient",
  NoLongerMedicallyNecessary = "no_longer_medically_necessary",
  PatientNeedsToBeSeenFirst = "patient_needs_to_be_seen_first",
  Other = "other",
}

const cancelReasonLabels = {
  [CancelReasons.NotMyPatient]: "Not my patient",
  [CancelReasons.NoLongerMedicallyNecessary]: "No longer medically necessary",
  [CancelReasons.PatientNeedsToBeSeenFirst]: "Patient needs to be seen first",
  [CancelReasons.Other]: "Other",
}

export const cancelReasonRadioOptions = Object.values(CancelReasons).map(
  (reason) => ({
    label: cancelReasonLabels[reason],
    value: reason,
  })
)

export enum FacilityCancelReasons {
  NoLongerNeedsProduct = "no_longer_needs_product",
  SupplierUnresponsive = "supplier_unresponsive",
  InsurancePaymentIssue = "insurance_payment_issue",
  DischargeDateUpdated = "discharge_date_updated",
  SupplierNotAvailable = "supplier_not_available",
  ProductNotAvailable = "product_not_available",
  PracticeOrderBrowsing = "practice_order_browsing",
  Other = "other",
}

const facilityCancelReasonLabels = {
  [FacilityCancelReasons.NoLongerNeedsProduct]:
    "Patient no longer needs product",
  [FacilityCancelReasons.SupplierUnresponsive]: "Supplier unresponsive",
  [FacilityCancelReasons.InsurancePaymentIssue]: "Insurance/payment issue",
  [FacilityCancelReasons.DischargeDateUpdated]: "Discharge date updated",
  [FacilityCancelReasons.SupplierNotAvailable]: "Supplier not available",
  [FacilityCancelReasons.ProductNotAvailable]: "Product not available",
  [FacilityCancelReasons.PracticeOrderBrowsing]: "Practice order/browsing",
  [FacilityCancelReasons.Other]: "Other",
}

export const facilityCancelReasonRadioOptions = Object.values(
  FacilityCancelReasons
).map((reason) => ({
  label: facilityCancelReasonLabels[reason],
  value: reason,
}))
