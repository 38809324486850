export type SupplierSettingConfiguration = {
  permissionKey: string
  label: string
  supplierPath: string
}

export const supplierSettingConfigurations: SupplierSettingConfiguration[] = [
  {
    permissionKey: "manageInternalStatuses",
    label: "Internal Statuses",
    supplierPath: "internal_statuses",
  },
  {
    permissionKey: "manageLegalEntities",
    label: "Legal Entities",
    supplierPath: "legal_entities",
  },
  {
    permissionKey: "manageFulfillmentAgreements",
    label: "Facilities",
    supplierPath: "fulfillment_agreements",
  },
  {
    permissionKey: "manageSalesRepTerritories",
    label: "Sales Rep Territories",
    supplierPath: "sales_rep_territories",
  },
  {
    permissionKey: "manageInsurance",
    label: "Insurance",
    supplierPath: "insurance/products",
  },
  {
    permissionKey: "manageTeams",
    label: "Teams",
    supplierPath: "teams",
  },
  {
    permissionKey: "manageDeliveryStatusMappings",
    label: "External Status Mappings",
    supplierPath: "delivery_status_mappings",
  },
  {
    permissionKey: "manageBrightreeAcceptanceStatusMappings",
    label: "External WIP Rules",
    supplierPath: "brightree_acceptance_statuses",
  },
  {
    permissionKey: "manageCachedO2Recerts",
    label: "External Recerts",
    supplierPath: "cached_o2_recerts",
  },
  {
    permissionKey: "createReports",
    label: "Report Builder",
    supplierPath: "report_builder",
  },
  {
    permissionKey: "viewSalesReports",
    label: "Sales Reports",
    supplierPath: "sales_reports",
  },
  {
    permissionKey: "viewOperationalReports",
    label: "Operational Reports",
    supplierPath: "operational_reports",
  },
  {
    permissionKey: "manageServiceAreas",
    label: "Service Areas",
    supplierPath: "supplier_service_areas",
  },
  {
    permissionKey: "manageMessageTemplates",
    label: "Messaging Templates",
    supplierPath: "message_templates",
  },
  {
    permissionKey: "manageSalesOrderClassifications",
    label: "Sales Order Classifications",
    supplierPath: "sales_order_classification_mappings",
  },
]
