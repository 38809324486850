import React from "react"
import { FacilityDetails } from "../../sharedTypes"
import { formatAddress } from "utilities/address"
import { pluralize } from "utilities/string"

interface Props {
  facility: FacilityDetails
}

function FacilityPreview({ facility }: Props) {
  const { name, npi, usersCount } = facility
  const hasUsersInfo = typeof usersCount === "number"

  return (
    <div className="well canopy-mbs-8x">
      <h3 className="canopy-mbe-4x">
        <strong>{name}</strong>
      </h3>
      <p className="canopy-mbe-0">{formatAddress(facility)}</p>
      <div>
        {npi && <span>NPI {npi}</span>}
        {npi && hasUsersInfo && <span className="canopy-mx-4x">|</span>}
        {hasUsersInfo && (
          <>
            <div className="logo logo-sm v-align-text-bottom canopy-mie-2x">
              <div className="image"></div>
            </div>
            <span>
              {usersCount} {pluralize("user", usersCount)}
            </span>
          </>
        )}
      </div>
    </div>
  )
}

export default FacilityPreview
