import React from "react"
import { Event, Notice as NoticeType, Nullable } from "sharedTypes"
import ExternalLinks from "./ExternalLinks"
import Notice from "./utilities/Notice"
import { EventRawContent, PlainTextEventContent } from "./EventRawContent"

type Props = {
  event: Event
  notice?: Nullable<NoticeType>
  maxLength?: number
}

const Content: React.FC<Props> = ({ event, notice, maxLength, children }) => {
  return (
    <>
      {event.outerContent && (
        <div className="outer-message-body">{event.outerContent}</div>
      )}
      {(event.content || event.rawContent) && (
        <div className="message-body">
          {event.rawContent ? (
            <EventRawContent event={event} maxLength={maxLength} />
          ) : (
            <PlainTextEventContent text={event.content} />
          )}{" "}
          <ExternalLinks links={event.externalLinks} />
          {children}
        </div>
      )}
      {notice && <Notice notice={notice} />}
    </>
  )
}

export default Content
