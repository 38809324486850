import { useEffect, useState } from "react"
import { useFormik, FormikValues } from "formik"

export const useFormikParachute: typeof useFormik = ({
  initialValues,
  onSubmit,
  ...rest
}: FormikValues) => {
  const [submitted, setSubmitted] = useState(false)

  const formik = useFormik({
    ...rest,
    validateOnBlur: submitted, // Ensures form validation is not attempted until after the first form submission occurs
    validateOnChange: submitted, // Ensures form validation is not attempted until after the first form submission occurs
    initialValues: initialValues, // initialValues passthrough (must be specified to satisfy typescript constraints from useFormik)
    onSubmit: onSubmit, // onSubmit passthrough (must be specified to satisfy typescript constraints from useFormik)
  })

  useEffect(
    function updateFormSubmitStateToEnableValidation() {
      if (formik.submitCount > 0) {
        setSubmitted(true)
      }
    },
    [formik.submitCount]
  ) // Listens for the submitCount to change and sets the submitted state prop after first submission

  return formik
}
