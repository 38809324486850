import React from "react"
import { attributesUrl } from "../../../../urls/attributes"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import AttributeForm from "../../components/AttributeForm"

const CreateAttributeFormPage: React.FC = () => {
  return (
    <>
      <Breadcrumbs>
        {[
          {
            label: "All Attributes",
            to: attributesUrl(),
          },
        ]}
      </Breadcrumbs>
      <h1 className="canopy-typography-heading-2xlarge">Add new Attribute</h1>
      <AttributeForm />
    </>
  )
}

export default CreateAttributeFormPage
