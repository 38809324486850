// @ts-strict-ignore
import React, { useEffect, useRef, useState } from "react"
import {
  useEffectRunOnEveryRender,
  useGlobalContext,
  useWebSockets,
} from "hooks"
import {
  DmeOrder,
  DmeOrderPermissions,
  DmeOrderWarning,
  Event,
  OrderStatus,
  Page,
} from "sharedTypes"
import { History, Location } from "history"
import classNames from "classnames"
import Navigation from "../containers/Navigation"
import Sidebar from "../containers/Sidebar"
import { SidebarTab } from "../containers/Navigation/sharedTypes"
import WorkflowPageContext from "context/WorkflowPage"
import { isComment } from "utilities/event"
import WorkflowHeader from "./WorkflowHeader"
import { isSidebarOpenWithWorkflowVisible } from "../utilities/sidebar"
import { inOrderNavReplacement } from "../utilities/utilityNav"
import { getEmployerType } from "utilities/url"

type Props = {
  dmeOrder: DmeOrder
  children: React.ReactNode
  nextPagePath?: string
  sidebarTab: SidebarTab
  pages: Page[]
  location: Location
  permissions: DmeOrderPermissions
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  refreshDmeOrder(): Promise<void>
  history: History
  warnings: DmeOrderWarning[]
  banner?: React.ReactNode
  title?: string
  subtitle?: string
}

const WorkflowPage = (props: Props) => {
  const {
    nextPagePath,
    dmeOrder,
    children,
    history,
    permissions,
    pages,
    sidebarTab,
    setDmeOrder,
    refreshDmeOrder,
    warnings,
    banner,
    title,
    subtitle,
  } = props

  const [connected, setConnected] = useState(false)
  const [hasNewComments, setHasNewComments] = useState(false)
  const [currentTab, setSidebarTab] = useState<SidebarTab>(
    isSidebarOpenWithWorkflowVisible(sidebarTab)
      ? sidebarTab || SidebarTab.Activity
      : SidebarTab.Closed
  )

  const isSidebarOpen = currentTab !== SidebarTab.Closed

  const [
    pollingForDocumentsCallback,
    setPollingForDocumentsCallback,
  ] = useState(null)
  const currentTabRef = useRef(currentTab)
  useEffectRunOnEveryRender(() => {
    currentTabRef.current = currentTab
  })

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add("right-sidebar-is-open")
    } else {
      document.body.classList.remove("right-sidebar-is-open")
    }
  }, [isSidebarOpen])

  useEffect(() => {
    if (currentTab === SidebarTab.Activity) {
      setHasNewComments(false)
    }
  }, [currentTab])

  useWebSockets(
    { channel: "EventsChannel", id: dmeOrder.id },
    {
      received: (event: Event) => {
        if (isComment(event) && currentTabRef.current !== SidebarTab.Activity) {
          setHasNewComments(true)
        }
      },
      connected: () => setConnected(true),
      disconnected: () => setConnected(false),
    }
  )

  const pollForDocuments = () => {
    setSidebarTab(SidebarTab.Documents)
    refreshDmeOrder()
    pollingForDocumentsCallback && pollingForDocumentsCallback()
  }

  const registerPollForDocuments = (cb) =>
    setPollingForDocumentsCallback(() => cb)
  const unregisterPollForDocuments = () => setPollingForDocumentsCallback(null)

  const context = useGlobalContext()

  const addSidebarButtonOffset =
    !inOrderNavReplacement(context) && !isSidebarOpen

  const employerType = getEmployerType()
  const shouldActivityBarBeWider =
    dmeOrder.orderStatus === OrderStatus.SupplierReview ||
    dmeOrder.orderStatus === OrderStatus.Accepted ||
    (employerType === "Supplier" &&
      dmeOrder.orderStatus === OrderStatus.FacilityInProgress)
  const workflowMainClass = () => {
    if (shouldActivityBarBeWider) return "workflow-main wider-sidebar"
    return "workflow-main"
  }
  return (
    <WorkflowPageContext.Provider
      value={{
        pollForDocuments,
        registerPollForDocuments,
        unregisterPollForDocuments,
        setSidebarTab,
        isSidebarOpen,
      }}
    >
      <div
        data-ws-connected={connected}
        className={classNames("workflow", {
          "right-sidebar-open": isSidebarOpen,
          "right-sidebar-closed": !isSidebarOpen,
        })}
      >
        {!!banner && <div className="workflow-banner">{banner}</div>}
        <div
          id="workflow-navigation"
          className={classNames({ "d-md-block": isSidebarOpen })}
        >
          <Navigation
            dmeOrder={dmeOrder}
            history={history}
            nextPagePath={nextPagePath}
            pages={pages}
            permissions={permissions}
            setDmeOrder={setDmeOrder}
            currentTab={currentTab}
            setSidebarTab={setSidebarTab}
            isSidebarOpen={isSidebarOpen}
          />
        </div>
        <div
          className={classNames(workflowMainClass(), {
            "d-none d-md-block": isSidebarOpen,
            "add-sidebar-button-offset": addSidebarButtonOffset,
          })}
        >
          {title && <WorkflowHeader title={title} subtitle={subtitle} />}
          {children}
        </div>
        <div className="workflow-overlay" />
        <Sidebar
          hasNewComments={hasNewComments}
          dmeOrder={dmeOrder}
          tab={currentTab}
          warnings={warnings}
          setSidebarTab={setSidebarTab}
          close={() => {
            setSidebarTab(SidebarTab.Closed)
          }}
          setDmeOrder={setDmeOrder}
          refreshDmeOrder={refreshDmeOrder}
        />
      </div>
    </WorkflowPageContext.Provider>
  )
}

export default WorkflowPage
