import React from "react"
import Button from "components/form/Button"
import * as styles from "./index.module.scss"
import Link from "../../../../components/Link"
import { useFeatureFlags } from "../../../../components/FeatureFlagContext"

interface Props {
  onClick(): void
}

function StartFirstOrderCard({ onClick }: Props) {
  const { isFeatureEnabled } = useFeatureFlags()

  if (isFeatureEnabled("showDemoPromptOnDashboardPage")) {
    return (
      <div className={styles.container}>
        <h4>Start your first order!</h4>
        <p className="font-subparagraph canopy-mbe-8x">
          Create a new order and your progress will save automatically.
        </p>
        <p className="font-subparagraph canopy-mbe-8x">
          Want tips and tricks on for placing orders?{" "}
          <Link href="https://www.parachutehealth.com/demo" target="_blank">
            Schedule a demo
          </Link>
        </p>
        <Button className="btn-brand btn-sm" onClick={onClick}>
          + New Order
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <h4>Start your first order!</h4>
      <p className="font-subparagraph canopy-mbe-8x">
        Click the New Order button to start your first Digital Order. Once you
        start the order your progress will be saved and you can return to
        complete the order at any time.
      </p>
      <Button className="btn-brand btn-sm" onClick={onClick}>
        + New Order
      </Button>
    </div>
  )
}

export default StartFirstOrderCard
