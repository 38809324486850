export enum FacilityType {
  AmbulatoryPractice = "ambulatory_practice",
  AssistedLiving = "assisted_living",
  CancerCenter = "cancer_center",
  DialysisCenter = "dialysis_center",
  DoctorOffice = "doctor_office",
  HealthPlan = "health_plan",
  HomeHealth = "home_health",
  Hospice = "hospice",
  Hospital = "hospital",
  NursingOrRehab = "nursing_or_rehab",
  Recert = "recert",
  SleepLab = "sleep_lab",
}

export const OtherOption = { value: null, label: "Other" }
