// @team @expanse
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { canopySpace8X } from "@parachutehealth/canopy-tokens-space"
import React from "react"
import { Fulfillment } from "sharedTypes"
import { areMultipleSalesOrderClassificationsPresent } from "./utils"
import * as styles from "./MultipleFulfillmentsNotice.module.scss"
import { buildDeliverySectionCardId } from "applications/Workflow/containers/Review/utilities"

type MultipleFulfillmentsNoticeProps = {
  fulfillments: Fulfillment[]
  isSupplier: boolean
}

const MultipleFulfillmentsNotice = ({
  fulfillments,
  isSupplier,
}: MultipleFulfillmentsNoticeProps) => {
  const fulfillmentCount = fulfillments.length

  if (!isSupplier) return null
  if (fulfillmentCount < 2) return null
  if (!areMultipleSalesOrderClassificationsPresent(fulfillments)) return null

  function scrollToDeliveryDetails() {
    const deliveryDetailsCardId = buildDeliverySectionCardId(0)

    const deliveryDetailsCard = document.getElementById(deliveryDetailsCardId)
    if (deliveryDetailsCard) {
      const deliveryDetailsYPosition = deliveryDetailsCard.getBoundingClientRect()
        .top
      const fixedNavHeight = 65
      const currentScrollPosition = window.scrollY
      const offsetPosition =
        deliveryDetailsYPosition + currentScrollPosition - fixedNavHeight

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }

  return (
    <CanopyNotice
      title={`This order has been separated into ${fulfillmentCount} fulfillments`}
      message="Based on sales order classification settings, this order has been split into the fulfillments below. To view more details select the link below."
      actions={[
        {
          as: "button",
          text: "View fulfillment details",
          onClick: scrollToDeliveryDetails,
          className: styles.viewFulfillmentDetailsButton,
        },
      ]}
      style={{ marginBottom: canopySpace8X }}
    />
  )
}

export default MultipleFulfillmentsNotice
