import { get } from "../../../../services/api"
import { employerPrefix } from "utilities/url"

export const exportResolvedSignatureRequestsCSV = ({
  doctorId,
}: {
  doctorId: string
}) => {
  return get(
    `${employerPrefix("Doctor", doctorId)}/signature_requests.csv`
  ).then((data) => {
    return data.data
  })
}
