import React from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { buildBackToMappingsButtonConfig } from "./utils"

function SalesOrderClassificationFormErrorNotice(props: {
  salesOrderClassificationMappingsPath: string
  submissionErrors: string[]
}) {
  if (props.submissionErrors.length === 0) return null

  const noticeMessage = props.submissionErrors.join(", ")

  return (
    <CanopyNotice
      message={noticeMessage}
      tabIndex={-1}
      title="Could not create mapping"
      variant="error"
      actions={[
        buildBackToMappingsButtonConfig(
          props.salesOrderClassificationMappingsPath
        ),
      ]}
    />
  )
}

export default SalesOrderClassificationFormErrorNotice
