import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  CarrierLineItemAuthorizationUpdateMutation,
  CarrierLineItemAuthorizationUpdateMutationVariables,
} from "../../../../graphql/__generated__/graphql"

export const CarrierLineItemAuthorizationUpdate: TypedDocumentNode<
  CarrierLineItemAuthorizationUpdateMutation,
  CarrierLineItemAuthorizationUpdateMutationVariables
> = gql`
  mutation CarrierLineItemAuthorizationUpdate(
    $id: ID!
    $hcpcs: String!
    $hcpcsDetails: JSON
    $quantity: Int!
    $unitsOfMeasure: String
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $priority: String!
    $decisionStatus: CarrierAuthStatusesEnum
  ) {
    carrierLineItemAuthorizationUpdate(
      input: {
        carrierLineItemAuthorizationId: $id
        hcpcs: $hcpcs
        hcpcsDetails: $hcpcsDetails
        quantity: $quantity
        unitsOfMeasure: $unitsOfMeasure
        startDate: $startDate
        endDate: $endDate
        priority: $priority
        decisionStatus: $decisionStatus
      }
    ) {
      carrierLineItemAuthorization {
        id
        hcpcs
      }
    }
  }
`
