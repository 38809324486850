import React from "react"
import * as styles from "./index.module.scss"
import classNames from "classnames"

type ContentAreaProps = {
  variant?: "full" | "medium"
  compact?: boolean
} & React.HTMLProps<HTMLDivElement>

const ContentArea = ({
  children,
  className,
  variant = "full",
  compact = false,
  ...other
}: ContentAreaProps) => {
  return (
    <div
      className={classNames(styles.mainContainer, className, styles[variant], {
        [styles.compact]: compact,
      })}
      {...other}
    >
      {children}
    </div>
  )
}

export default ContentArea
