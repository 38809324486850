import Chat from "@parachutehealth/chat"
import Service from "@parachutehealth/chat/build/service"
import sdk from "vendor/zendesk-sdk"
import { getQuestionnaireQuestions } from "utilities/chat"
import sessionStorage from "utilities/storage/session"
import emitter from "utilities/eventEmitter"
import { EventCategory, trackEvent } from "../utilities/tracking"

// This file is loaded from app/helpers/webpack_helper.rb

const target = document.getElementById("chat-app") // chat-app is from app/views/shared/_zendesk.html.haml
const zendeskChatData = document.getElementById("chat-data") // chat-data is from app/views/shared/_zendesk.html.haml
const zendeskChatDataInnerHtml = zendeskChatData?.innerHTML

try {
  // @ts-ignore
  const data = JSON.parse(zendeskChatDataInnerHtml)
  const { key, email, name, departments, questionnaire, assets, open } = data
  const questions = questionnaire ? getQuestionnaireQuestions(departments) : []

  const service = new Service({ key, sdk, storage: sessionStorage })

  emitter.on("route:change", () => {
    service.sendPath({ title: document.title, url: window.location.href })
  })

  const chat = new Chat({
    target,
    data: {
      brand: "Parachute Chat Support",
      display_name: name,
      email,
      header: "Need assistance using Parachute?",
      logo: assets.logo,
      bar_logo: assets.bar_logo,
      sounds: {
        message: [assets.sound],
      },
      signed_in: Boolean(name),
      support_email: data.support_email,
      questionnaire_questions: questions,
      tags: data.tags,
      ...service.data(),
    },
    service,
  })

  emitter.on("chat-link:clicked", () => {
    chat.set({ view: "open" })
  })

  if (open) {
    chat.set({ view: "open" })
  }

  chat.observe("view", (view) => {
    if (view === "open") {
      trackEvent(EventCategory.CustomerSupport, "help-center-clicked")
    }
  })
} catch (e) {
  console.error(
    "No zendesk chat found. Contact support@parachutehealth.com for support"
  )
  console.error(e)
}
