import React, { useRef, useState, useEffect } from "react"
import * as styles from "./ActionableOrderCard.module.scss"
import { workflowsUrl } from "../../../../Workflow/urls"
import { OrderStatus, SignatureStatus } from "sharedTypes"
import StatusChip from "./StatusChip"
import classNames from "classnames"
import FollowersSection from "./FollowersSection"
import SignatureAndDocumentStatus from "./SignatureAndDocumentStatus"
import LastComment from "./LastComment"
import { DashboardEvent } from "../../../sharedTypes"
import Supplier from "./Supplier"
import NotSelected from "./NotSelected"

interface Props {
  dmeOrder: {
    externalId: string
    patient: {
      dateOfBirth: string
      firstName: string
      lastName: string
      mrn: string
      sex: string
    }
    signingClinician: string
    facilityFollowers: {
      name: string
      initials: string
      id: string
    }[]
    orderStatus: OrderStatus
    signatureStatus: SignatureStatus
    categories: string[]
    relevantSuppliers: { name: string }[]
    lastComment: DashboardEvent | null
    snooze?: {
      snoozedUntil: string
    }
    cardStatuses?: { type?: string; text: string }[]
    unsatisfiedManualDocumentation: boolean
  }
  unreadEventCount: number
}

const ActionableOrderCard: React.FC<Props> = ({
  dmeOrder,
  dmeOrder: {
    patient,
    externalId,
    lastComment,
    cardStatuses,
    signatureStatus,
    orderStatus,
    unsatisfiedManualDocumentation,
    relevantSuppliers,
  },
  unreadEventCount,
}) => {
  const containerRef = useRef<HTMLAnchorElement>(null)
  const [width, setWidth] = useState(0)
  const fulfillmentStatusWraps = width > 390
  const stackChips = width < 317
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth)
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const patientName = patient.firstName + " " + patient.lastName
  const patientInfo = [patient.dateOfBirth, patient.sex, patient.mrn]
    .filter((info) => info)
    .join(" · ")

  const signingClinician = dmeOrder.signingClinician || <NotSelected />
  const productType = (dmeOrder.categories.length &&
    dmeOrder.categories.join(" · ")) || <NotSelected />

  const PatientAndOrderStatus = () => (
    <>
      {fulfillmentStatusWraps ? (
        <div className="d-flex justify-content-between canopy-mbe-8x">
          <div className="flex-column">
            <h3 className={styles.patientName}>{patientName}</h3>
            <span className={styles.patientInfo}>{patientInfo}</span>
          </div>
          <div className="flex-column">
            {cardStatuses &&
              cardStatuses.map((cardStatus) => (
                <StatusChip
                  cardStatus={cardStatus}
                  classes={{ label: styles.chipLabelOverride }}
                  key={`${externalId}-${cardStatus.text}`}
                />
              ))}
          </div>
        </div>
      ) : (
        <>
          <div className="canopy-mbe-4x">
            {cardStatuses &&
              cardStatuses.map((cardStatus) => (
                <StatusChip
                  cardStatus={cardStatus}
                  classes={{
                    label: classNames(
                      styles.chipLabelOverride,
                      styles.truncate
                    ),
                  }}
                  key={`${externalId}-${cardStatus.text}`}
                />
              ))}
          </div>
          <h3 className={styles.patientName}>{patientName}</h3>
          <span className={styles.patientInfo}>{patientInfo}</span>
        </>
      )}
    </>
  )

  return (
    <a
      className={styles.linkContainer}
      href={workflowsUrl(externalId)}
      data-testid="dashboard-actionable-order-card"
      ref={containerRef}
    >
      {unreadEventCount > 0 && (
        <div className={styles.commentBadge}>{unreadEventCount}</div>
      )}
      <div className={styles.card}>
        <PatientAndOrderStatus />
        <div className={styles.bottomCardRow}>
          <div>
            <h4 className={styles.detailHeader}>SIGNING CLINICIAN</h4>
            <span className={styles.detailContent}>{signingClinician}</span>
          </div>
          <div className="flex-1">
            <h4 className={styles.detailHeader}>PRODUCT TYPE</h4>
            <span className={styles.detailContent}>{productType}</span>
          </div>
        </div>
        <div className={classNames(styles.bottomCardRow, "canopy-mbs-8x")}>
          <Supplier relevantSuppliers={relevantSuppliers} />
          <FollowersSection dmeOrderFollowers={dmeOrder.facilityFollowers} />
        </div>
        <LastComment
          lastComment={lastComment}
          unreadEventCount={unreadEventCount}
        />
        <SignatureAndDocumentStatus
          signatureStatus={signatureStatus}
          orderStatus={orderStatus}
          unsatisfiedManualDocumentation={unsatisfiedManualDocumentation}
          stackChips={stackChips}
        />
      </div>
    </a>
  )
}

export default ActionableOrderCard
