import React from "react"
import { MaterialThemeProvider } from "../../themes/theme"
import { GridColDef } from "@mui/x-data-grid-pro"
import { isTest } from "../../utilities/environment"
import DataGridToolbar from "../Cms/components/DataGridToolbar"
import ParachuteMuiDataGridPro from "../../components/ParachuteMuiDataGrid/ParachuteMuiDataGrid"
import ParachuteMuiDataGridContainer from "../../components/ParachuteMuiDataGrid/ParachuteMuiDataGridContainer"

type SalesCollaborationFacilityRow = {
  id: string
  facilityName: string
  onParachute: boolean
  avgMonthlyOrders: number
  outstandingOrdersCount: number
  totalOrdersCount: number
  salesRep: string
  facilityAddress: string
}

export type Props = {
  rows: SalesCollaborationFacilityRow[]
}

const SalesCollaborationToolsNetworkTable = ({ rows }: Props) => {
  // why do column headers and row contents not line up? (in storybook)
  // move hardcoded width out of the actual component
  // write a test
  // stop hardcoding data?

  const columns: GridColDef[] = [
    {
      field: "facilityName",
      headerName: "Facility Name",
      sortable: true,
      hide: false,
      type: "string",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "onParachute",
      headerName: "On Parachute",
      sortable: true,
      hide: false,
      type: "boolean",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "avgMonthlyOrders",
      headerName: "Avg. monthly orders (last 3 months)",
      sortable: true,
      hide: false,
      type: "number",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "outstandingOrdersCount",
      headerName: "Outstanding Orders",
      sortable: true,
      hide: false,
      type: "number",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "totalOrdersCount",
      headerName: "Orders (total)",
      sortable: true,
      hide: false,
      type: "number",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "salesRep",
      headerName: "Sales Rep",
      sortable: true,
      hide: false,
      type: "string",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "facilityAddress",
      headerName: "Address",
      sortable: true,
      hide: false,
      type: "string",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
  ]

  return (
    <div style={{ width: "2000px" }}>
      <MaterialThemeProvider>
        <ParachuteMuiDataGridContainer>
          <ParachuteMuiDataGridPro
            columns={columns}
            autoHeight
            disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
            rows={rows}
            onFilterModelChange={(model) =>
              console.log("onFilterModelChange", model)
            }
            density="standard"
            getRowId={(row) => row.id}
            pagination={false}
            disableSelectionOnClick
            hideFooterSelectedRowCount
            components={{
              Toolbar: DataGridToolbar,
            }}
            componentsProps={{
              toolbar: { filter: true },
            }}
            rowHeight={62}
          />
        </ParachuteMuiDataGridContainer>
      </MaterialThemeProvider>
    </div>
  )
}

export default SalesCollaborationToolsNetworkTable
