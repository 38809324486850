import React from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { buildBackToMappingsButtonConfig } from "./utils"

function SalesOrderClassificationFormSuccessNotice(props: {
  salesOrderClassificationMappingsPath: string
  isSuccess: boolean
}) {
  if (!props.isSuccess) return null

  return (
    <CanopyNotice
      message="Successfully created classification mapping"
      tabIndex={-1}
      title="Success!"
      variant="success"
      actions={[
        buildBackToMappingsButtonConfig(
          props.salesOrderClassificationMappingsPath
        ),
      ]}
    />
  )
}

export default SalesOrderClassificationFormSuccessNotice
