import React from "react"
import { FormikErrors } from "formik"
import { useFormikParachute } from "../../../../../shared/hooks/useFormikParachute/useFormikParachute"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"

type SupplierSystemClinicalFacilityExternalSystemIdModalProps = {
  /**
   * Function called when the modal is closed
   */
  onClose: () => void
  /**
   * Function called when the form is submitted (and all fields are valid)
   */
  onSubmit: (externalSystemId) => Promise<void>
  /**
   * When true, displays the modal
   */
  open?: boolean
}

interface SupplierSystemClinicalFacilityExternalSystemIdFields {
  externalSystemId?: string
}

const SupplierSystemClinicalFacilityExternalSystemIdModal = (
  props: SupplierSystemClinicalFacilityExternalSystemIdModalProps
): JSX.Element => {
  const { onClose, onSubmit, open = true } = props

  const formik = useFormikParachute<
    SupplierSystemClinicalFacilityExternalSystemIdFields
  >({
    initialValues: {
      externalSystemId: "",
    },
    onSubmit: (values) => {
      return onSubmit(values.externalSystemId).then(() => onClose())
    },
    validate: (values) => {
      const errors: FormikErrors<SupplierSystemClinicalFacilityExternalSystemIdFields> = {}
      if (!values.externalSystemId || values.externalSystemId.length === 0) {
        errors.externalSystemId = "Enter this facility's external system ID"
      }
      return errors
    },
  })

  return (
    <CanopyDialogModal
      header="Set facility's external system ID"
      onClose={onClose}
      open={open}
      primaryFooterButton={
        <CanopyButton
          type="submit"
          form="suppliersystemclinicalfacilityexternalsystemid"
          loading={formik.isSubmitting}
          loadingText="Saving facility's external system ID..."
        >
          Save
        </CanopyButton>
      }
    >
      <CanopyForm
        id="suppliersystemclinicalfacilityexternalsystemid"
        onSubmit={formik.handleSubmit}
      >
        <p>
          This order is from a new facility. This facility&apos;s external
          system ID must be entered before this order can be accepted.
        </p>
        <CanopyRequiredFieldKey />
        <CanopyTextInputField
          feedbackMessage={formik.errors.externalSystemId}
          label="Facility's external system ID"
          name="externalSystemId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          value={formik.values.externalSystemId}
        />
      </CanopyForm>
    </CanopyDialogModal>
  )
}

export default SupplierSystemClinicalFacilityExternalSystemIdModal
