import React from "react"
import { CsrInboxDocument } from "sharedTypes"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core"
import { MaterialThemeProvider } from "themes/theme"
import Pill from "components/Pill"
import { titleize } from "utilities/string"
import { formatPhone } from "utilities/phone"
import { EVENT_DATE_TIME_FORMAT, format } from "utilities/date"
import { employerPrefix } from "../../utilities/url"
import * as styles from "./index.module.scss"
import Icon from "../../components/Icon"
import CsrInboxStateAiDetails from "../CsrInboxStateAiDetails"

const fromFax = (document) => document.sourceType === "fax"

const Row = (props: { labelText: string; value: any }) => {
  return (
    <TableRow>
      <TableCell classes={{ root: styles.labelCell }} align="left">
        <Typography variant="body1" className={styles.label}>
          {props.labelText}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography variant="body1">{props.value}</Typography>
      </TableCell>
    </TableRow>
  )
}

type Props = {
  document: CsrInboxDocument
  supplierId: string
  supplierOrganizationId?: string
  aiIntakeEnabled: boolean
  documentation: {
    title?: string
    summary?: string
    qualificationStatus?: "qualified" | "undetermined"
  }
}

export default (props: Props) => {
  const document = props.document

  const inboxUrl = props.supplierOrganizationId
    ? `${employerPrefix(
        "SupplierOrganization",
        props.supplierOrganizationId
      )}/inbox/${props.supplierId}`
    : `${employerPrefix("Supplier", props.supplierId)}/inbox`

  return (
    <MaterialThemeProvider>
      <a className="link" href={inboxUrl}>
        <Icon type="arrow-left" className="canopy-pie-4x" />
        Back to Inbound Documents
      </a>
      <Table>
        <TableBody>
          <Row labelText="Document ID" value={props.document.documentId} />
          <Row
            labelText="Document Status"
            value={
              <Pill noBottomMargin solid>
                {titleize(props.document.state)}
              </Pill>
            }
          />
          <Row
            labelText="From"
            value={
              fromFax(document) ? formatPhone(document.source) : document.source
            }
          />
          <Row
            labelText="Received"
            value={format(document.createdAt, EVENT_DATE_TIME_FORMAT)}
          />
          <Row labelText="Pages" value={props.document.pageCount} />
          <Row
            labelText="Internal Status"
            value={props.document.internalStatus?.status || "None"}
          />
          <Row
            labelText="Assigned to"
            value={props.document.assignee?.name || "Unassigned"}
          />
        </TableBody>
      </Table>
      <CsrInboxStateAiDetails
        aiIntakeEnabled={props.aiIntakeEnabled}
        documentation={props.documentation}
      />
    </MaterialThemeProvider>
  )
}
