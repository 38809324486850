import React, { useEffect } from "react"
import { Box } from "@material-ui/core"
import { initUserInstruments } from "services/clientsideInstrumentation"
import ContentArea from "../../../../components/ContentArea"
import SimpleTabs from "applications/Cms/components/SimpleTabs"
import { useFeatureFlags } from "../../../../../../components/FeatureFlagContext"
import AttributesDataGrid from "./components/AttributesDataGrid"

const AttributesPage: React.FC = () => {
  const { isFeatureEnabled } = useFeatureFlags()

  useEffect(() => {
    document.title = "Attributes"
    void initUserInstruments()
  }, [])

  const isAttrEnhancementFlagOn = isFeatureEnabled(
    "cmsAttributeEnhancementsRefresh"
  )

  return (
    <>
      <h2 className="canopy-typography-heading-2xlarge">Attributes</h2>
      {isAttrEnhancementFlagOn ? (
        <>
          <Box mb={3}>
            <p className="canopy-typography-body-medium">
              Attributes are the smallest building block in the Catalog.
              Combining a series of Attribute values creates an individual SKU
              for people to order.
            </p>
          </Box>
          <ContentArea compact>
            <Box mb={2}>
              <SimpleTabs fullWidth={false}>
                {[
                  {
                    label: "Attributes",
                    key: "attributes",
                    content: <AttributesDataGrid />,
                  },
                  {
                    label: "Packaging label",
                    key: "packagingLabel",
                    content: [],
                  },
                  {
                    label: "Units of measure",
                    key: "unitsOfMeasure",
                    content: [],
                  },
                ]}
              </SimpleTabs>
            </Box>
          </ContentArea>
        </>
      ) : (
        <ContentArea>
          <Box mb={2}>
            <AttributesDataGrid />
          </Box>
        </ContentArea>
      )}
    </>
  )
}

export default AttributesPage
