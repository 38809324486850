import { capitalize } from "utilities/string"

export const PHONE_VALIDATION_MESSAGE =
  "must be 10 digits long and cannot start with 0 or 1"

export function formatPhone(value?: string): string | null {
  if (!value) {
    return null
  }
  const digits = value.replace(/\D/g, "")
  return `(${digits.substring(0, 3)}) ${digits.substring(
    3,
    6
  )}-${digits.substring(6)}`
}

export function formatPhoneWithExtension(
  phone?: string,
  extension?: string
): string | null {
  if (!phone) {
    return null
  }
  const number = formatPhone(phone)
  if (!extension) {
    return number
  }
  return `${number} ext. ${extension}`
}

export function formatPhoneWithType(phoneWithType: {
  type?: string
  phoneNumber: string
}): string {
  let result = ""
  if (phoneWithType.type) {
    result += `${capitalize(phoneWithType.type)} phone - `
  }
  result += formatPhone(phoneWithType.phoneNumber)
  return result
}

const US_PHONE_REGEXP = /^(\+1|1)?[-. ]?\(?([2-9][0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

export function isPhoneValid(value?: string, optional = false): boolean {
  if (optional && !value) {
    return true
  }
  if (!value) {
    return false
  }
  return US_PHONE_REGEXP.test(value)
}

export function normalizePhone(value: string): string {
  value = value.replace(/\D/g, "")
  if (value.startsWith("+1")) {
    return value.substring(2)
  } else if (value.startsWith("1")) {
    return value.substring(1)
  }
  return value
}
