// @team @cat-crew
import { del, get, patch, post } from "../../../services/api"
import { CatalogSeed, Permissioned } from "../types/sharedTypes"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"

export const deleteCatalogSeed = async (id: string | number) => {
  return await del(`/cms/catalog/seeds/${id}.json`)
    .then(() => true)
    .catch(() => false)
}

export const updateCatalogSeed = async (
  id: string | number,
  params: Record<string, any>
) => {
  return patch(`/cms/catalog/seeds/${id}.json`, params).then(
    (response) => response.data
  )
}

export const createCatalogSeed = async (params: Record<string, any>) => {
  return post(`/cms/catalog/seeds.json`, params).then(
    (response) => response.data
  )
}

export type CatalogSeedsParams = ServerSideDataGridParams
export type CatalogSeedsResponse = {
  catalogSeeds: CatalogSeed[]
  totalCount: number
  pageSize: number
} & Permissioned
export const getCatalogSeeds = async (
  params: CatalogSeedsParams
): Promise<CatalogSeedsResponse> => {
  return await get(`/cms/catalog/seeds.json`, params).then((response) => {
    return response.data
  })
}

export const getCatalogSeed = async (
  id: string | number
): Promise<CatalogSeed> => {
  return await get(`/cms/catalog/seeds/${id}.json`).then((response) => {
    return response.data.catalogSeed
  })
}

export const bulkImportCatalogSeeds = async (
  supplierId: string | number,
  params: Record<string, any>
) => {
  return await post(
    `/cms/catalog/suppliers/${supplierId}/catalog_imports.json`,
    params
  ).then((response) => response.data)
}
