import React, { useState } from "react"
import { HospicePatient } from "sharedTypes"
import UpdatePatientHospiceForm, { FormData } from "./UpdatePatientHospiceForm"
import Modal from "components/Modal"
import Icon from "components/Icon"
import * as styles from "./InternalPatientProfile.module.scss"

type Props = {
  hospicePatient: HospicePatient
  updateHospiceDates(
    values: FormData
  ): Promise<{ hospicePatient: HospicePatient }>
  updateHospicePermission: boolean
}

export default (props: Props) => {
  const { hospicePatient, updateHospiceDates, updateHospicePermission } = props

  const [currentHospicePatient, setCurrentHospicePatient] = useState(
    hospicePatient
  )
  const [showModal, setShowModal] = useState(false)

  const onSave = async (values) => {
    return updateHospiceDates(values).then(({ hospicePatient }) => {
      setCurrentHospicePatient(hospicePatient)
      setShowModal(false)
    })
  }

  return (
    <div>
      <span className={styles.demographicsHeader}>HOSPICE STATUS</span>
      <br />
      {updateHospicePermission && (
        <>
          <a className="link" onClick={() => setShowModal(true)}>
            {currentHospicePatient.hospiceStatus}&nbsp;
            <Icon type="pencil-alt" />
          </a>
          <Modal
            size="md"
            title="Edit patient status"
            show={showModal}
            closeable={false}
          >
            <Modal.Body>
              <UpdatePatientHospiceForm
                hospicePatient={currentHospicePatient}
                onSave={onSave}
                cancelButton={() => setShowModal(false)}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
      {!updateHospicePermission && currentHospicePatient.hospiceStatus}
    </div>
  )
}
