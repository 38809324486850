import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  CarrierLineItemAuthorizationDeleteMutation,
  CarrierLineItemAuthorizationDeleteMutationVariables,
} from "../../../../graphql/__generated__/graphql"

export const CarrierLineItemAuthorizationDelete: TypedDocumentNode<
  CarrierLineItemAuthorizationDeleteMutation,
  CarrierLineItemAuthorizationDeleteMutationVariables
> = gql`
  mutation CarrierLineItemAuthorizationDelete($id: ID!) {
    carrierLineItemAuthorizationDelete(input: { id: $id }) {
      carrierLineItemAuthorization {
        id
      }
    }
  }
`
