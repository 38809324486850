import React, { useRef, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { QuestionAndAnswers } from "../../../../../../../../../../sharedTypes"
import ReadOnlyQuestionAnswerList from "applications/Workflow/containers/Review/components/ReadOnlyQuestionAnswerList"

interface Props {
  title: string
  questionsWithSelectedAnswers: QuestionAndAnswers[] | undefined
}

const ExternalQuestionnaireModalButton = (props: Props) => {
  const [open, setOpen] = useState(false)
  const activatorRef = useRef<HTMLButtonElement>(null)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      {/*TODO: Reliant on canopy xsmall button being made
        update the below size to small and remove custom styling
      */}
      {props.questionsWithSelectedAnswers && (
        <CanopyButton
          size="small"
          style={{ minHeight: "24px", maxHeight: "24px" }}
          ref={activatorRef}
          onClick={openModal}
          className="canopy-mr-4x"
        >
          View
        </CanopyButton>
      )}
      <CanopyDialogModal
        activatorElementRef={activatorRef}
        header={props.title}
        headerOverline="Read Only"
        onClose={closeModal}
        open={open}
        size="large"
        primaryFooterButton={
          <CanopyButton onClick={closeModal}>Close</CanopyButton>
        }
      >
        {props.questionsWithSelectedAnswers && (
          <ReadOnlyQuestionAnswerList
            questions={props.questionsWithSelectedAnswers}
          />
        )}
      </CanopyDialogModal>
    </>
  )
}

export default ExternalQuestionnaireModalButton
