// @team @cat-crew
import { CatalogCustomAttribute } from "applications/Cms/types/sharedTypes"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"
import { ApiResponse, ErrorResponse } from "./utilities"
import { del, get, patch, post } from "../../../services/api"

export type CatalogAttributesParams = ServerSideDataGridParams & {
  searchKeywords?: string
}

export type CatalogAttributesResponse = {
  attributes: CatalogCustomAttribute[]
  totalCount: number
}

export const getCatalogAttributes = async (
  params: CatalogAttributesParams
): Promise<CatalogAttributesResponse> => {
  return await get("/cms/catalog/attributes.json", params)
    .then((response) => response.data)
    .catch((data) => data.response.data)
}

export const getCatalogAttribute = async (
  id: string
): Promise<CatalogCustomAttribute> => {
  return await get(`/cms/catalog/attributes/${id}.json`).then(
    (response) => response.data
  )
}

export const createCatalogAttribute = async (
  selectionCaption: string,
  name: string
): Promise<
  ApiResponse<{ attribute: CatalogCustomAttribute; warning?: string }>
> => {
  return await post("/cms/catalog/attributes.json", {
    catalog_custom_attribute: {
      name: name,
      selection_caption: selectionCaption,
    },
  })
    .then((response) => response.data)
    .catch((data) => data.response.data)
}

export const updateCatalogAttribute = async (
  attributeId: string,
  params: Record<string, any>
): Promise<ApiResponse<{ customAttribute: CatalogCustomAttribute }>> => {
  return await patch(`/cms/catalog/attributes/${attributeId}.json`, {
    catalog_custom_attribute: params,
  })
    .then((response) => response.data)
    .catch((data) => data.response.data)
}

export const destroyCatalogAttribute = async (
  attributeId: string
): Promise<boolean | ErrorResponse> => {
  return await del(`/cms/catalog/attributes/${attributeId}.json`)
    .then(() => true)
    .catch((data) => data.response.data)
}
