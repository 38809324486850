import React from "react"
import { DmeOrder, Fulfillment, Employer, EmployerType } from "sharedTypes"
import { formatPhone } from "utilities/phone"
import { capitalize } from "utilities/string"
import { packagesForFulfillment } from "../../utilities"
import {
  addressForFulfillment,
  cityLine,
  formatAddressType,
} from "utilities/address"
import DataRow from "components/DataRow"
import FulfillmentEditor from "../FulfillmentEditor"
import { FormData } from "applications/FulfillmentEditor/components/FulfillmentForm"
import { Link } from "react-router-dom"
import { orderLinkageModalPath } from "applications/Workflow/routes"

type Props = {
  dmeOrder: DmeOrder
  fulfillment: Fulfillment
  updateFulfillment(id: string, values: FormData): Promise<void>
  currentEmployer: Employer
}
const DeliverySectionContent = ({
  dmeOrder,
  fulfillment,
  updateFulfillment,
  currentEmployer,
}: Props) => {
  const address = addressForFulfillment(dmeOrder.addresses, fulfillment)

  const renderDeliveryNote = (deliveryNote: string) => {
    if (!deliveryNote) {
      return
    }

    return (
      <div className="well well-info canopy-mbs-8x canopy-mbe-0">
        <div className="canopy-mbs-0 canopy-mbe-0 preline">{deliveryNote}</div>
      </div>
    )
  }

  const formatAddress = (address) => {
    return (
      <>
        {address.addressType && (
          <span className="bold color-black">
            {capitalize(formatAddressType(address))}
            <br />
          </span>
        )}
        {[address.line1, address.line2, address.room, cityLine(address)]
          .filter((l) => !!l)
          .map((l) => (
            <React.Fragment key={l}>
              {l}
              <br />
            </React.Fragment>
          ))}
      </>
    )
  }

  const supplierSystemOrderLinkage = () => (
    <>
      <div>
        {fulfillment.supplierFulfillment?.supplierSystemId ||
          "Assign on Accept"}
      </div>
      {dmeOrder.permissions.supplierFulfillments.manage && (
        <Link className="link" to={orderLinkageModalPath(fulfillment.id)}>
          {fulfillment.supplierFulfillment?.supplierSystemId ? "Edit" : "Add"}{" "}
          Sales ID
        </Link>
      )}
    </>
  )

  return (
    <div key={fulfillment.id}>
      <DataRow
        label="Products"
        value={packagesForFulfillment(dmeOrder, fulfillment)}
      />
      <DataRow
        label="Address"
        value={
          <>
            {formatAddress(address)}
            <br />
            {formatPhone(fulfillment.deliveryPhoneNumber)}
          </>
        }
      />
      <DataRow label="Requested by" value={fulfillment.deliveryDate} />
      {renderDeliveryNote(fulfillment.deliveryNote)}
      {dmeOrder.permissions.updateDeliveryData &&
        dmeOrder.lineItemGroups.length > 0 && (
          <div className="row canopy-mbs-8x">
            <div className="col-12">
              <FulfillmentEditor
                dmeOrder={dmeOrder}
                updateFulfillment={updateFulfillment}
              />
            </div>
          </div>
        )}
      {dmeOrder.permissions.supplierFulfillments.show &&
        currentEmployer.employerType === EmployerType.Supplier && (
          <DataRow label="BT Sales ID" value={supplierSystemOrderLinkage()} />
        )}
    </div>
  )
}

export default DeliverySectionContent
