import React from "react"
import * as styles from "./ActionableOrderCard.module.scss"
import classNames from "classnames"
import { Chip } from "@material-ui/core"
interface Props {
  dmeOrderFollowers: {
    name: string
    initials: string
    id: string
  }[]
}

const FollowersSection: React.FC<Props> = ({ dmeOrderFollowers }) => {
  const currentUserId = window.parachute?.currentUserId

  let followers = dmeOrderFollowers.map((follower) => ({
    ...follower,
    isUser: currentUserId === follower.id,
  }))

  followers = followers.sort((x, y) => Number(y.isUser) - Number(x.isUser))

  return (
    <div>
      <h4 className={styles.detailHeader}>FOLLOWERS</h4>
      <div className={styles.followerChipsContainer}>
        {followers.map((follower) => (
          <Chip
            label={follower.initials}
            key={follower.id}
            className={classNames(
              styles.chip,
              styles.followerChip,
              follower.isUser && styles.followerSelfChip
            )}
            color="primary"
            classes={{
              label: classNames(
                styles.followerChipLabel,
                follower.isUser && styles.followerSelfChipLabel
              ),
            }}
            variant="outlined"
            data-testid="follower-chip"
          />
        ))}
      </div>
    </div>
  )
}

export default FollowersSection
