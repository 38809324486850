import React, { useState, useEffect } from "react"
import InternalNewOrderForm from "./components/InternalNewOrderForm"
import { makeApi, preCreateOrder } from "./api"
import {
  MasterPatient,
  CsrInboxDocument,
  ContextEmployer,
  EmployerType,
} from "sharedTypes"
import { employerTypeFromContext } from "utilities/url"
import { Supplier } from "applications/SupplierOrganizationSettings/components/SupplierSelector"
import { navigate, openNewTab } from "../../utilities/navigation"
import { handleError } from "utilities/error"
import { NewOrderFormPatient } from "./sharedTypes"

type Props = {
  employer?: ContextEmployer
  masterPatient?: MasterPatient
  patientSearch?: NewOrderFormPatient
  document?: CsrInboxDocument
  intakePreCreateOrderUrl?: string | undefined
  intakePreCreateWithoutFacility?: boolean
}

function NewOrderForm({
  employer,
  masterPatient,
  patientSearch,
  document,
  intakePreCreateOrderUrl,
  intakePreCreateWithoutFacility,
}: Props) {
  const patientFromMasterPatient = masterPatient
    ? {
        firstName: masterPatient.firstName,
        lastName: masterPatient.lastName,
        dateOfBirth: masterPatient.dateOfBirth,
        sex: masterPatient.sex,
      }
    : undefined
  const employerId = employer ? employer.employerId : ""
  const employerType = employer ? employer.employerType : EmployerType.None

  const [showModal, setShowModal] = useState(false)
  const [patient, setPatient] = useState<NewOrderFormPatient | undefined>(
    patientFromMasterPatient
  )
  const [supplierId, setSupplierId] = useState<string>("")
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [api, setApi] = useState(
    makeApi(employerTypeFromContext(employerType), employerId)
  )

  useEffect(() => {
    const needsSupplier = () => {
      return employerType === "supplier_organization" && supplierId === ""
    }

    if (needsSupplier()) {
      const api = makeApi(employerTypeFromContext(employerType), employerId)

      api.fetchSuppliers().then((response) => {
        const responseSuppliers = response.data.result
        setSuppliers(responseSuppliers)
        if (responseSuppliers.length === 1) {
          setApi(makeApi("Supplier", responseSuppliers[0].id))
          setSupplierId(responseSuppliers[0].id)
        }
      })
    }
  }, [supplierId, employerId, employerType])

  const readyToPreCreateOrderWithoutFacility =
    !!document &&
    !!intakePreCreateWithoutFacility &&
    !!intakePreCreateOrderUrl &&
    !!patient &&
    showModal

  useEffect(() => {
    if (readyToPreCreateOrderWithoutFacility) {
      const createOrderParams = {
        patient,
        masterPatientId: masterPatient?.id,
      }
      preCreateOrder(intakePreCreateOrderUrl, createOrderParams)
        .then((response) => navigate(response.data.preCreateUrl))
        .catch(handleError)
    }
  }, [
    readyToPreCreateOrderWithoutFacility,
    intakePreCreateOrderUrl,
    masterPatient,
    patient,
  ])

  const selectSupplier = (supplierId: string) => {
    setApi(makeApi("Supplier", supplierId))
    setSupplierId(supplierId)
  }

  const supplierEmployer = (): ContextEmployer => ({
    employerType: EmployerType.Supplier,
    employerId: supplierId,
    multiEmployersInOrganization: false,
    patientPortalEnabled: false,
  })

  const facilitySubmitButtonText = intakePreCreateOrderUrl
    ? "Save and Review Patient Info"
    : "Create New Order"
  const facilitySubmit = (
    clinicalFacilityId,
    doctorId,
    selectedLegalEntityId,
    selectedExternalSystemBranchId,
    selectedSalesRepId,
    legalEntityAssignmentMethod
  ) => {
    const documentIds = document ? [document.documentId] : []
    const createOrderParams = {
      clinicalFacilityId,
      doctorId,
      patient,
      documentIds,
      legalEntityAssignmentMethod,
      externalSystemBranchId: selectedExternalSystemBranchId,
      externalSalesUserId: selectedSalesRepId,
      supplierLegalEntityId: selectedLegalEntityId,
      masterPatientId: masterPatient?.id,
    }
    if (intakePreCreateOrderUrl) {
      return preCreateOrder(
        intakePreCreateOrderUrl,
        createOrderParams
      ).then((response) => navigate(response.data.preCreateUrl))
    }
    return api.createDmeOrder(createOrderParams).then((response) => {
      if (!!response.data.dmeOrderPath) {
        if (document) {
          openNewTab(response.data.dmeOrderPath)
          window.history.back()
        } else {
          navigate(response.data.dmeOrderPath)
        }
      }
    })
  }

  const openModal = () => setShowModal(true)
  const closeModal = () => {
    setPatient(patientFromMasterPatient)
    setSupplierId("")
    setShowModal(false)
  }

  return (
    <InternalNewOrderForm
      employer={supplierId ? supplierEmployer() : employer}
      patient={patient}
      setPatient={setPatient}
      patientValidate={api.patientValidate}
      patientSearch={patientSearch}
      documentFacility={{
        id: document?.clinicalFacilityId || "",
        name: document?.clinicalFacilityName || "",
      }}
      facilitySubmitButtonText={facilitySubmitButtonText}
      facilitySubmit={facilitySubmit}
      openModal={openModal}
      closeModal={closeModal}
      showModal={showModal}
      newPatient={!masterPatient}
      supplierId={supplierId}
      suppliers={suppliers}
      setSupplierId={selectSupplier}
      skipFacilitySelect={readyToPreCreateOrderWithoutFacility}
    />
  )
}

export default NewOrderForm
