import { CatalogSeed, Permissioned } from "../../../../types/sharedTypes"
import React, { useEffect, useState } from "react"
import { isTest } from "../../../../../../utilities/environment"
import {
  DataGridPro,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro"
import { Box, CircularProgress } from "@material-ui/core"
import { CanopyButton } from "@parachutehealth/canopy-button"
import ContentArea from "../../../../components/ContentArea"
import DataGridToolbar from "../../../../components/DataGridToolbar"
import { Link, useHistory } from "react-router-dom"
import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "../../../../hooks/useServerSideDataGrid"
import { getCatalogSeed, getCatalogSeeds } from "../../../../api/seeds"
import NoRows from "../../../../components/DataGrid/NoRows"
import renderTimestamp from "../../../../components/DataGrid/cells/renderTimestamp"
import { seedCreateUrl } from "../../../../urls/seeds"
import SeedPackagesDrawer from "../SeedPackagesDrawer"
import SeedProductVariationsDrawer from "../SeedProductVariationsDrawer"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import ArchivedLabel from "../ArchivedTooltip"
import InfoTooltip from "../../../../components/InfoTooltip"

type CatalogSeedsOptions = ServerSideDataGridOptions
type DrawerType = "packages" | "productVariations"
type DrawerState = { open: boolean; type: DrawerType | null }

const usePageTitle = (title: string): void => {
  useEffect(() => {
    document.title = title
  }, [title])
}

const SeedsPage: React.FC = (): React.JSX.Element => {
  usePageTitle("Catalog Seeds")

  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)

  const beforeFetch = () => {
    setLoading(true)
  }
  const afterFetch = () => {
    setLoading(false)
  }

  const [seedsData, setSeedsData] = useState<
    {
      catalogSeeds: CatalogSeed[]
      totalCount: number
      pageSize: number
    } & Permissioned
  >({
    catalogSeeds: [],
    totalCount: 0,
    pageSize: 0,
    permissions: {},
  })

  const defaultOptions: CatalogSeedsOptions = {
    page: 1,
    sort: undefined,
  }

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getCatalogSeeds(params)

    setSeedsData((prev) => ({
      ...prev,
      catalogSeeds: data.catalogSeeds,
      totalCount: data.totalCount,
      pageSize: data.pageSize,
      permissions: data.permissions,
    }))
  }

  /**
   * The key to store the button that launched the currently-loaded state
   * is logically centralized here.
   */
  const buttonKey = (type: DrawerType, id: string): string => `${type}${id}`

  type DrawerLaunchingCellProps = {
    params: GridRenderCellParams
    type: DrawerType
    countProperty: string
  }
  const DrawerLaunchingCell = (props: DrawerLaunchingCellProps) => {
    const { params, type, countProperty } = props
    const count: number = params.row[countProperty] || 0
    const selectedButton = buttonKey(type, params.row.externalId)
    const disabled =
      count === 0 || Boolean(loadingButton && loadingButton !== selectedButton)

    if (disabled) {
      return <b>{count}</b>
    } else if (loadingButton === selectedButton) {
      return (
        <b>
          <CircularProgress
            title="Loading..."
            color="primary"
            style={{ width: "16px" }}
            size="small"
          />
        </b>
      )
    } else {
      return (
        <a
          href="" // needed for styling
          role="button"
          title="Click to view detail"
          onClick={(e) => {
            e.preventDefault()
            void openDrawer(params.row.externalId, type)
          }}
        >
          {count}
        </a>
      )
    }
  }

  const columns: GridColDef[] = [
    {
      field: "externalId",
      flex: 1,
      hide: true,
      headerName: "ID",
      filterable: false,
    },
    {
      field: "name",
      flex: 1,
      headerName: "Seed Name",
      renderCell: (params) => {
        return (
          <>
            <Link to={params.row?.url} tabIndex={params.tabIndex}>
              {(params?.formattedValue || params?.value)?.toString()}
            </Link>
            {params.row.internalNotes && (
              <InfoTooltip
                className="canopy-mis-6x color-dark-gray"
                title={params.row.internalNotes}
              />
            )}
            {params.row.hasInvalidReferences && (
              <ArchivedLabel message="This seed references archived global items that will be skipped during import" />
            )}
          </>
        )
      },
    },
    {
      field: "packageCount",
      flex: 1,
      headerName: "Packages",
      type: "number",
      filterable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => (
        <DrawerLaunchingCell
          params={params}
          countProperty="packageCount"
          type="packages"
        />
      ),
    },
    {
      field: "productVariationCount",
      flex: 1,
      headerName: "SKUs",
      type: "number",
      filterable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params: GridRenderCellParams) => (
        <DrawerLaunchingCell
          params={params}
          countProperty="productVariationCount"
          type="productVariations"
        />
      ),
    },
    {
      field: "createdAt",
      flex: 1,
      headerName: "Created",
      renderCell: renderTimestamp,
      minWidth: 200,
      hide: true,
      type: "dateTime",
    },
    {
      field: "updatedAt",
      flex: 1,
      headerName: "Updated",
      renderCell: renderTimestamp,
      minWidth: 200,
      hide: true,
      type: "dateTime",
    },
  ]

  const [loadingButton, setLoadingButton] = useState<string | null>(null)
  const [selectedSeed, setSelectedSeed] = useState<CatalogSeed | null>(null)
  const [drawerState, setDrawerState] = useState<DrawerState>({
    open: false,
    type: null,
  })

  const openDrawer = async (seedId: string, displayType: DrawerType) => {
    setLoadingButton(buttonKey(displayType, seedId))
    await getCatalogSeed(seedId).then((seed) => {
      setSelectedSeed(seed)
      setDrawerState({ open: true, type: displayType })
      setLoadingButton(null)
    })
  }

  const closeDrawer = () => {
    setSelectedSeed(null)
    setDrawerState({ open: false, type: null })
  }

  const {
    filterModel,
    options,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<CatalogSeedsOptions>({
    defaultFilterModel: {
      columnFilters: [],
      columnFilterLinkOperator: GridLinkOperator.And,
    },
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  return (
    <>
      <Box mt={1} mb={3}>
        <h2 className="canopy-typography-heading-2xlarge">Catalog Seeds</h2>
        <p className="canopy-typography-body-medium">
          A group of Packages and SKUs that can be bulk-imported into a
          supplier's catalog.
        </p>
      </Box>
      <ContentArea>
        {seedsData.permissions?.canAddNewSeed && (
          <>
            <Box mb={2} style={{ display: "flex", justifyContent: "end" }}>
              <CanopyButton
                variant="primary"
                size="small"
                disabled={!Boolean(seedsData.permissions?.canAddNewSeed)}
                onClick={() => history.push(seedCreateUrl())}
              >
                New Catalog Seed
              </CanopyButton>
            </Box>
          </>
        )}
        <Box className={classNames(styles.seedsDatagrid)}>
          <DataGridPro
            loading={loading}
            className="borderless"
            rows={seedsData.catalogSeeds}
            rowCount={seedsData.totalCount}
            autoHeight
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            density="standard"
            columns={columns}
            pagination={true}
            page={options.page - 1} // account for DataGrid's zero-based indexing
            pageSize={seedsData.pageSize}
            rowsPerPageOptions={[seedsData.pageSize]}
            paginationMode="server"
            onPageChange={(page) => {
              handlePageChange(page + 1)
            }} // account for DataGrid's zero-based indexing
            getRowId={(row) => row.externalId}
            sortingMode="server"
            sortModel={options.sort}
            onSortModelChange={handleSortModelChange}
            disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
            hideFooterSelectedRowCount
            components={{
              Toolbar: DataGridToolbar,
              NoRowsOverlay: NoRows,
            }}
            componentsProps={{
              noRowsOverlay: {
                message: "There are currently no Catalog Seeds.",
              },
              toolbar: { filter: true },
            }}
          />
          {selectedSeed && (
            <SeedPackagesDrawer
              open={drawerState.open && drawerState.type === "packages"}
              onClose={closeDrawer}
              catalogSeed={selectedSeed}
            />
          )}

          {selectedSeed && (
            <SeedProductVariationsDrawer
              open={
                drawerState.open && drawerState.type === "productVariations"
              }
              onClose={closeDrawer}
              catalogSeed={selectedSeed}
            />
          )}
        </Box>
      </ContentArea>
    </>
  )
}

export default SeedsPage
