import { del, post, patch } from "services/api"
import {
  deleteSupplierOrderClassificationMappingPath,
  supplierOrderClassificationMappingsUrl,
  updateSupplierOrderClassificationMappingsUrl,
} from "./urls"

export const createSalesOrderClassificationMapping = (
  salesOrderClassificationMappingsPath,
  values
) => {
  return post(
    supplierOrderClassificationMappingsUrl(
      salesOrderClassificationMappingsPath
    ),
    {
      ...values,
    }
  ).then(({ data }) => data)
}

export const updateSalesOrderClassificationMapping = (
  salesOrderClassificationMappingsPath,
  mappingId,
  values
) => {
  return patch(
    updateSupplierOrderClassificationMappingsUrl(
      salesOrderClassificationMappingsPath,
      mappingId
    ),
    values
  ).then(({ data }) => data)
}

export const deleteSalesOrderClassificationMapping = async (
  salesOrderClassificationMappingsPath: string,
  mappingId: string
) => {
  return del(
    deleteSupplierOrderClassificationMappingPath(
      salesOrderClassificationMappingsPath,
      mappingId
    )
  ).then(({ data }) => data)
}
