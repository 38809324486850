import React from "react"
import { datadogRum } from "@datadog/browser-rum"
import { PackageWithCartData } from "../sharedTypes"
import GridItem from "./GridItem"
import { CatalogBrowseTab, DmeOrder } from "sharedTypes"

type Props = {
  currentCatalogBrowseTab: CatalogBrowseTab
  currentPage: number
  dmeOrder: DmeOrder
  onSelect(p: PackageWithCartData): Promise<void>
  packages: PackageWithCartData[]
  yourOrganizationsSuppliersOnly: boolean
  searchContextForRum?: Record<string, any>
}

const Grid = ({
  currentCatalogBrowseTab,
  currentPage,
  dmeOrder,
  packages,
  onSelect,
  yourOrganizationsSuppliersOnly,
  searchContextForRum = {},
}: Props) => {
  const monitorAction = (pkg: PackageWithCartData) => {
    datadogRum.addAction(`click on Result on Page ${currentPage}`, {
      packageName: pkg.name,
      packageId: pkg.externalId,
      numberInCart: pkg.numberInCart,
      ...searchContextForRum,
    })
  }

  return (
    <div className="grid row gutter-0 display-flex">
      {packages.map((pkg) => (
        <GridItem
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          dmeOrder={dmeOrder}
          key={pkg.externalId}
          onSelect={async (pkg): Promise<void> => {
            monitorAction(pkg)
            onSelect(pkg)
          }}
          pkg={pkg}
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        />
      ))}
    </div>
  )
}

export default Grid
