import React from "react"
import { SvgIcon } from "@material-ui/core"

export default (props: React.ComponentProps<typeof SvgIcon>) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <g>
      <rect fill="none" height="16" width="16" />
    </g>
    <g>
      <g>
        <path
          d="M2.55556 3.125H7.22222C7.43611 3.125 7.61111 3.29375 7.61111 3.5V11.743C7.88576 11.2742 8.28924 10.8828 8.77778 10.6203V8.37266V3.5C8.77778 2.67266 8.08021 2 7.22222 2H2.55556C1.69757 2 1 2.67266 1 3.5V12.5C1 13.3273 1.69757 14 2.55556 14H7.22222C7.3 14 7.37778 13.9953 7.45313 13.9836C7.30729 13.7492 7.22222 13.4727 7.22222 13.1773C7.22222 13.0742 7.22708 12.9734 7.23924 12.8727C7.23438 12.8727 7.22951 12.8727 7.22222 12.8727L2.55556 12.875C2.34167 12.875 2.16667 12.7063 2.16667 12.5V11.75H4.30556C4.62882 11.75 4.88889 11.4992 4.88889 11.1875C4.88889 10.8758 4.62882 10.625 4.30556 10.625H2.16667V9.5H4.30556C4.62882 9.5 4.88889 9.24922 4.88889 8.9375C4.88889 8.62578 4.62882 8.375 4.30556 8.375H2.16667V3.5C2.16667 3.29375 2.34167 3.125 2.55556 3.125ZM4.30556 4.625V5.1875H3.72222C3.50833 5.1875 3.33333 5.35625 3.33333 5.5625V5.9375C3.33333 6.14375 3.50833 6.3125 3.72222 6.3125H4.30556V6.875C4.30556 7.08125 4.48056 7.25 4.69444 7.25H5.08333C5.29722 7.25 5.47222 7.08125 5.47222 6.875V6.3125H6.05556C6.26944 6.3125 6.44444 6.14375 6.44444 5.9375V5.5625C6.44444 5.35625 6.26944 5.1875 6.05556 5.1875H5.47222V4.625C5.47222 4.41875 5.29722 4.25 5.08333 4.25H4.69444C4.48056 4.25 4.30556 4.41875 4.30556 4.625ZM13.4444 8.375C13.4444 7.87772 13.2396 7.40081 12.8749 7.04917C12.5103 6.69754 12.0157 6.5 11.5 6.5C10.9843 6.5 10.4897 6.69754 10.1251 7.04917C9.76042 7.40081 9.55556 7.87772 9.55556 8.375C9.55556 8.87228 9.76042 9.34919 10.1251 9.70083C10.4897 10.0525 10.9843 10.25 11.5 10.25C12.0157 10.25 12.5103 10.0525 12.8749 9.70083C13.2396 9.34919 13.4444 8.87228 13.4444 8.375ZM8 13.182C8 13.6344 8.37917 14 8.84826 14H14.1517C14.6208 14 15 13.6344 15 13.182C15 11.9773 13.9865 11 12.7372 11H10.2628C9.01354 11 8 11.9773 8 13.182Z"
          fill="#520079"
        />
      </g>
    </g>
  </SvgIcon>
)
