// @ts-strict-ignore
import {
  DoctorDmeOrderDetails,
  DoctorSignatureRequest,
  DoctorSignatureRequestDetails,
  getDmeOrderDetails,
  getSignatureRequestDetails,
} from "../../api"
import React, { useEffect, useState, useMemo } from "react"
import DocumentsBrowser from "components/DocumentsBrowser"
import DmeOrderPreview from "./DmeOrderPreview"
import { EventCategory, trackEvent } from "utilities/tracking"
import * as styles from "./preview.module.scss"
import { useStyles } from "themes/theme"
import { documentHeightCalculation } from "../../utilities/heightCalculation"
import { useFlashContext } from "../../hooks/useFlashContext"
import { FeatureFlagProvider } from "components/FeatureFlagContext"

const documentsBrowserStyles = (flash, hasBackButton) => ({
  sidebarDocuments: {
    maxHeight: `${documentHeightCalculation(flash, hasBackButton)} !important`,
    minHeight: `${documentHeightCalculation(flash, hasBackButton)} !important`,
  },
})

export const Preview = (props: {
  signatureRequest: DoctorSignatureRequest
  doctorId: string
  hasBackButton?: boolean
}) => {
  const { doctorId, signatureRequest, hasBackButton } = props

  const [signatureRequestDetails, setSignatureRequestDetails] = useState<
    DoctorSignatureRequestDetails
  >(undefined)
  const [dmeOrderDetails, setDmeOrderDetails] = useState<DoctorDmeOrderDetails>(
    undefined
  )
  const flash = useFlashContext()
  const muiStyles = useMemo(
    () => documentsBrowserStyles(flash, hasBackButton),
    [flash, hasBackButton]
  )
  const muiClasses = useStyles(muiStyles)

  useEffect(() => {
    if (signatureRequest?.type === "Signing::SignatureRequest") {
      getSignatureRequestDetails(
        doctorId,
        signatureRequest.id
      ).then((details) => setSignatureRequestDetails(details))
      setDmeOrderDetails(undefined)
    }
    if (signatureRequest?.type === "DmeOrder") {
      getDmeOrderDetails(doctorId, signatureRequest.id).then((details) =>
        setDmeOrderDetails(details)
      )
      setSignatureRequestDetails(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureRequest.id, signatureRequest.type])

  if (signatureRequest?.type === "DmeOrder" && dmeOrderDetails) {
    const { dmeOrder, downloadableDocuments, context } = dmeOrderDetails
    return (
      <FeatureFlagProvider flags={context.featureFlags}>
        <DmeOrderPreview
          dmeOrder={dmeOrder}
          downloadableDocuments={downloadableDocuments}
          context={context}
          className={muiClasses.sidebarDocuments}
        />
      </FeatureFlagProvider>
    )
  }
  if (
    signatureRequest?.type === "Signing::SignatureRequest" &&
    signatureRequestDetails
  ) {
    const document =
      signatureRequestDetails.signedDocument || signatureRequestDetails.document
    const DOWNLOAD_DOCUMENTATION_ORDER_DOCUMENT_EVENT_NAME =
      "reviewer_dash_download_documentation"
    return (
      <div className={`${styles.documentsContainer} right-sidebar-body`}>
        <DocumentsBrowser
          documents={[document]}
          onDownload={() => {
            trackEvent(
              EventCategory.Resupply,
              DOWNLOAD_DOCUMENTATION_ORDER_DOCUMENT_EVENT_NAME
            )
          }}
          className={muiClasses.sidebarDocuments}
        />
      </div>
    )
  }
  return null
}
