import React from "react"
import { Field, FormikErrors } from "formik"
import DatePickerInput from "components/input/DatePickerInput"
import { SurveyAnswerType, SurveyQuestion } from "sharedTypes"
import { InitialValues, Values } from "../sharedTypes"
import Checkbox from "../form/Checkbox"
import { SaveButton } from "../components/SaveButton"
import QuestionTitle from "../components/QuestionTitle"
import {
  convert,
  DATE_FORMAT,
  isDateValid,
  SERVER_DATE_FORMAT,
} from "../../../../../utilities/date"

type Props = {
  question: SurveyQuestion
  inputId: string
  dirty: boolean
  errors: FormikErrors<Values>
  values: Values
  isSubmitting: boolean
  valid: boolean
  missingRequiredInformation: boolean
}

const validate = (values: Values) => {
  if (isDateValid(values.answerValue) || values.na) {
    return {}
  }
  return { answerValue: "Invalid date" }
}

const getInitialValues: (question: SurveyQuestion) => InitialValues = (
  question
) => {
  if (question.answerType === SurveyAnswerType.Na) {
    return { answerValue: undefined, na: true }
  } else if (isDateValid(question.answerValue)) {
    return { answerValue: convert(question.answerValue, DATE_FORMAT) }
  }
  return { answerValue: undefined }
}

const formatAnswer = (values: Values) => {
  const { answerValue, na } = values
  if (na) {
    return { answerType: SurveyAnswerType.Na, answerValue: null }
  }
  return {
    answerValue: convert(answerValue, SERVER_DATE_FORMAT),
    answerType: SurveyAnswerType.Value,
  }
}

const QuestionInputDate: React.SFC<Props> = ({
  question,
  inputId,
  dirty,
  errors,
  values,
  isSubmitting,
  valid,
  missingRequiredInformation,
}) => {
  const renderSaveButton: boolean | undefined =
    dirty && (!errors.answerValue || values.na)

  return (
    <div>
      <Field name="answerValue">
        {({ form, field }) => (
          <>
            <QuestionTitle
              questionTitle={question.title}
              questionText={question.text}
              tooltips={question.tooltips}
              questionId={inputId}
            />
            <DatePickerInput
              {...field}
              id={inputId}
              disabled={form.values.na}
              handleNavigationKey={form.submitForm}
              width="sm"
            />
          </>
        )}
      </Field>
      {question.naEnabled && (
        <Field name="na">
          {({ form, field }) => (
            <Checkbox
              {...field}
              id={`na-${question.questionId}`}
              checked={!!form.values.na}
              label={question.naLabel}
              onChange={(checked) => {
                form.setFieldValue("answerValue", "")
                form.setFieldValue("na", checked)
              }}
            />
          )}
        </Field>
      )}
      {renderSaveButton && (
        <SaveButton
          isSubmitting={isSubmitting}
          valid={valid}
          missingRequiredInformation={missingRequiredInformation}
          question={question}
        />
      )}
    </div>
  )
}

export {
  QuestionInputDate as QuestionComponent,
  validate,
  getInitialValues,
  formatAnswer,
}
