import React from "react"
import RecentOrderCard from "./RecentOrderCard"
import { ContextEmployer, RecentOrder } from "sharedTypes"
import { replaceUnderscores } from "utilities/string"
import * as styles from "./InternalPatientProfile.module.scss"

type Props = {
  recentOrders: RecentOrder[]
  showDmeOrderUrl(dmeOrderId: string): string
  showSuppliers: boolean
  currentEmployer: ContextEmployer
}

export default (props: Props) => {
  const {
    recentOrders,
    showDmeOrderUrl,
    showSuppliers,
    currentEmployer,
  } = props
  const hasOrders = !!recentOrders && recentOrders.length > 0

  return (
    <>
      {hasOrders && (
        <div className="canopy-pbs-8x">
          <h2 className={styles.subhead}>Recent orders</h2>
          <div className="cards">
            {recentOrders.map((recentOrder) => (
              <RecentOrderCard
                key={recentOrder.id}
                recentOrder={recentOrder}
                dmeOrderUrl={showDmeOrderUrl(recentOrder.id)}
                showSuppliers={showSuppliers}
              />
            ))}
          </div>
        </div>
      )}
      {!hasOrders && (
        <div className="canopy-pbs-8x">
          {`This patient has no orders with this ${replaceUnderscores(
            currentEmployer.employerType
          )}.`}
        </div>
      )}
    </>
  )
}
