import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  CarrierAuthorizationByIdQueryQuery,
  CarrierAuthorizationByIdQueryQueryVariables,
} from "../../../../graphql/__generated__/graphql"

export const GetCarrierAuthorizationById: TypedDocumentNode<
  CarrierAuthorizationByIdQueryQuery,
  CarrierAuthorizationByIdQueryQueryVariables
> = gql`
  query CarrierAuthorizationByIdQuery($id: String!) {
    carrierAuthorization(id: $id) {
      id
      authStatus
      createdAt
      requestSubmitDateTime
      referralType
      sourceType
      orderingDoctor {
        name
        credential
        firstName
        lastName
        line1
        line2
        city
        state
        zip
        phoneNumber
        faxNumber
        email
        npi
      }
      carrierLineItemAuthorizations {
        id
        hcpcs
        hcpcsDetails
        deliveryAddress
        quantity
        unitsOfMeasure
        startDate
        endDate
        priority
        supplierLegalEntity {
          name
        }
      }
      orderingFacility {
        name
        line1
        line2
        city
        state
        zip
        phoneNumber
        faxNumber
        email
      }
      supplier {
        name
        npi
        phoneNumber
        line1
        line2
        city
        state
        zip
      }
      dmeOrder {
        id
        icd10Codes {
          codeLabel
          description
        }
        supplierLegalEntity {
          name
          npi
          phoneNumber
          line1
          line2
          city
          state
          zip
        }
      }
      carrierMembership {
        memberNumber
        personIdSuffix
        mbi
        altMemberNumber
        enrollmentStatus
        enrollmentStartDate
        enrollmentEndDate
        firstName
        lastName
        dateOfBirth
        sex
        relationToSubscriber
        line1
        line2
        city
        state
        zip
        phoneNumber
        groupNumber
        insuranceProduct {
          id
          planType
          name
        }
        planType
        planTypeCode
        cmsContractId
        cmsPlanId
        subscriber {
          firstName
          lastName
          dateOfBirth
          memberNumber
          altMemberNumber
          personIdSuffix
          mbi
          sex
          line1
          line2
          city
          state
          zip
          phoneNumber
        }
      }
      submitter {
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
`
