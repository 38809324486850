import React, { FC } from "react"
import { Chip, Divider } from "@material-ui/core"
import { OrderStatus, SignatureStatus } from "sharedTypes"
import * as styles from "./ActionableOrderCard.module.scss"
import classNames from "classnames"

interface Props {
  signatureStatus: SignatureStatus
  orderStatus: OrderStatus
  unsatisfiedManualDocumentation: boolean
  stackChips: boolean
}

const SignatureAndDocumentStatus: FC<Props> = ({
  orderStatus,
  signatureStatus,
  unsatisfiedManualDocumentation,
  stackChips,
}) => {
  if (orderStatus === OrderStatus.FacilityInProgress) return null
  const showSignatureStatus =
    signatureStatus === SignatureStatus.Needed ||
    signatureStatus === SignatureStatus.Pending ||
    signatureStatus === SignatureStatus.Unknown

  if (!showSignatureStatus && !unsatisfiedManualDocumentation) return null

  const label =
    signatureStatus === SignatureStatus.Needed
      ? "Signature needed"
      : signatureStatus === SignatureStatus.Pending
      ? "Signature pending"
      : signatureStatus === SignatureStatus.Unknown
      ? "Signature unknown"
      : ""

  return (
    <>
      <Divider className="canopy-my-8x" />
      <div
        className={classNames(
          styles.signatureAndDocumentStatus,
          stackChips && styles.stackChips
        )}
        data-testid="signature-document-status"
      >
        {showSignatureStatus && (
          <div>
            <Chip
              className={classNames(
                styles.chip,
                (signatureStatus === SignatureStatus.Pending ||
                  signatureStatus === SignatureStatus.Needed) &&
                  styles.solidWarningChip,
                signatureStatus === SignatureStatus.Unknown &&
                  styles.signatureUnknownChip
              )}
              label={label}
            />
          </div>
        )}
        {unsatisfiedManualDocumentation && (
          <div>
            <Chip
              className={classNames(styles.chip, styles.solidWarningChip)}
              label="Missing documents"
            />
          </div>
        )}
      </div>
    </>
  )
}

export default SignatureAndDocumentStatus
