import React from "react"
import { GridColDef } from "@mui/x-data-grid-pro"
import { ExternalInsuranceInput } from "./ExternalInsuranceInput"
import { AcceptedInsuranceToggle } from "./AcceptedInsuranceToggle"

export const supplierInsuranceSettingsColumnDefinitions = (): GridColDef[] => [
  {
    field: "name",
    flex: 1,
    sortable: true,
    headerName: "Plan Family",
  },
  {
    field: "planType",
    flex: 1,
    headerName: "Type",
    sortable: true,
  },
  {
    field: "id",
    flex: 1,
    headerName: "Parachute ID",
    sortable: false,
  },
  {
    field: "insuranceContract",
    flex: 1,
    sortable: false,
    headerName: "Accepted",
    renderCell: (params) => <AcceptedInsuranceToggle cellParams={params} />,
  },
  {
    field: "externalSystemId",
    flex: 2,
    headerName: "External System ID",
    sortable: false,
    valueGetter: (params) =>
      params.row.externalInsuranceMapping
        ? params.row.externalInsuranceMapping.externalSystemId
        : null,
    renderCell: (params) => <ExternalInsuranceInput cellParams={params} />,
  },
]
