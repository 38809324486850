import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  CarrierAuthorizationByIdQueryQuery,
  CarrierAuthorizationByIdQueryQueryVariables,
} from "graphql/__generated__/graphql"

export const GetCarrierAuthorizationById: TypedDocumentNode<
  CarrierAuthorizationByIdQueryQuery,
  CarrierAuthorizationByIdQueryQueryVariables
> = gql`
  query CarrierAuthorizationById($id: String!) {
    carrierAuthorization(id: $id) {
      id
      requestSubmitDateTime
    }
  }
`
