import { fetchExternalSystemBranchesUrl } from "applications/SupplierOrganizationInboxDocument/urls"
import { get, put } from "services/api"

export const fetchExternalSystemBranches = async (
  supplierId: string,
  term: string
): Promise<{ name: string; id: string }[]> => {
  return get(fetchExternalSystemBranchesUrl(supplierId), { term }).then(
    ({ data }) => data.results
  )
}

export const updateBranch = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  externalSystemBranchId: string | null
): Promise<{ success?: boolean; errors?: boolean }> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`

  const requestBody = {
    supplier_system_branch_id: externalSystemBranchId,
  }
  return put(
    `${pathToCsrInboxState}/facility/update_external_system_branch`,
    requestBody
  )
    .then(() => ({ success: true }))
    .catch(() => ({ errors: true }))
}
