import React from "react"
import { SurveyQuestion } from "sharedTypes"
import { SELECT_MANY_QUESTION_TYPE } from "../sharedTypes"

type Props = {
  question: SurveyQuestion
}

export const HelpText = ({ question }: Props) => {
  if (question.type === SELECT_MANY_QUESTION_TYPE) {
    return null
  }
  return (
    <p className="canopy-mbe-0 canopy-mis-12x font-xs">
      Press <strong>Enter &crarr;</strong> or <strong>Tab &#8677;</strong>
    </p>
  )
}
