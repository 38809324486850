import { gql, TypedDocumentNode } from "@apollo/client"
import {
  CarrierAuthorizationSubmitMutation,
  CarrierAuthorizationSubmitMutationVariables,
} from "../../../../graphql/__generated__/graphql"

export const CarrierAuthorizationSubmit: TypedDocumentNode<
  CarrierAuthorizationSubmitMutation,
  CarrierAuthorizationSubmitMutationVariables
> = gql`
  mutation CarrierAuthorizationSubmit($carrierAuthorizationId: ID!) {
    carrierAuthorizationSubmit(
      input: { carrierAuthorizationId: $carrierAuthorizationId }
    ) {
      carrierAuthorization {
        id
        authStatus
        requestSubmitDateTime
      }
    }
  }
`
