// @team @platform

import { datadogRum } from "@datadog/browser-rum"

import { FeatureFlagName } from "sharedTypes"

export const isPermittedPath = (location: Location): boolean =>
  window.prohibitedUserMonitoringPaths.every(
    (unpermittedPath) => !location.pathname.match(unpermittedPath)
  )

export const isInstrumentationEnabled = (): boolean => {
  if (window.parachute?.currentUserId) {
    return true
  } else {
    return isPermittedPath(window.location)
  }
}

export const initializeDatadogRUM = (): void => {
  if (!isInstrumentationEnabled()) {
    return
  }

  const { datadogConfiguration, clientsideInstrumentation } = window

  if (!datadogConfiguration) {
    console.warn(
      "Instrumentation is enabled but DataDog configuration is unavailable"
    )
    return
  }

  datadogRum.init({
    applicationId: datadogConfiguration.applicationId,
    clientToken: datadogConfiguration.clientToken,
    site: datadogConfiguration.site,
    service: datadogConfiguration.serviceName,
    env: clientsideInstrumentation.releaseStage,
    version: clientsideInstrumentation.commitSHA,
    sessionSampleRate: datadogConfiguration.realUserMonitoring.sampleRate,
    sessionReplaySampleRate:
      datadogConfiguration.realUserMonitoring.sessionReplaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    actionNameAttribute: "data-user-target-name",
    defaultPrivacyLevel: "allow",
    enableExperimentalFeatures: ["feature_flags"],
    allowedTracingUrls: [isAllowedTracingURL],
  })
}

export const instrumentDatadogUser = (): void => {
  datadogRum.setUser({
    id: window.parachute?.currentUserId,
    email: window.parachute?.currentUserEmail,
    employerType: window.parachute?.employerType,
    employerId: window.parachute?.employerId,
    emrContextIndex: window.parachute?.emrContextIndex,
    emrInstanceType: window.parachute?.emrInstanceType,
    onboardingLevel: window.parachute?.currentUserOnboardingLevel,
  })
}

export const initUserInstruments = async (): Promise<void> => {
  initializeDatadogRUM()
  instrumentDatadogUser()
}

export const isAllowedTracingURL = (url: string): boolean =>
  !!url.match(
    /^(?:https:\/\/)(?:(?:app-workflow\.(?:master|production|staging)\.internal)|(?:(?:dme|sandbox)\.parachutehealth\.com)).*$/i
  )

export const instrumentFeatureFlagEvaluation = (
  flagName: FeatureFlagName,
  flagValue: boolean | undefined
): void => datadogRum.addFeatureFlagEvaluation(flagName, flagValue)
