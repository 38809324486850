import React from "react"
import { CarrierAuthorization } from "../../../../../graphql/__generated__/graphql"
import { AuthorizationDetailsSection } from "./AuthorizationDetailsSection/AuthorizationDetailsSection"
import {
  DOCTOR_DETAILS,
  FACILITY_DETAILS,
  PATIENT_DETAILS,
  PLAN_DETAILS,
  REQUEST_DETAILS,
  SUBMITTER_DETAILS,
  SUBSCRIBER_DETAILS,
  SUPPLIER_DETAILS,
  SUPPLIER_LEGAL_ENTITY_DETAILS,
} from "./constants/authorizationDetailsDefinitions"

interface AuthorizationDetailsProps {
  authorization: CarrierAuthorization
}

export const AuthorizationDetails = ({
  authorization,
}: AuthorizationDetailsProps) => {
  const sections = [
    REQUEST_DETAILS,
    SUPPLIER_DETAILS,
    SUPPLIER_LEGAL_ENTITY_DETAILS,
    SUBSCRIBER_DETAILS,
    PLAN_DETAILS,
    PATIENT_DETAILS,
    FACILITY_DETAILS,
    DOCTOR_DETAILS,
    SUBMITTER_DETAILS,
  ]

  return (
    <>
      {sections.map((section) => (
        <AuthorizationDetailsSection
          key={section.title}
          authorization={authorization}
          dataDefinition={section}
        />
      ))}
    </>
  )
}
