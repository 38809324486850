import React, { useEffect, useState } from "react"
import { makeApi } from "applications/FacilitySelectForm/api"
import { patch } from "services/api"
import {
  FacilityOption,
  InboxStateClinicalFacility,
  UpdateFacilityInformation,
} from "sharedTypes"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { useErrorContext } from "../../../error-context"
import { useEffectThatWontRunOnMount } from "../../../../../hooks/useEffectThatWontRunOnMount"
import {
  CanopyComboboxField,
  SelectOption,
} from "@parachutehealth/canopy-combobox-field"
import * as styles from "./index.module.scss"
import * as utilities from "./utilities"
import {
  infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  isComboBoxOptionItem,
} from "../helpers"
import { ExternalSystemBranchSelect } from "./ExternalSystemSelect"

const facilityQueryKey = ["csrInboxState", "facility"]
export const setFacilityQueryData = (
  client: QueryClient,
  facilityData?: InboxStateClinicalFacility
) => {
  client.setQueryData(facilityQueryKey, facilityData)
}
export const useFacilityQuery = () => {
  return useQuery<InboxStateClinicalFacility>({
    queryKey: facilityQueryKey,
    queryFn: () => ({}),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })
}

type SubsetOfClinicalFacility = Pick<FacilityOption, "id" | "name">
export type FetchFacilities = (
  searchTerm: string
) => Promise<SubsetOfClinicalFacility[]>

const updateFacilityInformation = ({
  supplierOrganizationId,
  csrInboxStateId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
}): UpdateFacilityInformation => async (
  updatedData: Partial<InboxStateClinicalFacility>
): Promise<void> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`
  const requestBody = {
    facility_id: updatedData.facility?.id || null,
  }
  await patch(`${pathToCsrInboxState}/facility`, requestBody)
}

const optionRenderFunction = ({ option }) => {
  return (
    <div className={styles.supplierIntakeFacilityData}>
      <div>
        <strong>{option.name}</strong>
        <br />
        <span>
          {option.line1}
          {option.line2 ? " " + option.line2 : ""}, {option.city},{" "}
          {option.state}, {option.zip}
        </span>
        {option.preferredSupplier ? (
          <>
            <br />
            <span>Preferred supplier: {option.preferredSupplier}</span>
            <br />
          </>
        ) : (
          ""
        )}
      </div>
      <div> {option.userCount} Users</div>
    </div>
  )
}

export const FacilityData = ({}: {}) => {
  const queryClient = useQueryClient()
  const csrInboxState = useCsrInboxState()
  const { displayErrors } = useErrorContext()
  const { data } = useQuery<InboxStateClinicalFacility>({
    queryKey: ["csrInboxState", "facility"],
    queryFn: () => ({}),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })

  useEffect(() => {
    setSelectedValue(
      data?.facility
        ? {
            label: data?.facility?.name,
            value: data?.facility?.id,
          }
        : ""
    )
    setRecommendedFacilities([])
  }, [data])

  const [recommendedFacilities, setRecommendedFacilities] = useState(
    [] as FacilityOption[]
  )

  const [displayFeedbackMessage, setDisplayFeedbackMessage] = useState(
    displayErrors && !data?.facility?.id
  )
  useEffectThatWontRunOnMount(() => {
    setDisplayFeedbackMessage(!data?.facility?.id)
  }, [data, displayErrors])

  const onChange = updateFacilityInformation({
    supplierOrganizationId: csrInboxState.supplierOrganization.id,
    csrInboxStateId: csrInboxState.id,
  })

  const mutateFacility = useMutation({
    mutationFn: (facility: InboxStateClinicalFacility["facility"]) => {
      return onChange({ facility: facility }).then(() => {
        queryClient.setQueryData<InboxStateClinicalFacility>(
          facilityQueryKey,
          (old) => {
            return {
              ...old,
              facility: facility || undefined,
            }
          }
        )
      })
    },
  })

  const [searchTerm, setSearchTerm] = React.useState<string>()
  const { data: options } = useQuery({
    queryKey: ["facilities", csrInboxState.id, searchTerm],
    queryFn: async () => {
      if (searchTerm) {
        return fetchFacilities(searchTerm).then((results) => {
          return utilities.groupedFacilities(results, recommendedFacilities)
        })
      }
      return []
    },
  })

  const [selectedValue, setSelectedValue] = React.useState<SelectOption>()

  const fetchFacilities = makeApi("Supplier", csrInboxState.supplier.id)
    .fetchFacilities

  const facility = data || {}
  return (
    <>
      <CanopyComboboxField
        placeholder="Type to search"
        label="Facility"
        feedbackMessage={
          displayFeedbackMessage
            ? "A facility is required to create an order"
            : undefined
        }
        options={options || []}
        required={true}
        multiple={false}
        value={selectedValue}
        onInputChange={setSearchTerm}
        optionRenderFunction={optionRenderFunction}
        onChange={(newValue) => {
          if (!newValue) {
            mutateFacility.mutateAsync(undefined)
            setSelectedValue("")
          } else if (isComboBoxOptionItem(newValue)) {
            mutateFacility.mutateAsync({
              id: newValue.value,
              name: newValue.label || "",
            })
            setSelectedValue(newValue)
          } else {
            console.warn(
              "new value on canopycomboboxfield was not an option item? That shouldn't happen",
              newValue
            )
          }
        }}
        noOptionsFoundMessage="No facilities found for that search"
        description={`Extracted text: ${facility.extractedName || ""}`}
      ></CanopyComboboxField>
      {csrInboxState.supplier.brightreeAcceptance && (
        <ExternalSystemBranchSelect />
      )}
    </>
  )
}
