import React from "react"
import {
  ChartNote,
  DmeOrderPermissions,
  DmeOrderWarning,
  DocumentationRequirement,
  DocumentationRequirementEntryMechanism,
  DocumentationRequirementSatisfyMethod,
  DocumentationRequirementSatisfyStatus,
  PackageConfigurationWarningMetadata,
} from "sharedTypes"
import GenerateButton from "./GenerateButton"
import DocumentationRequirementRows from "./DocumentationRequirementRows"
import Alert from "components/Alert"
import { productsPackageConfigurationPath } from "applications/Workflow/routes"
import { Link } from "react-router-dom"
import AttachManualDocumentationModalButton from "./AttachManualDocumentationModalButton"
import cx from "classnames"
import { getNextFillablePdfOrChartNotePagePathWhenOnClinicalStep } from "../../navigation"

interface Props {
  chartNotes: ChartNote[]
  className: string
  dmeOrderId: string
  documentationRequirements: DocumentationRequirement[]
  hasSigningClinician: boolean
  permissions: DmeOrderPermissions
  supplierFaxNumber: string
  updateSatisfyMethod(
    documentationRequirement: DocumentationRequirement,
    satisfyMethod: DocumentationRequirementSatisfyMethod
  ): Promise<void>
  uploadDocuments(
    documentationRequirementIds: string[],
    files: File[],
    signed: boolean,
    otherAttachmentTitle: string
  ): Promise<void>
  updateDocumentationRequirementsSatisfyStatus(values: {
    manualSatisfyStatus: DocumentationRequirementSatisfyStatus
    documentationRequirementIds: string[]
  })
  warnings: DmeOrderWarning[]
  bounceToSection: {
    shouldBounce: boolean
    triggerBounce: () => void
  }
  setShowIcd10Error: (show: boolean) => void
}

const DocumentationRequirements = ({
  chartNotes,
  className,
  dmeOrderId,
  documentationRequirements,
  hasSigningClinician,
  permissions,
  supplierFaxNumber,
  updateSatisfyMethod,
  uploadDocuments,
  warnings,
  updateDocumentationRequirementsSatisfyStatus,
  bounceToSection,
  setShowIcd10Error,
}: Props) => {
  if (!documentationRequirements.length) {
    return <></>
  }
  const packageConfigurationWarning = warnings.find(
    (warning) =>
      warning.key === "rx_details" || warning.key === "product_requirements"
  )

  const renderActionButton = () => {
    const showAttach = documentationRequirements.some(
      (dr) =>
        dr.satisfyMethod === DocumentationRequirementSatisfyMethod.Manual &&
        dr.satisfyStatus === DocumentationRequirementSatisfyStatus.Incomplete
    )
    const pathToFirstIncompleteDocumentation = getNextFillablePdfOrChartNotePagePathWhenOnClinicalStep(
      {
        chartNotes,
        documentationRequirements,
        updateChartNotesPermission: true,
        currentPath: "/clinical",
      }
    )
    const showEnter =
      (documentationRequirements.some(
        (dr) =>
          dr.chartNotesRequired &&
          dr.satisfyMethod === DocumentationRequirementSatisfyMethod.Generate &&
          dr.satisfyStatus !== DocumentationRequirementSatisfyStatus.Complete
      ) &&
        pathToFirstIncompleteDocumentation) ||
      (pathToFirstIncompleteDocumentation &&
        documentationRequirements.some(
          (dr) =>
            dr.satisfyMethod ===
              DocumentationRequirementSatisfyMethod.Generate &&
            dr.satisfyStatus !==
              DocumentationRequirementSatisfyStatus.Complete &&
            dr.entryMechanism ===
              DocumentationRequirementEntryMechanism.FillablePdf
        ))

    return (
      <div className="canopy-mbs-12x">
        {(showEnter || showAttach) && (
          <div className="canopy-typography-heading-medium canopy-mb-8x">
            Still need to satisfy requirements for this order?
          </div>
        )}
        {showEnter && (
          <div className="canopy-mbe-4x">
            <GenerateButton
              chartNotes={chartNotes}
              pathToFirstIncompleteDocumentation={
                pathToFirstIncompleteDocumentation
              }
              bounceToSection={bounceToSection}
            />
          </div>
        )}
        {showAttach && (
          <AttachManualDocumentationModalButton
            btnClass={showEnter ? "btn-brand-o" : "btn-brand"}
            documentationRequirements={documentationRequirements}
            uploadDocuments={uploadDocuments}
            dmeOrderId={dmeOrderId}
            supplierFaxNumber={supplierFaxNumber}
            hasSigningClinician={hasSigningClinician}
            updateDocumentationRequirementsSatisfyStatus={
              updateDocumentationRequirementsSatisfyStatus
            }
            manuallySatisfyGeneratedDocs={
              permissions.manuallySatisfyGeneratedDocs
            }
          />
        )}
      </div>
    )
  }

  const requirementRows = () => {
    return (
      <>
        <div className="canopy-pbs-4x">
          <p className="color-dark-gray">
            The following documents are required for this order:
          </p>
        </div>
        <DocumentationRequirementRows
          dmeOrderId={dmeOrderId}
          chartNotes={chartNotes}
          documentationRequirements={documentationRequirements}
          permissions={permissions}
          missingChartNotesPrerequisites={!!packageConfigurationWarning}
          updateSatisfyMethod={updateSatisfyMethod}
          bounceToSection={bounceToSection}
          setShowIcd10Error={setShowIcd10Error}
          detailed
        />
        {!packageConfigurationWarning && renderActionButton()}
      </>
    )
  }

  return (
    <div className={cx(className, "well")}>
      <div>
        <div className="row canopy-px-8x">
          <span className="canopy-typography-heading-large">
            Documentation requirements
          </span>
        </div>
        {packageConfigurationWarning && (
          <Alert status="danger" leftIcon>
            The documentation requirements are disabled because of missing
            details for your product(s).
            <Link
              className="canopy-pis-2x"
              to={productsPackageConfigurationPath(
                null,
                (packageConfigurationWarning.metadata as PackageConfigurationWarningMetadata)
                  .packageConfigurationId,
                true
              )}
            >
              Click here to add product details
            </Link>
          </Alert>
        )}
        {requirementRows()}
      </div>
    </div>
  )
}

export default DocumentationRequirements
