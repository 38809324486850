import "custom-event-polyfill"
import ReactSignaturePad from "react-signature-pad-wrapper"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"
import React, { useRef, useEffect, useCallback } from "react"
import * as styles from "./signature-pad.module.scss"
import { canopySpace4X } from "@parachutehealth/canopy-tokens-space"

type Props = {
  height: number
  onChange?(base64DataUrl?: string): void
  fieldError?: string
}

const SignaturePad = ({ height, onChange, fieldError }: Props): JSX.Element => {
  const ref = useRef<ReactSignaturePad>(null)

  const getSignaturePad = useCallback(() => {
    return ref.current ? ref.current.instance : undefined
  }, [ref])

  const onSignatureChange = useCallback(() => {
    const signaturePad = getSignaturePad()
    const signaturePadEmpty = !signaturePad || signaturePad.isEmpty()
    if (onChange) {
      onChange(signaturePadEmpty ? undefined : signaturePad.toDataURL())
    }
  }, [getSignaturePad, onChange])

  const clearSignaturePad = () => {
    const signaturePad = getSignaturePad()
    if (signaturePad) {
      signaturePad.clear()
      onSignatureChange()
    }
  }

  useEffect(() => {
    const signaturePad = getSignaturePad()
    if (signaturePad) {
      signaturePad.addEventListener("endStroke", onSignatureChange)
    }
    return () => {
      if (signaturePad) {
        signaturePad.removeEventListener("endStroke", onSignatureChange)
      }
    }
  }, [getSignaturePad, onSignatureChange])

  const classes = [styles.signaturePad]
  if (fieldError) {
    classes.push(styles.signaturePadError)
  }

  return (
    <>
      <CanopyFormFieldGroup
        label="Signature"
        description="Use your mouse or finger to sign below"
        feedbackMessage={fieldError}
        required
      >
        {() => {
          return (
            <div style={{ display: "block", width: "100%" }}>
              <div
                className={classes.join(" ")}
                style={{ height, marginBlockEnd: canopySpace4X }}
                data-testid="signature-pad-wrapper"
              >
                <ReactSignaturePad
                  canvasProps={{ style: { height: "100%" } }}
                  redrawOnResize
                  ref={ref}
                />
              </div>
              <CanopyButton
                variant="secondary"
                size="small"
                onClick={clearSignaturePad}
              >
                Clear signature
              </CanopyButton>
            </div>
          )
        }}
      </CanopyFormFieldGroup>
    </>
  )
}

export default SignaturePad

SignaturePad.defaultProps = {
  height: 300,
}
