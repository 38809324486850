import { put } from "services/api"
import { employerPrefix } from "utilities/url"

export const updateCsrInboxStateCategories = async (
  csrInboxStateId: string,
  supplierOrganizationId: string,
  categoryIds: string[]
): Promise<void> => {
  const updateCategoriesUrl = `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/categories`
  await put(updateCategoriesUrl, {
    categoryIds,
  })
}
