import { Address } from "../../../../../../sharedTypes"
import { format } from "../../../../../../utilities/date"
import { formatAddress } from "../../../../../../utilities/address"
import { formatHcpcsModifiersString } from "../../../utilities/hcpcsModifiersFormatters"

interface LineItemObject {
  title: string
  required: boolean
  formatFn?: (value: any) => string
}

export const LINE_ITEM_DETAILS: { [key: string]: LineItemObject } = {
  hcpcs: {
    title: "HCPCS",
    required: true,
  },
  hcpcsDetails: {
    title: "HCPCS modifier(s)",
    required: false,
    formatFn: (value) => {
      return !!value ? formatHcpcsModifiersString(value) : "-"
    },
  },
  quantity: {
    title: "Quantity",
    required: true,
  },
  unitsOfMeasure: {
    title: "Unit of measure",
    required: true,
  },
  startDate: {
    title: "Start date",
    required: true,
    formatFn: (value) => format(value),
  },
  endDate: {
    title: "End date",
    required: true,
    formatFn: (value) => format(value),
  },
  priority: {
    title: "Priority",
    required: true,
    formatFn: (value) => value.charAt(0).toUpperCase() + value.slice(1),
  },
  deliveryAddress: {
    title: "Delivery address",
    required: true,
    formatFn: (value: Address) => formatAddress(value),
  },
  supplierLegalEntity: {
    title: "Supplier",
    required: true,
    formatFn: (value) => value.name,
  },
}
