// @ts-strict-ignore
import SideBarAwareContainer from "../SideBarAwareContainer"
import React, { useMemo, useState } from "react"
import { Nullable } from "sharedTypes"
import { CanopySelectField } from "@parachutehealth/canopy-select-field"
import { SelectOptions } from "@parachutehealth/canopy-combobox-field"
import { NestedOptions } from "./sharedTypes"

export type Props = {
  header: string
  options: NestedOptions[]
  selectedValue: Nullable<number>
  select(n: number, isSubcategory?: boolean): void
  selectedSubcategory?: number
}

const CategorySelectorMobile: React.FunctionComponent<Props> = ({
  options,
  selectedValue,
  select,
  header,
  selectedSubcategory,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    selectedValue ? String(selectedValue) : null
  )

  const subOptions = useMemo(() => {
    if (!selectedOption) {
      return null
    }

    const chosenOption = options.find(
      (option) => String(option.value) === selectedOption
    )

    if (!chosenOption) {
      return null
    }

    const { suboptions, label } = chosenOption

    const defaultSubOption = {
      value: null,
      label: `All ${label}`,
      disabled: false,
    }

    return ([defaultSubOption] as NestedOptions[]).concat(suboptions)
  }, [options, selectedOption])

  return (
    <div className="row gutter-0">
      <div className="col-12">
        <SideBarAwareContainer role="selectOneMobile">
          <CanopySelectField
            className="category-select"
            label={header}
            options={options as SelectOptions}
            onChange={(selectedOption) => {
              // The canopy select field returns the label as the value if the value is null
              // so we need to check if the value is a number before setting it
              const value = Number(selectedOption.target.value)
                ? selectedOption.target.value
                : null
              setSelectedOption(value)
              select(value)
            }}
            value={String(selectedValue)}
          />
          {subOptions && (
            <CanopySelectField
              className="canopy-mt-6x"
              label="Filter by Subcategory"
              options={subOptions as SelectOptions}
              onChange={(selectedOption) => {
                const value = Number(selectedOption.target.value)
                  ? selectedOption.target.value
                  : null
                select(value, true)
              }}
              value={String(selectedSubcategory)}
            />
          )}
        </SideBarAwareContainer>
      </div>
    </div>
  )
}

export default CategorySelectorMobile
