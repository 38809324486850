// @team @destroythefax
import React, { useCallback, useEffect, useRef } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./OrderActionsDropdown.module.scss"
import CancelButton from "applications/Workflow/containers/Navigation/components/CancelButton"
import { DmeOrder, Originator } from "sharedTypes"
import SnoozeButton from "applications/Workflow/containers/Navigation/components/SnoozeButton"
import UnsnoozeButton from "applications/Workflow/containers/Navigation/components/UnsnoozeButton"
import FollowButton from "../FollowButton"
import UnfollowButton from "../UnfollowButton"

interface Props {
  originator: Originator
  dmeOrder: DmeOrder
  snoozeDmeOrder(params): Promise<void>
  unsnoozeDmeOrder(): Promise<void>
  followDmeOrder(): Promise<void>
  unfollowDmeOrder(followingId: string): Promise<void>
}

const OrderActionsDropdown: React.FC<Props> = ({
  originator,
  followDmeOrder,
  unfollowDmeOrder,
  snoozeDmeOrder,
  unsnoozeDmeOrder,
  dmeOrder,
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const icon = open ? "caret-up" : "caret-down"

  const dropdownRef = useRef<HTMLDivElement>(null)

  // This enables "click away" behavior to close the dropdown when the user
  // clicks outside of the actions dropdown menu
  const handleClickOutside = useCallback(
    (event): void => {
      if (
        open &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setOpen(false)
      }
    },
    [open]
  )

  // This callback is passed down to child components that open other modals
  // to prevent behavior where the modal actions are prevented from executing otherwise and
  // minimize duplicate state setting calls and strange side effects
  const removeClickOutsideDropdownMenuListener = useCallback(() => {
    return document.removeEventListener("click", handleClickOutside)
  }, [handleClickOutside])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      removeClickOutsideDropdownMenuListener()
    }
  }, [handleClickOutside, removeClickOutsideDropdownMenuListener])

  return (
    <div className={styles.orderActionsDropdown} ref={dropdownRef}>
      <CanopyButton
        fullWidth
        variant="secondary"
        onClick={handleClick}
        iconEnd={icon}
      >
        Order&nbsp;actions
      </CanopyButton>
      {open && (
        <div className={styles.dropdownContainer}>
          {dmeOrder.currentEmploymentFollowingId ? (
            <UnfollowButton
              unfollowDmeOrder={() =>
                unfollowDmeOrder(dmeOrder.currentEmploymentFollowingId!)
              }
              setOrderActionsDropdownOpen={setOpen}
            />
          ) : (
            <FollowButton
              followDmeOrder={followDmeOrder}
              setOrderActionsDropdownOpen={setOpen}
            />
          )}
          {dmeOrder.snoozed ? (
            <UnsnoozeButton
              unsnoozeDmeOrder={unsnoozeDmeOrder}
              setOrderActionsDropdownOpen={setOpen}
              removeClickOutsideDropdownMenuListener={
                removeClickOutsideDropdownMenuListener
              }
            />
          ) : (
            <SnoozeButton
              snoozeDmeOrder={snoozeDmeOrder}
              dmeOrder={dmeOrder}
              setOrderActionsDropdownOpen={setOpen}
              removeClickOutsideDropdownMenuListener={
                removeClickOutsideDropdownMenuListener
              }
            />
          )}
          <CancelButton
            originator={originator}
            orderActionMenu
            setOrderActionsDropdownOpen={setOpen}
            removeClickOutsideDropdownMenuListener={
              removeClickOutsideDropdownMenuListener
            }
          />
        </div>
      )}
    </div>
  )
}

export default OrderActionsDropdown
