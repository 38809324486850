import React, { useState } from "react"
import { GetCarrierAuthorizationById } from "./graphql/carrierAuthorizationByIdQuery"
import { useMutation, useQuery } from "@apollo/client"
import { CanopyButton } from "@parachutehealth/canopy-button"

import { CarrierAuthorizationSubmit } from "./graphql/carrierAuthorizationSubmit"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { format } from "../../../utilities/date"
import { sendError } from "../../../utilities/error"
import { withApollo } from "hocs/withApollo"
import { AuthorizationRequestLineItemCard } from "./components/AuthorizationLineItemCard/AuthorizationRequestLineItemCard"
import {
  CarrierAuthorization,
  CarrierAuthStatusesEnum,
  CarrierLineItemAuthorization,
  CarrierLineItemAuthorizationUpdateMutationVariables,
  Doctor,
} from "../../../graphql/__generated__/graphql"
import { EmployerType } from "../../../sharedTypes"
import * as styles from "./index.module.scss"
import { AuthorizationDetails } from "./components/AuthorizationDetails/AuthorizationDetails"
import MuiAccordion from "@material-ui/core/Accordion"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { AccordionDetails, AccordionSummary } from "@material-ui/core"
import { EditDrawer } from "./components/EditDrawer/EditDrawer"
import { LineItemEditForm } from "./components/LineItemEditForm/LineItemEditForm"
import { CarrierLineItemAuthorizationDelete } from "./graphql/carrierLineItemAuthorizationDelete"
import { scrollTop } from "../../../utilities/scroll"
import { CarrierLineItemAuthorizationUpdate } from "./graphql/carrierLineItemAuthorizationUpdate"
import { utcTime } from "./utilities/utcTime"
import Link from "components/Link"
import { dmeOrderUrl } from "applications/CarrierAuthorization/urls"
import classNames from "classnames"

type PageNotice = {
  status: "success" | "information" | "error"
  message: string
}

interface CarrierAuthorizationRequestProps {
  carrierAuthorizationId: string
}

const CarrierAuthorizationRequest = ({
  carrierAuthorizationId,
}: CarrierAuthorizationRequestProps) => {
  const [pageAlert, setPageAlert] = useState<PageNotice | null>(null)
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  const [editItem, setEditItem] = useState<CarrierLineItemAuthorization | null>(
    null
  )
  const [editDrawerHeader, setEditDrawerHeader] = useState<string>("")

  const currentEmployer = window?.parachute?.employerType?.toLowerCase()

  const onMutationComplete = (pageAlert: PageNotice) => {
    setPageAlert(pageAlert)
    setEditItem(null)
    setOpenEditDrawer(false)
  }

  const { data } = useQuery(GetCarrierAuthorizationById, {
    variables: { id: carrierAuthorizationId },
    refetchWritePolicy: "merge",
    onError: () => {
      sendError(
        `Error fetching carrier authorization ${carrierAuthorizationId}`
      )
    },
    onCompleted: (result) => {
      if (
        result?.carrierAuthorization?.authStatus ===
        CarrierAuthStatusesEnum.PayorInReview
      ) {
        const prettyPrintSubmitAt = format(
          utcTime(result.carrierAuthorization?.requestSubmitDateTime),
          "MM/DD/YYYY HH:mm UTC"
        )
        setPageAlert({
          status: "information",
          message: `Authorization request submitted ${prettyPrintSubmitAt}`,
        })
      }
    },
  })

  const [updateLineItem] = useMutation(CarrierLineItemAuthorizationUpdate, {
    refetchQueries: [GetCarrierAuthorizationById],
    onCompleted: (data) => {
      const { hcpcs } = data?.carrierLineItemAuthorizationUpdate
        ?.carrierLineItemAuthorization as CarrierLineItemAuthorization
      onMutationComplete({
        status: "success",
        message: `Prior auth line item: ${hcpcs} has been updated`,
      })
    },
    onError: () => {
      onMutationComplete({
        status: "error",
        message: `We had trouble updating prior auth line item ${editItem?.hcpcs}. Please try again.`,
      })
    },
  })

  const [deleteLineItem] = useMutation(CarrierLineItemAuthorizationDelete, {
    refetchQueries: [GetCarrierAuthorizationById],
    onCompleted: () => {
      setOpenEditDrawer(false)
      onMutationComplete({
        status: "information",
        message: `Prior auth line item: ${editItem?.hcpcs} has been deleted`,
      })
    },
    onError: (_error) => {
      onMutationComplete({
        status: "error",
        message: `We had trouble completing your request. Please try again.`,
      })
    },
  })

  const [
    carrierAuthorizationSubmit,
    { loading: carrierAuthorizationSubmitLoading },
  ] = useMutation(CarrierAuthorizationSubmit, {
    refetchQueries: [GetCarrierAuthorizationById],
    onCompleted: (result) => {
      const updatedAuth =
        result.carrierAuthorizationSubmit?.carrierAuthorization
      if (updatedAuth) {
        const prettyPrintSubmitAt = format(
          utcTime(updatedAuth.requestSubmitDateTime),
          "MM/DD/YYYY HH:mm UTC"
        )
        setPageAlert({
          status: "information",
          message: `Authorization request submitted ${prettyPrintSubmitAt}`,
        })
        scrollTop()
      }
    },
    onError: (error) => {
      if (error.message === "missing_required_info_error") {
        setPageAlert({ status: "error", message: "Missing required info" })
      } else {
        setPageAlert({
          status: "error",
          message:
            "Your request is unable to be submitted at this time. Please try again later.",
        })
      }
    },
  })

  const submitAuthorization = () => {
    carrierAuthorizationSubmit({
      variables: {
        carrierAuthorizationId: carrierAuthorizationId,
      },
    })
  }

  const carrierAuthorization = data?.carrierAuthorization as CarrierAuthorization
  const showSupplierInteractions =
    carrierAuthorization?.authStatus ===
      CarrierAuthStatusesEnum.SupplierInProgress &&
    currentEmployer === EmployerType.Supplier

  const carrierAuthCreatedAtDate = new Date(carrierAuthorization?.createdAt)
  const dmeOrderPath = dmeOrderUrl(carrierAuthorization?.dmeOrder?.id)

  return (
    <>
      <h1 className="canopy-typography-heading-2xlarge canopy-mb-0 canopy-my-12x">
        Auth request {carrierAuthorizationId}
      </h1>
      {!!showSupplierInteractions && (
        <>
          <h3 className="canopy-typography-heading-xlarge canopy-mb-0">
            Review & submit authorization request
          </h3>
          <p className="canopy-typography-body-medium canopy-mt-2x canopy-mb-8x">
            Review and complete the missing required information. Changes made
            on this page will NOT be reflected on the order.
          </p>
        </>
      )}

      <div className={styles.authCardsContainer}>
        <div className={styles.authorizationDetailsContainer}>
          {pageAlert && (
            <CanopyNotice
              title={pageAlert.message}
              variant={pageAlert.status}
            />
          )}
          {carrierAuthorization?.carrierLineItemAuthorizations &&
            carrierAuthorization.carrierLineItemAuthorizations.map(
              (lineItem) => (
                <AuthorizationRequestLineItemCard
                  key={lineItem.id}
                  orderingPhysician={
                    carrierAuthorization.orderingDoctor as Doctor
                  }
                  lineItem={lineItem}
                  showSupplierInteractions={showSupplierInteractions}
                  onEditClick={() => {
                    setOpenEditDrawer(true)
                    setEditItem(lineItem)
                    setEditDrawerHeader(
                      `Prior auth line item: ${lineItem?.hcpcs}`
                    )
                  }}
                />
              )
            )}
          {showSupplierInteractions && (
            <CanopyButton
              variant="primary"
              size="medium"
              className="canopy-mt-8x"
              loading={carrierAuthorizationSubmitLoading}
              onClick={submitAuthorization}
            >
              Submit authorization request
            </CanopyButton>
          )}
          {!showSupplierInteractions &&
            carrierAuthorization?.requestSubmitDateTime && (
              <MuiAccordion className={styles.accordionRoot}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <span className="canopy-typography-heading-large">
                    Auth request details
                  </span>
                </AccordionSummary>
                <AccordionDetails className="d-block">
                  <AuthorizationDetails authorization={carrierAuthorization} />
                </AccordionDetails>
              </MuiAccordion>
            )}
        </div>
        {carrierAuthorization && (
          <div
            className={classNames(
              styles.orderLinkCard,
              !pageAlert && "canopy-mt-8x"
            )}
          >
            <p>
              This auth request was generated from{" "}
              <Link href={dmeOrderPath} target="_blank" rel="noreferrer">
                this order
              </Link>{" "}
              on {carrierAuthCreatedAtDate.toLocaleDateString()} at{" "}
              {format(utcTime(carrierAuthCreatedAtDate), "HH:mm UTC")}. Any
              changes made to the order after that time will not be reflected on
              the auth request.
            </p>
            <CanopyButton
              as="a"
              fullWidth
              href={dmeOrderPath}
              iconEnd="arrow-up-right-from-square"
              rel="noreferrer"
              target="_blank"
              variant="secondary"
            >
              View order
            </CanopyButton>
          </div>
        )}
      </div>
      <EditDrawer
        open={openEditDrawer}
        anchor="right"
        heading={editDrawerHeader}
        onClose={() => setOpenEditDrawer(false)}
      >
        {editItem && (
          <LineItemEditForm
            lineItem={editItem}
            onSubmit={async (
              updatedFields: CarrierLineItemAuthorizationUpdateMutationVariables
            ) => {
              await updateLineItem({ variables: { ...updatedFields } })
            }}
            onDelete={async (lineItemId: string) => {
              await deleteLineItem({ variables: { id: lineItemId } })
            }}
            onCancel={() => {
              setOpenEditDrawer(false)
              setEditItem(null)
            }}
          />
        )}
      </EditDrawer>
    </>
  )
}

export default withApollo(CarrierAuthorizationRequest)
