import ExternalQuestionnaireStatusPill from "applications/Workflow/containers/Review/components/ExternalQuestionnaireStatusPill"
import * as styles from "./index.module.scss"
import React from "react"
import { QuestionnaireResponse } from "sharedTypes"
import Link from "components/Link"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import ReadOnlyQuestionAnswerList from "applications/Workflow/containers/Review/components/ReadOnlyQuestionAnswerList"

type Props = {
  questionnaireResponses: QuestionnaireResponse[]
}

const MedicalNecessityReview = (props: Props) => {
  const [open, setOpen] = React.useState(false)
  const activatorRef = React.useRef<HTMLButtonElement>(null)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const { questionnaireResponses } = props
  return (
    <div className="canopy-mt-12x">
      <div className="canopy-typography-heading-medium">
        Health Plan Medical Necessity Review
      </div>
      <ul>
        {questionnaireResponses.map(
          ({ title, id, questionsWithSelectedAnswers, status }) => (
            <li className={styles.questionnaireContainer} key={id}>
              <CanopyDialogModal
                activatorElementRef={activatorRef}
                header={title}
                headerOverline="Read Only"
                onClose={closeModal}
                open={open}
                size="large"
                primaryFooterButton={
                  <CanopyButton onClick={closeModal}>Close</CanopyButton>
                }
              >
                {questionsWithSelectedAnswers && (
                  <ReadOnlyQuestionAnswerList
                    questions={questionsWithSelectedAnswers}
                  />
                )}
              </CanopyDialogModal>

              <span className="flex-1">
                <Link onClick={() => openModal()}>{title}</Link>
              </span>
              <span>
                <ExternalQuestionnaireStatusPill satisfyStatus={status} />
              </span>
            </li>
          )
        )}
      </ul>
    </div>
  )
}

export default MedicalNecessityReview
