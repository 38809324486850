import React from "react"
import Logo from "../../components/Logo"

const DoctorPendingOrderWorkflowHeader = () => {
  return (
    <>
      <div className="navbar-fixed-top fixed-top">
        <nav className="navbar navbar-light bg-light">
          <div>
            <div className="bold">Order for Irene Parachute</div>
            <div className="color-dark-gray font-m">MRN: 123456789</div>
          </div>
          <Logo width="36" className="canopy-mb-4x"></Logo>
        </nav>
      </div>
    </>
  )
}

export default DoctorPendingOrderWorkflowHeader
