// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__bottom-card-row--cndBf";
var _2 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__card--w3cH4";
var _3 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__chip--_zbEm";
var _4 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__chip-label-override--rgfuQ";
var _5 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__comment-badge--I591K";
var _6 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__current-employment-mention--P1Fwq";
var _7 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__detail-content--cTCIp";
var _8 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__detail-header--FR0Jb";
var _9 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__error-chip--kaviS";
var _a = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__follower-chip--PETBi";
var _b = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__follower-chip-label--NE5kj";
var _c = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__follower-chips-container--kBi3h";
var _d = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__follower-self-chip--O_nM5";
var _e = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__follower-self-chip-label--eRira";
var _f = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__followers-container--RCKZS";
var _10 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__last-comment-body--FMKes";
var _11 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__last-comment-by-current-employer--j1CI0";
var _12 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__last-comment-container--e_fhe";
var _13 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__last-comment-date--TOmNc";
var _14 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__last-comment-header--RNKjN";
var _15 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__link-container--Fn2hI";
var _16 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__mention--rDZJL";
var _17 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__patient-info--YU69y";
var _18 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__patient-name--kbsnx";
var _19 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__signature-and-document-status--LlXIp";
var _1a = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__signature-unknown-chip--YDSdl";
var _1b = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__snooze-chip--g7bbe";
var _1c = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__solid-warning-chip--PX_oY";
var _1d = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__stack-chips--IbBVZ";
var _1e = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__success-chip--upVK_";
var _1f = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__truncate--W7cFe";
var _20 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__unread-comment--Zpzs3";
var _21 = "app-javascript-applications-ClinicalFacilityDashboard-components-DashboardColumn-ActionableOrderCard-ActionableOrderCard-module__warning-chip--lvsPy";
export { _1 as "bottomCardRow", _2 as "card", _3 as "chip", _4 as "chipLabelOverride", _5 as "commentBadge", _6 as "currentEmploymentMention", _7 as "detailContent", _8 as "detailHeader", _9 as "errorChip", _a as "followerChip", _b as "followerChipLabel", _c as "followerChipsContainer", _d as "followerSelfChip", _e as "followerSelfChipLabel", _f as "followersContainer", _10 as "lastCommentBody", _11 as "lastCommentByCurrentEmployer", _12 as "lastCommentContainer", _13 as "lastCommentDate", _14 as "lastCommentHeader", _15 as "linkContainer", _16 as "mention", _17 as "patientInfo", _18 as "patientName", _19 as "signatureAndDocumentStatus", _1a as "signatureUnknownChip", _1b as "snoozeChip", _1c as "solidWarningChip", _1d as "stackChips", _1e as "successChip", _1f as "truncate", _20 as "unreadComment", _21 as "warningChip" }
