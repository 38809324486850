import React from "react"
import { Field } from "formik"
import Textarea from "components/TextArea"
import { SurveyAnswerType, SurveyQuestion } from "sharedTypes"
import { isTabOrEnter } from "utilities/browser/event"
import { SaveButton } from "../components/SaveButton"
import QuestionTitle from "../components/QuestionTitle"
import { InitialValues, Values } from "../sharedTypes"

type Props = {
  inputId: string
  isSubmitting: boolean
  question: SurveyQuestion
  dirty: boolean
  valid: boolean
  missingRequiredInformation: boolean
}

const validate = () => ({})

const getInitialValues: (question: SurveyQuestion) => InitialValues = (
  question
) => ({
  answerValue: question.answerValue || "",
})

const formatAnswer = (values: Values) => ({
  answerType: SurveyAnswerType.Value,
  answerValue: values.answerValue,
})

const QuestionOptional = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      inputId,
      isSubmitting,
      question,
      dirty,
      valid,
      missingRequiredInformation,
    },
    ref
  ) => {
    const renderSaveButton: boolean = dirty || !question.answerValue
    return (
      <>
        <Field name="answerValue">
          {({ form, field }) => (
            <>
              <QuestionTitle
                questionTitle={question.title}
                questionText={question.text}
                tooltips={question.tooltips}
                questionId={inputId}
              />
              <Textarea
                ref={ref}
                {...field}
                className="form-control textarea"
                id={inputId}
                disabled={isSubmitting}
                onKeyDown={(event) =>
                  isTabOrEnter(event) && form.handleSubmit()
                }
              />
            </>
          )}
        </Field>
        {renderSaveButton && (
          <SaveButton
            isSubmitting={isSubmitting}
            valid={valid}
            missingRequiredInformation={missingRequiredInformation}
            question={question}
          />
        )}
      </>
    )
  }
)

export {
  QuestionOptional as QuestionComponent,
  validate,
  getInitialValues,
  formatAnswer,
}
