import { put } from "../../../../../services/api"
import { updatePatientAddressUrl } from "../../../urls"
import { ResourceUpdateResponse } from "../api"

export type PatientAddress = {
  line1?: string
  line2?: string
  city?: string
  state?: string
  zip?: string
  phoneNumber?: string
}

export const updatePatientAddress = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  patientAddress: Partial<PatientAddress>
): Promise<ResourceUpdateResponse<keyof PatientAddress>> => {
  return put(updatePatientAddressUrl(supplierOrganizationId, csrInboxStateId), {
    patientAddress,
  })
    .then(() => ({ success: true }))
    .catch((e) => ({ errors: e.response?.data?.errors || {} }))
}
