import React from "react"
import ToggleFilter from "components/ToggleFilter"
import SelectFilter, { Record } from "components/SelectFilter"
import { isAnyFilterPresent } from "utilities/dashboard"
import { formatPhone } from "utilities/phone"
import { InternalStatus } from "sharedTypes"
import Search from "applications/SupplierOrganizationDashboard/components/DashboardFilters/Search"
import { SupplierIntakeEmail } from "applications/SupplierOrganizationDocumentDashboard/components/sharedTypes"
import { Category } from "./sharedTypes"

interface Assignee {
  name: string
  id: string
}

interface ClinicalFacility {
  name: string
  id: string
}

interface DocumentSource {
  source: string
  sourceType: string
}

interface Filters {
  assigned?: boolean
  assignees?: string[]
  liaison?: boolean
  internalStatuses?: string[]
  clinicalFacilities?: string[]
  documentSources?: string[]
  receivingFaxNumbers?: string[]
  supplierIntakeEmailExternalIds?: string[]
  worked?: boolean
  patientName?: string
  categoryExternalIds?: string[]
}

interface Props {
  fromSources?: { source: string }[]
  assignees?: Assignee[]
  filters?: Filters
  onSubmit: (values: {
    [key: string]: string | boolean | undefined
  }) => Promise<void>
  internalStatuses?: InternalStatus[]
  clinicalFacilities?: ClinicalFacility[]
  documentSources?: DocumentSource[]
  receivingFaxNumbers?: string[]
  supplierIntakeEmailsEnabled: boolean
  supplierIntakeEmails: SupplierIntakeEmail[]
  getClinicalFacilities: (term: string) => Promise<ClinicalFacility[]>
  getDocumentSources: (term: string) => Promise<DocumentSource[]>
  getReceivingFaxNumbers: (term: string) => Promise<string[]>
  getAssignees: (term: string) => Promise<{ label: string; value: string }[]>
  onReset: () => void
  aiColumnsEnabled: boolean
  categories: Category[]
}

const formatDocumentSources = (source: {
  source: string
  sourceType: string
}): Record => ({
  label:
    source.sourceType === "email"
      ? source.source
      : formatPhone(source.source) || "",
  value: source.source,
})

const buildFaxNumberOption = (faxNumber: string): Record => {
  if (faxNumber === "unknown") {
    return {
      label: "Unknown",
      value: "unknown",
    }
  }
  const label = formatPhone(faxNumber)

  return {
    label: label ? label : "",
    value: faxNumber,
  }
}

const defaultFilters: Filters = {
  assigned: false,
  assignees: [],
  clinicalFacilities: [],
  documentSources: [],
  receivingFaxNumbers: [],
  internalStatuses: [],
  supplierIntakeEmailExternalIds: [],
  liaison: false,
  categoryExternalIds: [],
}

const FilterForm: React.FC<Props> = (props) => {
  const [patientSearchText, setPatientSearchText] = React.useState<
    string | undefined
  >()
  const {
    assignees = [],
    clinicalFacilities = [],
    documentSources = [],
    receivingFaxNumbers = [],
    filters = defaultFilters,
    getDocumentSources,
    getClinicalFacilities,
    getReceivingFaxNumbers,
    getAssignees,
    internalStatuses = [],
    supplierIntakeEmails,
    onReset,
    onSubmit,
  } = props

  const getSourcesWithFormatting = async (term: string) => {
    const sources = await getDocumentSources(term)
    return sources.map(formatDocumentSources)
  }

  const getReceivingFaxNumberOptions = async (term: string) => {
    let optionValues: string[] = []
    if (!term || term.match(/^\s+$/)) {
      optionValues.push("unknown")
    }
    const faxNumbers = await getReceivingFaxNumbers(term)
    optionValues = optionValues.concat(faxNumbers)

    return optionValues.map(buildFaxNumberOption)
  }

  return (
    <div>
      {props.aiColumnsEnabled && (
        <Search
          onChange={(value) => {
            setPatientSearchText(value)
            onSubmit({ patientName: value })
          }}
          placeholder="Search by patient"
          value={patientSearchText}
        />
      )}
      <ToggleFilter
        name="worked"
        options={[
          {
            label: "New",
            value: false,
          },
          {
            label: "Worked",
            value: true,
          },
        ]}
        initialValue={filters.worked}
        onChange={onSubmit}
      />
      <SelectFilter
        name="assignees"
        label="Assignee"
        options={assignees.map((option) => ({
          label: option.name,
          value: option.id,
        }))}
        initialValues={filters.assignees}
        fetchOptions={getAssignees}
        onChange={onSubmit}
      />
      <SelectFilter
        name="internalStatuses"
        label="Internal Status"
        options={internalStatuses.map(({ status, id }) => ({
          label: status,
          value: id.toString(),
        }))}
        initialValues={filters.internalStatuses}
        onChange={onSubmit}
      />
      {props.supplierIntakeEmailsEnabled && (
        <SelectFilter
          name="supplierIntakeEmailExternalIds"
          label="Inboxes"
          options={supplierIntakeEmails.map(({ name, externalId }) => ({
            label: name,
            value: externalId,
          }))}
          initialValues={filters.supplierIntakeEmailExternalIds}
          onChange={onSubmit}
        />
      )}
      {props.aiColumnsEnabled && (
        <SelectFilter
          name="categoryExternalIds"
          label="Categories"
          options={props.categories.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
          initialValues={filters.categoryExternalIds}
          onChange={onSubmit}
        />
      )}
      <SelectFilter
        name="clinicalFacilities"
        label="Facility"
        initialValues={filters.clinicalFacilities}
        options={clinicalFacilities.map(({ id, name }) => ({
          label: name,
          value: id.toString(),
        }))}
        fetchOptions={getClinicalFacilities}
        onChange={onSubmit}
      />
      <SelectFilter
        name="documentSources"
        label="From"
        initialValues={filters.documentSources}
        options={documentSources.map(formatDocumentSources)}
        fetchOptions={getSourcesWithFormatting}
        onChange={onSubmit}
        singular
      />
      <SelectFilter
        name="receivingFaxNumbers"
        label="To"
        initialValues={filters.receivingFaxNumbers}
        options={receivingFaxNumbers.map(buildFaxNumberOption)}
        fetchOptions={getReceivingFaxNumberOptions}
        onChange={onSubmit}
        minLength={0}
        singular
      />
      <ToggleFilter
        name="assigned"
        options={[
          {
            label: "Unassigned",
            value: false,
          },
          {
            label: "Assigned",
            value: true,
          },
        ]}
        initialValue={filters.assigned}
        onChange={onSubmit}
      />
      <ToggleFilter
        name="liaison"
        options={[
          {
            label: "Liaison",
            value: true,
          },
          {
            label: "Non-liaison",
            value: false,
          },
        ]}
        initialValue={filters.liaison}
        onChange={onSubmit}
      />
      {isAnyFilterPresent(filters) && (
        <a
          onClick={onReset}
          className="canopy-mis-8x link link-sm link-understated nowrap"
        >
          <small>Clear All Filters</small>
        </a>
      )}
    </div>
  )
}

export default FilterForm
