import React, { useState } from "react"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import Switch from "../../../../components/input/Switch"
import { useMutation } from "@apollo/client"
import { SupplierInsuranceContractUpdate } from "../../graphql/supplierInsuranceContractUpdateMutation"

interface Props {
  cellParams: GridRenderCellParams
}

export const AcceptedInsuranceToggle: React.FC<Props> = ({ cellParams }) => {
  const [isAccepted, setIsAccepted] = useState(
    !!cellParams.row.insuranceContract
  )

  const [updateContract] = useMutation(SupplierInsuranceContractUpdate)

  return (
    <Switch
      id={`switch-${cellParams.row.id}`}
      onChange={async (isToggledOn) => {
        setIsAccepted(isToggledOn)
        if (isToggledOn) {
          await updateContract({
            variables: { insuranceProductId: cellParams.row.id },
          })
        } else {
          await updateContract({
            variables: {
              insuranceProductId: cellParams.row.id,
              insuranceContractId: cellParams.row.insuranceContract?.id,
            },
          })
        }
      }}
      checked={isAccepted}
    />
  )
}
