import { dmeOrderUrl } from "applications/Workflow/urls"

export const searchSuppliersUrl = (): string =>
  dmeOrderUrl("/workflow/suppliers/search.json")

export const searchSettingsUrl = () =>
  dmeOrderUrl(`/workflow/suppliers/search_settings.json`)

export const fulfillmentAgreementPromotionsConfirmUrl = (
  externalId: string
): string =>
  dmeOrderUrl(
    `/workflow/fulfillment_agreement_promotions/${externalId}/confirm.json`
  )

export const fulfillmentAgreementPromotionsDismissUrl = (
  externalId: string
): string =>
  dmeOrderUrl(
    `/workflow/fulfillment_agreement_promotions/${externalId}/dismiss.json`
  )
