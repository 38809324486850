import React, { useEffect, useMemo, useState } from "react"
import SupplierSendBackForm from "./SupplierSendBackForm"
import Modal from "components/Modal"
import withInitialData from "components/withInitialData"
import { DocumentationRequirement } from "sharedTypes"
import { FacilityOption, SupplierFaxBackDocument } from "./sharedTypes"
import {
  RejectFormValues,
  SelectedRecipient,
  SendBackFormValues,
  SendBackRecipient,
} from "./sharedTypes"
import * as api from "./api"
import { navigate } from "utilities/navigation"
import { workflowReviewUrl } from "applications/Workflow/urls"
import { makeApi } from "../../../../../FacilitySelectForm/api"
import { EventCategory, trackEvent } from "utilities/tracking"
import { ClinicalFacilityProvider } from "./useClinicalFacilityContext"

export enum DmeOrderCreatedVia {
  supplier_api = "supplier_api",
  upload = "upload",
  referral_api = "referral_api",
}

export type InitialData = {
  allowReject: boolean
  defaultFacilityRecipient: SendBackRecipient
  defaultSupplierRecipient: SendBackRecipient
  defaultSupplierTeamRecipients: SendBackRecipient[]
  defaultFacility: FacilityOption
  documentationRequirements: DocumentationRequirement[]
  facilityEmail: string
  faxNumbers: string[]
  selectedRecipients: SelectedRecipient[]
  createdVia: DmeOrderCreatedVia
  supplierFaxBackDocuments: SupplierFaxBackDocument[]
}

type Props = {
  dismiss(): void
  initialData: InitialData
  doctorId?: string
  supplierId?: string
}

function SupplierSendBack({
  dismiss,
  initialData,
  doctorId,
  supplierId,
}: Props) {
  const {
    allowReject,
    defaultFacilityRecipient,
    defaultSupplierRecipient,
    defaultSupplierTeamRecipients,
    defaultFacility,
    documentationRequirements,
    facilityEmail,
    faxNumbers,
    selectedRecipients,
    createdVia,
  } = initialData

  const [recommendedFacilities, setRecommendedFacilities] = useState(
    [] as FacilityOption[]
  )
  const [clinicalFacilityId, setClinicalFacilityId] = useState("")

  const [
    searchRecipientRecommendations,
    setSearchRecipientRecommendations,
  ] = useState([] as string[])

  const [hasFacilityRecipients, setHasFacilityRecipients] = useState<boolean>(
    defaultFacility.hasRecipients
  )

  const [isNonParachuteFacility, setIsNonParachuteFacility] = useState<boolean>(
    defaultFacility.isNonParachuteFacility
  )
  const [selectedFacilityName, setSelectedFacilityName] = useState<string>(
    defaultFacility.name
  )

  const facilityApi = useMemo(() => {
    return makeApi("Supplier", supplierId)
  }, [supplierId])

  useEffect(() => {
    if (doctorId) {
      facilityApi.fetchRecommendedFacilities(doctorId).then((response) => {
        setRecommendedFacilities(response?.recommendedFacilities?.results)
      })
    }
  }, [doctorId, facilityApi])

  const supplierSendBack = (
    values: SendBackFormValues,
    setErrors,
    setShowOverlay
  ) => {
    return api
      .supplierSendBack(values, searchRecipientRecommendations)
      .then(() => {
        trackEvent(
          EventCategory.SupplierGeneratedOrders,
          "supplier_send_back_completed"
        )
        navigate(workflowReviewUrl())
      })
      .catch(({ response }) => {
        setShowOverlay(false)
        setErrors(response.data.errors)
      })
  }

  const supplierReject = (
    values: RejectFormValues,
    setErrors,
    setShowOverlay
  ) => {
    return api
      .supplierReject(values, searchRecipientRecommendations)
      .then(() => navigate(workflowReviewUrl()))
      .catch(({ response }) => {
        setShowOverlay(false)
        setErrors(response.data.errors)
      })
  }

  const selectFacility = (value: string) => {
    api.getFacilityInfo(value).then((response) => {
      setHasFacilityRecipients(response.hasRecipients)
      setIsNonParachuteFacility(response.isNonParachuteFacility)
      setSelectedFacilityName(response.name)
    })
    setClinicalFacilityId(value)
  }

  return (
    <Modal size="lg" show cancel={dismiss}>
      <ClinicalFacilityProvider clinicalFacilityId={clinicalFacilityId}>
        <SupplierSendBackForm
          allowReject={allowReject}
          cancel={dismiss}
          documentationRequirements={documentationRequirements}
          defaultFacilityRecipient={defaultFacilityRecipient}
          defaultSupplierRecipient={defaultSupplierRecipient}
          defaultSupplierTeamRecipients={defaultSupplierTeamRecipients}
          facilityEmail={facilityEmail}
          faxNumbers={faxNumbers}
          hasFacilityRecipients={hasFacilityRecipients}
          isNonParachuteFacility={isNonParachuteFacility}
          selectedFacilityName={selectedFacilityName}
          searchFacilityRecipients={api.searchFacilityRecipients}
          searchSupplierFollowables={api.searchSupplierFollowables}
          supplierFaxBackDocuments={initialData.supplierFaxBackDocuments}
          supplierReject={supplierReject}
          supplierSendBack={supplierSendBack}
          setSearchRecipientRecommendations={setSearchRecipientRecommendations}
          selectedRecipients={selectedRecipients}
          createdVia={createdVia}
          facilitySelectProps={{
            defaultFacility,
            recommendedFacilities,
            fetchFacilities: facilityApi.fetchFacilities,
            selectFacility,
          }}
        />
      </ClinicalFacilityProvider>
    </Modal>
  )
}

const fetchInitialData = () =>
  api.getSupplierSendBackData().then(({ data }) => data)
export default withInitialData(fetchInitialData)(SupplierSendBack)
