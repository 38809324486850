import React, { useState } from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import InsuranceSelection from "./InsuranceSelection"
import AuthorizationSelect from "./AuthorizationSelect"
import { carrierAuthorizationUrl } from "applications/Workflow/urls"
import { DmeOrder, InsurancePolicy } from "sharedTypes"
import {
  CarrierAuthorizationParams,
  CarrierSearchSuggestion,
  InsuranceVerificationInterfaceStatus,
  InsuranceRank,
  Prediction,
  UpdateParams,
  CarrierAutoUpdateResponse,
  CarrierAuthorizationsResponse,
} from "../sharedTypes"
import { EventCategory, trackEvent } from "utilities/tracking"
import { CanopyButton } from "@parachutehealth/canopy-button"
import cx from "classnames"

type Props = {
  dmeOrder: DmeOrder
  nextPagePath?: string
  updateAuthorizationNumber(params: CarrierAuthorizationParams): Promise<void>
  carrierSearch(carrierName: string): Promise<CarrierSearchSuggestion[]>
  predict(memberNumber: string, rank: InsuranceRank): Promise<Prediction[]>
  update(params: UpdateParams): Promise<void>
  verifyInsurance(rank: InsuranceRank): Promise<boolean>
  getCarrierAuthorizations(
    query: string
  ): Promise<CarrierAuthorizationsResponse>
  removePolicy(policy: InsurancePolicy): Promise<void>
  autoUpdateCarrier(rank: InsuranceRank): Promise<CarrierAutoUpdateResponse>
}

const InternalPayment = (props: Props) => {
  const {
    carrierSearch,
    nextPagePath,
    dmeOrder,
    updateAuthorizationNumber,
    predict,
    update,
    verifyInsurance,
    getCarrierAuthorizations,
    removePolicy,
    autoUpdateCarrier,
  } = props
  const {
    insurancePolicies,
    carrierAuthorizationNumber,
    showCarrierAuthorizationNumber,
  } = dmeOrder

  const primary = insurancePolicies.find((policy) => policy.rank === "primary")
  const secondary = insurancePolicies.find(
    (policy) => policy.rank === "secondary"
  )

  const tertiary = insurancePolicies.find(
    (policy) => policy.rank === "tertiary"
  )

  const isPolicyPresent = (policy) => {
    return !!(policy && policy.memberNumber)
  }

  const isPolicyComplete = (policy) => {
    return !!(policy && policy.memberNumber && policy.carrierName)
  }

  const [
    areAllPresentPoliciesComplete,
    setAreAllPresentPoliciesComplete,
  ] = useState(
    isPolicyComplete(primary) &&
      (secondary ? isPolicyComplete(secondary) : true) &&
      (tertiary ? isPolicyComplete(tertiary) : true)
  )

  const [isSecondaryButtonVisible, setIsSecondaryButtonVisible] = useState(
    isPolicyComplete(primary) && !isPolicyPresent(secondary)
  )

  const [isSecondarySectionVisible, setIsSecondarySectionVisible] = useState(
    isPolicyPresent(secondary)
  )

  const [isTertiaryButtonVisible, setIsTertiaryButtonVisible] = useState(
    isPolicyComplete(primary) &&
      isPolicyComplete(secondary) &&
      !isPolicyPresent(tertiary)
  )

  const [isTertiarySectionVisible, setIsTertiarySectionVisible] = useState(
    isPolicyPresent(tertiary)
  )

  const onSecondaryButtonClicked = () => {
    setIsSecondaryButtonVisible(false)
    setIsSecondarySectionVisible(true)
  }

  const onTertiaryButtonClicked = () => {
    setIsTertiaryButtonVisible(false)
    setIsTertiarySectionVisible(true)
  }

  const onPrimaryPolicyChange = ({ carrierName, memberNumber }) => {
    setAreAllPresentPoliciesComplete(isPolicyComplete(primary))

    if (
      isPolicyComplete({ carrierName, memberNumber }) &&
      !isSecondarySectionVisible
    ) {
      setIsSecondaryButtonVisible(true)
    } else {
      setIsSecondaryButtonVisible(false)
    }
  }

  const onSecondaryPolicyChange = ({ carrierName, memberNumber }) => {
    setAreAllPresentPoliciesComplete(
      isPolicyComplete(primary) && isPolicyComplete(secondary)
    )

    if (
      isPolicyComplete({ carrierName, memberNumber }) &&
      !isTertiarySectionVisible
    ) {
      setIsTertiaryButtonVisible(true)
    } else {
      setIsTertiaryButtonVisible(false)
    }
  }

  const onTertiaryPolicyChange = () => {
    const allPoliciesAreComplete =
      isPolicyComplete(primary) &&
      isPolicyComplete(secondary) &&
      isPolicyComplete(tertiary)

    setAreAllPresentPoliciesComplete(allPoliciesAreComplete)
  }

  const onInsuranceRemove = (rank: InsuranceRank) => {
    if (rank === "secondary") {
      secondary && removePolicy(secondary)
      setIsSecondaryButtonVisible(true)
      setIsTertiaryButtonVisible(false)
      setIsSecondarySectionVisible(false)
    }
    if (rank === "tertiary") {
      tertiary && removePolicy(tertiary)
      isSecondarySectionVisible && setIsTertiaryButtonVisible(true)
      setIsTertiarySectionVisible(false)
    }
    trackEvent(EventCategory.PaymentsPage, `remove-${rank}-insurance`)
  }

  const removeRankPolicy = (rank: InsuranceRank) => {
    if (rank === "primary") {
      primary && removePolicy(primary)
      setIsSecondaryButtonVisible(false)
    }
    if (rank === "secondary") {
      secondary && removePolicy(secondary)
      setIsTertiaryButtonVisible(false)
    }
    if (rank === "tertiary") {
      tertiary && removePolicy(tertiary)
    }
  }

  const status: (policy?: {
    active: boolean
    checkPending: boolean
  }) => InsuranceVerificationInterfaceStatus = (policy) => {
    if (!policy) return InsuranceVerificationInterfaceStatus.Blank
    if (policy.checkPending)
      return InsuranceVerificationInterfaceStatus.Verifying
    if (policy.active) return InsuranceVerificationInterfaceStatus.Verified
    return InsuranceVerificationInterfaceStatus.Unverified
  }

  const renderInsuranceSelection = ({
    rank,
    policy,
    onPolicyChange,
  }: {
    rank: InsuranceRank
    policy?: InsurancePolicy
    onPolicyChange?({
      carrierName,
      memberNumber,
    }: {
      carrierName: string
      memberNumber: string
    }): void
  }) => {
    const title = rank[0].toUpperCase() + rank.substring(1)
    return (
      <div
        className={cx(
          "well",
          "wide-fixed-row",
          "canopy-mbs-12x",
          " canopy-p-12x"
        )}
      >
        <InsuranceSelection
          title={title}
          rank={rank}
          initialMemberNumber={policy?.memberNumber}
          initialCarrierName={policy?.carrierName}
          initialCarrierId={policy?.carrierId}
          initialStatus={status(policy)}
          onPolicyChange={onPolicyChange}
          carrierSearch={carrierSearch}
          predict={predict}
          update={update}
          verifyInsurance={verifyInsurance}
          onInsuranceRemove={onInsuranceRemove}
          removeRankPolicy={removeRankPolicy}
          autoUpdateCarrier={autoUpdateCarrier}
        />
      </div>
    )
  }

  const renderAddInsuranceLink = ({
    rank,
    onClick,
  }: {
    rank: InsuranceRank
    onClick(event): void
  }) => (
    <div
      className={cx(
        "wide-fixed-row",
        "d-flex",
        "justify-content-center",
        "canopy-mbs-12x"
      )}
    >
      <CanopyButton variant="secondary" className="flex-1" onClick={onClick}>
        {`Add ${rank.charAt(0).toUpperCase() + rank.slice(1)} Insurance`}
      </CanopyButton>
    </div>
  )

  return (
    <div className="wide-fixed-row">
      {renderInsuranceSelection({
        rank: "primary",
        policy: primary,
        onPolicyChange: onPrimaryPolicyChange,
      })}
      {isSecondaryButtonVisible &&
        renderAddInsuranceLink({
          rank: "secondary",
          onClick: onSecondaryButtonClicked,
        })}
      {isSecondarySectionVisible &&
        renderInsuranceSelection({
          rank: "secondary",
          policy: secondary,
          onPolicyChange: onSecondaryPolicyChange,
        })}
      {isTertiaryButtonVisible &&
        renderAddInsuranceLink({
          rank: "tertiary",
          onClick: onTertiaryButtonClicked,
        })}
      {isTertiarySectionVisible &&
        renderInsuranceSelection({
          rank: "tertiary",
          policy: tertiary,
          onPolicyChange: onTertiaryPolicyChange,
        })}
      {showCarrierAuthorizationNumber && (
        <div
          className={cx(
            "well",
            "wide-fixed-row",
            "canopy-mbs-12x",
            " canopy-p-12x"
          )}
        >
          <AuthorizationSelect
            onSubmit={updateAuthorizationNumber}
            initialAuthorizationNumber={carrierAuthorizationNumber}
            getCarrierAuthorizations={getCarrierAuthorizations}
          />
          {dmeOrder.carrierAuthorizationEmrSourced &&
            dmeOrder.carrierAuthorizationId && (
              <a
                href={carrierAuthorizationUrl(dmeOrder.carrierAuthorizationId)}
              >
                View Details
              </a>
            )}
        </div>
      )}
      <div className={cx("wide-fixed-row")}>
        {areAllPresentPoliciesComplete && nextPagePath && (
          <div className="text-right canopy-mbs-12x">
            <ContinueButton path={nextPagePath} />
          </div>
        )}
      </div>
    </div>
  )
}

export default InternalPayment
