import { CanopyIcon } from "@parachutehealth/canopy-icon"
import React from "react"
import { HelpText } from "./HelpText"
import { SurveyQuestion } from "sharedTypes"

type Props = {
  isSubmitting: boolean
  valid: boolean
  missingRequiredInformation: boolean
  question: SurveyQuestion
}

export const SaveButton = ({
  isSubmitting,
  valid,
  missingRequiredInformation,
  question,
}: Props) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <button
          type="submit"
          className="btn btn-brand"
          disabled={isSubmitting || !valid}
        >
          {isSubmitting ? "Saving" : "Save"}
        </button>
        <HelpText question={question} />
      </div>
      {missingRequiredInformation && (
        <div className="save-warning canopy-mt-4x d-flex align-items-start">
          <CanopyIcon
            size="small"
            name="circle-exclamation"
            fill="canopyColorTextDanger"
            className="col-auto"
          />
          <div className="col canopy-pl-2x color-danger canopy-typography-body-xsmall">
            Saving is required
          </div>
        </div>
      )}
    </>
  )
}
