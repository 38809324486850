import React from "react"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { FacilityData } from "./FacilityData"
import { Diagnoses } from "./Diagnoses"
import { Diagnosis as DiagnosisType } from "./Diagnoses/api"
import PatientData from "./PatientData"
import { Insurance, Patient, PatientEmergencyContact } from "../../sharedTypes"
import { PatientInformation } from "./PatientInformation"
import { ContactInformation } from "./ContactInformation"
import { PatientAddress } from "./ContactInformation/api"
import { InsuranceInformation } from "./InsuranceInformation"
import { EmergencyContactInformation } from "./EmergencyContactInformation"
import ActionsFooter from "./ActionsFooter"
import { dataFieldWrapper } from "../../index.module.scss"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { PatientContactInformation } from "./ContactInformation/PatientContactInformation"
import { PatientContactInformationProps } from "./ContactInformation/PatientContactInformation/api"
import { ClinicianData, OrderingClinicianData } from "./ClinicianData"

export const DataEntryColumn = ({
  clinician,
  diagnoses,
  patient,
  patientAddress,
  patientContactInformation,
  patientEmergencyContact,
  insurances,
}: {
  clinician: OrderingClinicianData
  diagnoses: DiagnosisType[]
  insurances: Insurance[]
  patient: Patient
  patientAddress: PatientAddress
  patientContactInformation: PatientContactInformationProps
  patientEmergencyContact: PatientEmergencyContact
}) => {
  const { livePilotEnabled } = useCsrInboxState()
  return (
    <>
      <div className={dataFieldWrapper}>
        <CanopyForm>
          <PatientData patient={patient} />
          {!livePilotEnabled && (
            <>
              <h2 className="canopy-typography-heading-xlarge">
                Facility/clinician info
              </h2>
              <ClinicianData clinicianInformation={clinician} />
              <FacilityData />
            </>
          )}
          <PatientInformation patient={patient} />
          <ContactInformation address={patientAddress} />
          <PatientContactInformation
            contactInformation={patientContactInformation}
          />

          <InsuranceInformation insurances={insurances} />
          <Diagnoses initialSelectedDiagnoses={diagnoses} />
          <EmergencyContactInformation
            patientEmergencyContact={patientEmergencyContact}
          />
        </CanopyForm>
      </div>
      <ActionsFooter />
    </>
  )
}
