// @team @cat-crew
import { isNullOrUndefined } from "./isNullOrUndefined"

export function isNonBlankString(value: any): boolean {
  if (!value || isNullOrUndefined(value)) {
    return false
  }
  if (typeof value !== "string") {
    return false
  } else {
    return value.trim() !== ""
  }
}
