// @team @cat-crew

import { get } from "../../../services/api"
import { CatalogUnit } from "../types/sharedTypes"
import { unitsUrl } from "../urls/units"

export type UnitsResponse = {
  units: CatalogUnit[]
}

export const getAllUnits = async (): Promise<UnitsResponse> => {
  return await get(unitsUrl("json"))
    .then((response) => response.data)
    .catch((data) => data.response.data)
}
