import React, { useState } from "react"
import { SalesOrderClassificationMapping } from "components/cms/sharedTypes"
import Table from "components/Table"
import {
  canopyColorInteractiveLinkDefault,
  canopyColorPrimitivesGray94,
  canopyColorTextDanger,
} from "@parachutehealth/canopy-tokens-color"
import { TableContainer } from "@material-ui/core"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  createSupplierOrderClassificationMappingsPath,
  editSupplierOrderClassificationUrl,
} from "./urls"
import { deleteSalesOrderClassificationMapping } from "./api"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { navigate } from "utilities/navigation"

export type SalesOrderClassificationMappingsProps = {
  supplierName: string
  salesOrderClassificationMappings: SalesOrderClassificationMapping[]
  salesOrderClassificationMappingsPath: string
}

const SalesOrderClassificationMappings: React.FC<SalesOrderClassificationMappingsProps> = ({
  supplierName,
  salesOrderClassificationMappings,
  salesOrderClassificationMappingsPath,
}: SalesOrderClassificationMappingsProps) => {
  const [mappings, setMappings] = useState(salesOrderClassificationMappings)
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)

  const handleDelete = async (id: string) => {
    try {
      await deleteSalesOrderClassificationMapping(
        salesOrderClassificationMappingsPath,
        id
      )
      setMappings(mappings.filter((mapping) => mapping.externalId !== id))
      setSuccess(true)
    } catch (error) {
      setFailure(true)
    }
  }

  const salesOrders = mappings.map((salesOrderClassificationMapping) => {
    return {
      key: salesOrderClassificationMapping.externalId,
      productCategory: salesOrderClassificationMapping.catalogCategoryName,
      productSubcategory:
        salesOrderClassificationMapping.catalogSubcategoryName,
      classificationId: salesOrderClassificationMapping.classificationId,
    }
  })
  return (
    <>
      <div>
        {success && (
          <CanopyNotice
            closable={true}
            message="Successfully deleted classification mapping"
            title="Success!"
            variant="success"
          />
        )}
        {failure && (
          <CanopyNotice
            message="Unable to delete classification mapping"
            title="Error!"
            variant="error"
          />
        )}
        <h1>Sales Order Classification Mappings</h1>
        <p>Supplier: {supplierName}</p>
        <CanopyButton
          as="a"
          href={createSupplierOrderClassificationMappingsPath(
            salesOrderClassificationMappingsPath
          )}
          size="medium"
          variant="primary"
        >
          Create
        </CanopyButton>
      </div>
      <TableContainer
        style={{
          border: `1px solid ${canopyColorPrimitivesGray94}`,
          borderRadius: "5px",
        }}
      >
        <Table
          records={salesOrders}
          tableColumns={[
            {
              title: "Product category",
              attr: "productCategory",
              render: (record: { productCategory: string | undefined }) => {
                return <b>{record.productCategory}</b>
              },
            },
            {
              title: "Product subcategory",
              attr: "productSubcategory",
              render: (record: { productSubcategory: string | undefined }) => {
                return <b>{record.productSubcategory}</b>
              },
            },
            {
              title: "Classification ID",
              attr: "classificationId",
              render: (record: { classificationId: string }) => {
                return <b>{record.classificationId}</b>
              },
            },
            {
              title: "Actions",
              attr: "actions",
              render: (record: { key: string }) => {
                return (
                  <div>
                    <CanopyButton
                      style={{
                        color: canopyColorInteractiveLinkDefault,
                      }}
                      variant="tertiary"
                      size="small"
                      onClick={() =>
                        navigate(
                          editSupplierOrderClassificationUrl(
                            salesOrderClassificationMappingsPath,
                            record.key
                          )
                        )
                      }
                    >
                      Edit
                    </CanopyButton>
                    |
                    <CanopyButton
                      style={{
                        color: canopyColorTextDanger,
                      }}
                      variant="tertiary"
                      size="small"
                      onClick={() => {
                        if (
                          confirm(
                            "Are you sure you want to delete this sales order classification mapping?"
                          )
                        ) {
                          handleDelete(record.key)
                        }
                      }}
                    >
                      Delete
                    </CanopyButton>
                  </div>
                )
              },
            },
          ]}
        />
      </TableContainer>
    </>
  )
}

export default SalesOrderClassificationMappings
