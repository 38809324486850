// @team @paypals

import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { carrierAuthorizationDetailsUrl } from "applications/Workflow/urls"
import { carrierAuthCreateMutation } from "../graphql/carrierAuthCreateMutation"
import { withApollo } from "hocs/withApollo"

type AuthRequestButtonProps = {
  carrierAuthorizationId: string
  dmeOrderId: string
}

const AuthRequestButton: React.FC<AuthRequestButtonProps> = (props) => {
  const { carrierAuthorizationId, dmeOrderId } = props

  const [carrierAuthUrl, setCarrierAuthUrl] = useState(
    carrierAuthorizationId
      ? carrierAuthorizationDetailsUrl(carrierAuthorizationId)
      : null
  )

  const [error, setError] = useState("")

  const [createCarrierAuth] = useMutation(carrierAuthCreateMutation, {
    onCompleted: (result) => {
      const carrierAuthId =
        result?.carrierAuthorizationCreate?.carrierAuthorization?.id

      setCarrierAuthUrl(carrierAuthorizationDetailsUrl(carrierAuthId))
      window.open(`${carrierAuthorizationDetailsUrl(carrierAuthId)}`)
    },
    onError: () => {
      setError("Please try again later.")
    },
  })

  const handleClick = () => {
    createCarrierAuth({ variables: { dmeOrderId } })
  }

  const buttonProps = carrierAuthUrl
    ? {
        href: carrierAuthUrl,
        target: "_blank",
        rel: "noreferrer",
      }
    : { onClick: handleClick }

  return (
    <>
      {error && (
        <CanopyNotice
          className="canopy-mb-4x"
          variant="error"
          title="Your request is unable to be submitted at this time."
          message={error}
        />
      )}
      <CanopyNotice
        className="canopy-mb-4x"
        variant="discovery"
        title="Prepare your authorization request with Parachute"
        message="Review and complete a few missing details – we’ll handle the rest! Click “Continue to auth request” to finish the health plan authorization request."
      />
      <CanopyButton
        className="btn-block"
        iconEnd="arrow-up-right-from-square"
        as={carrierAuthUrl ? "a" : "button"}
        {...buttonProps}
      >
        Continue to auth request
      </CanopyButton>
    </>
  )
}

export default withApollo(AuthRequestButton)
