import LeftSidebar, {
  Option,
} from "../../components/SupplierOrganizationDashboard/LeftSidebar"
import React from "react"
import SalesCollaborationToolsNetworkTable from "./SalesCollaborationToolsNetworkTable"

const SalesCollaborationToolsNetwork = () => {
  const rows = [
    {
      id: "fake external id 1",
      facilityName: "Facility Name",
      onParachute: true,
      avgMonthlyOrders: 100,
      outstandingOrdersCount: 50,
      totalOrdersCount: 20,
      salesRep: "Sales Rep",
      facilityAddress: "123 Main St, City, State, 12345",
    },
  ]

  return (
    <div className="position-relative">
      <LeftSidebar active={Option.Network} />
      <div className="row mt-n3">
        <div className="canopy-mbs-12x col-12">
          <h1>
            <span className="canopy-typography-heading-2xlarge">Network</span>
          </h1>
          <div>
            Add new facilities, manage existing facilities, and grow your
            Parachute network.
            <SalesCollaborationToolsNetworkTable rows={rows} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesCollaborationToolsNetwork
