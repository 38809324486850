import React, { FC } from "react"
import * as styles from "./ActionableOrderCard.module.scss"
import NotSelected from "./NotSelected"

interface Props {
  relevantSuppliers: { name: string }[]
}

const Supplier: FC<Props> = ({ relevantSuppliers }) => {
  const getSupplierDisplay = (): React.ReactNode => {
    if (relevantSuppliers.length === 0) {
      return <NotSelected />
    }

    const mostRecentlyAddedSupplierName =
      relevantSuppliers[relevantSuppliers.length - 1].name
    if (relevantSuppliers.length === 1) {
      return mostRecentlyAddedSupplierName
    }

    const additionalSuppliersCount = relevantSuppliers.length - 1
    return `${mostRecentlyAddedSupplierName} +${additionalSuppliersCount}`
  }

  return (
    <>
      <div>
        <h4 className={styles.detailHeader}>SUPPLIER</h4>
        <span className={styles.detailContent}>{getSupplierDisplay()}</span>
      </div>
    </>
  )
}

export default Supplier
