import React from "react"
import { useFormikParachute } from "../../../../../shared/hooks/useFormikParachute/useFormikParachute"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"

// Component API
type OrderLinkageModalProps = {
  /**
   * Function called when the modal is closed
   */
  onClose: () => void
  /**
   * Function called when the form is submitted (and all fields are valid)
   */
  onSubmit: (supplierSystemId) => Promise<void>
  /**
   * When true, the modal is visible
   */
  open?: boolean
  /**
   * Initial value that populates the single text input on the form
   */
  supplierSystemId?: string
}

// Form field interface
interface OrderLinkageFields {
  supplierSystemId?: string
}

/**
 * Modal form used to link Brightree orders to Parachute orders
 */
const OrderLinkageModal = (props: OrderLinkageModalProps): JSX.Element => {
  const { onClose, onSubmit, open = true, supplierSystemId } = props

  const formik = useFormikParachute<OrderLinkageFields>({
    initialValues: {
      supplierSystemId: supplierSystemId,
    },
    onSubmit: (values) => {
      return onSubmit(values.supplierSystemId).then(() => onClose())
    },
    // No form validation  needed here since the field is optional and submitting it blank is a means for "clearing out" the supplierSystemId
  })

  return (
    <CanopyDialogModal
      header="Link Parachute order to Brightree"
      onClose={onClose}
      open={open}
      primaryFooterButton={
        <CanopyButton
          type="submit"
          form="brightreeSalesIdLinkageForm"
          loading={formik.isSubmitting}
          loadingText="Linking Brightree Order..."
        >
          Save
        </CanopyButton>
      }
    >
      <CanopyForm
        id="brightreeSalesIdLinkageForm"
        onSubmit={formik.handleSubmit}
      >
        <CanopyTextInputField
          feedbackMessage={formik.errors.supplierSystemId}
          label="Brightree sales ID"
          name="supplierSystemId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.supplierSystemId}
        />
      </CanopyForm>
    </CanopyDialogModal>
  )
}

export default OrderLinkageModal
