import React, { useCallback, useState } from "react"
import { DmeOrder } from "sharedTypes"
import { useFormikParachute } from "../../../../../shared/hooks/useFormikParachute/useFormikParachute"
import { FormikErrors } from "formik"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"
import SignaturePad from "./components/SignaturePad/SignaturePad"
import * as styles from "./DeliveryReceipt.module.scss"

interface DeliveryReceiptFields {
  base64File: string
  dispensedBy: string
  equipmentSerialNumber: string
  reason: string
  relationship: string
  signedBy: string
}
interface DeliveryReceiptProps {
  dmeOrder: DmeOrder
  errors: { [key: string]: string }
  onClose: () => void
  onSubmit: (values: DeliveryReceiptFields) => Promise<void>
}

const DeliveryReceipt = (props: DeliveryReceiptProps): JSX.Element => {
  const { dmeOrder, errors, onClose, onSubmit } = props

  const [signer, setSigner] = useState<string>("self")

  const formik = useFormikParachute<DeliveryReceiptFields>({
    initialValues: {
      base64File: "",
      dispensedBy: dmeOrder.creatorName,
      equipmentSerialNumber: "",
      reason: "",
      relationship: "self",
      signedBy: "",
    },
    initialErrors: errors,
    onSubmit: (values) => {
      onSubmit(values)
    },
    validate: (values) => {
      const errors: FormikErrors<DeliveryReceiptFields> = {}

      if (!values.base64File) {
        errors.base64File = "Provide a signature"
      }
      if (!values.signedBy) {
        errors.signedBy = `Enter the ${
          signer === "self" ? "patient's" : "signer's"
        } name`
      }
      if (!values.dispensedBy) {
        errors.dispensedBy = "Enter the name of who dispensed"
      }
      if (!values.relationship) {
        errors.relationship = "Enter the signer's relationship to the patient"
      }
      if (values.relationship !== "self" && !values.reason) {
        errors.reason = "Enter the reason the patient could not sign"
      }

      return errors
    },
  })

  const handleSignerChange = useCallback(
    (value) => {
      setSigner(value.target.value)
      if (value.target.value === "self") {
        formik.setFieldValue("relationship", "self")
      } else {
        formik.setFieldValue("relationship", "")
      }
    },
    [formik, setSigner]
  )

  return (
    <CanopyDialogModal
      className={styles.deliveryReceiptModal}
      open
      primaryFooterButton={
        <CanopyButton
          type="submit"
          loading={formik.isSubmitting}
          loadingText="Saving signature..."
          form="deliveryReceiptForm"
        >
          Sign
        </CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton variant="secondary" onClick={onClose}>
          Cancel
        </CanopyButton>
      }
      onClose={onClose}
      header="Sign proof of delivery"
    >
      <CanopyForm id="deliveryReceiptForm" onSubmit={formik.handleSubmit}>
        <CanopyRequiredFieldKey />
        <SignaturePad
          height={200}
          onChange={(base64DataUrl) => {
            formik.setFieldValue("base64File", base64DataUrl || "")
          }}
          fieldError={formik.errors.base64File}
        />

        <CanopyRadioInputField
          label="Did the patient sign for this?"
          name="signer"
          onChange={handleSignerChange}
          options={[
            {
              label: "Yes",
              value: "self",
            },
            {
              label: "No",
              value: "other",
            },
          ]}
          value={signer}
        />
        <CanopyTextInputField
          feedbackMessage={formik.errors.signedBy}
          label={signer === "self" ? "Patient's name" : "Who signed for this?"}
          name="signedBy"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.signedBy}
          required
        />
        {signer !== "self" && (
          <>
            <CanopyTextInputField
              feedbackMessage={formik.errors.relationship}
              label="Relationship to patient"
              name="relationship"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.relationship}
              required
            />
            <CanopyTextInputField
              feedbackMessage={formik.errors.reason}
              label="Reason patient could not sign"
              name="reason"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.reason}
              required
            />
          </>
        )}
        <CanopyTextInputField
          feedbackMessage={formik.errors.dispensedBy}
          label="Dispensing user"
          name="dispensedBy"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.dispensedBy}
          required
        />
        <CanopyTextInputField
          feedbackMessage={formik.errors.equipmentSerialNumber}
          label="Equipment serial number"
          name="equipmentSerialNumber"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.equipmentSerialNumber}
        />
      </CanopyForm>
    </CanopyDialogModal>
  )
}

export default DeliveryReceipt
