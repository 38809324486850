import { put } from "services/api"
import { updatePatientContactInformationUrl } from "../../../../urls"
import { ResourceUpdateResponse } from "../../api"

export type PatientContactInformationProps = {
  email?: string
}

export const updatePatientContactInformation = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  patientContactInformation: Partial<PatientContactInformationProps>
): Promise<ResourceUpdateResponse<keyof PatientContactInformationProps>> => {
  return put(
    updatePatientContactInformationUrl(supplierOrganizationId, csrInboxStateId),
    {
      patientContactInformation,
    }
  )
    .then(() => ({ success: true }))
    .catch((e) => ({ errors: e.response?.data?.errors || {} }))
}
