import React from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { existingOrderUrl } from "./urls"

interface Props {
  orderId: string
  patientFullName: string
}

const OrderCanceledAlert: React.FC<Props> = ({ orderId, patientFullName }) => {
  const noticeTitle = `Order for ${patientFullName} has been canceled.`
  const orderLink = existingOrderUrl(orderId)

  return (
    <div className="canopy-mbe-4x">
      <CanopyNotice
        closable
        title={noticeTitle}
        variant="success"
        actions={[
          {
            href: orderLink,
            text: "View order",
          },
        ]}
      />
    </div>
  )
}

export default OrderCanceledAlert
