import { get, post } from "services/api"
import {
  fulfillmentAgreementPromotionsConfirmUrl,
  fulfillmentAgreementPromotionsDismissUrl,
  searchSettingsUrl,
  searchSuppliersUrl,
} from "./urls"
import {
  SearchSettings,
  SuppliersRequest,
  SuppliersResponse,
} from "./sharedTypes"

export const searchSuppliers = (
  suppliersRequest: SuppliersRequest
): Promise<SuppliersResponse> =>
  get(searchSuppliersUrl(), suppliersRequest).then(({ data }) => data)

export const loadSearchSettings = (): Promise<SearchSettings> =>
  get(searchSettingsUrl()).then(({ data }) => data)

export const confirmPromotion = (externalId: string): Promise<any> =>
  post(fulfillmentAgreementPromotionsConfirmUrl(externalId), {}).then(
    (response) => response
  )

export const dismissPromotion = (externalId: string): Promise<any> =>
  post(fulfillmentAgreementPromotionsDismissUrl(externalId), {}).then(
    (response) => response
  )
