import React, { ReactNode } from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

interface Props {
  children: ReactNode
  selectedFocusTile: string | null
}

const EmptyState = ({ children, selectedFocusTile }: Props) => {
  const emptyStateMessage = (() => {
    switch (selectedFocusTile) {
      case "newMessages":
        return "No orders at your facility have new messages."
      case "incomingOrders":
        return "There are no incoming orders at your facility."
      case "sentBackForReview":
        return "No orders at your facility have been sent back for review."
      default:
        return "Clear your filters to view all orders at your facility."
    }
  })()
  return (
    <div className="row">
      <div className="col-12">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ marginTop: "76px" }}
        >
          {selectedFocusTile && (
            <CanopyIcon
              className="canopy-mbe-16x"
              fill="canopyColorBrandSecondary"
              name="sparkles"
              size="large"
            />
          )}
          <h4 className="canopy-typography-heading-2xlarge canopy-mbe-8x">
            {selectedFocusTile ? "Your work here is done!" : "No orders found"}
          </h4>
          <p className="canopy-typography-body-medium canopy-mbe-8x">
            {emptyStateMessage}
          </p>
          {children}
        </div>
      </div>
    </div>
  )
}

export default EmptyState
