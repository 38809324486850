import { put } from "services/api"
import { updatePatientUrl } from "../../../urls"
import { ResourceUpdateResponse } from "../api"
import { Patient } from "../../../sharedTypes"

export const updatePatient = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  patient: Partial<Patient>
): Promise<ResourceUpdateResponse<keyof Patient>> => {
  return put(updatePatientUrl(supplierOrganizationId, csrInboxStateId), {
    patient,
  })
    .then(() => ({ success: true }))
    .catch((e) => ({ errors: e.response?.data?.errors || {} }))
}
