import React, { useState } from "react"
import {
  Context,
  EmrCachedPatients,
  HospicePatient,
  MasterPatient,
  RecentOrder,
} from "sharedTypes"
import { capitalize } from "utilities/string"
import IntegratedData from "./IntegratedData"
import CreateOrderButton from "./CreateOrderButton"
import RecentOrdersSection from "./RecentOrdersSection"
import NewOrderForm from "applications/NewOrderForm"
import HospiceDetail from "./HospiceDetail"
import { FormData } from "./UpdatePatientHospiceForm"
import EmrResetPasswordAlert from "./Alerts/EmrResetPasswordAlert"
import PendingOrderAlert from "./Alerts/PendingOrderAlert"
import { EmailResponse } from "../sharedTypes"
import * as styles from "./InternalPatientProfile.module.scss"
import classNames from "classnames"

type Props = {
  context: Context
  masterPatient: MasterPatient
  emrPatient: boolean
  emrCachedPatients: EmrCachedPatients
  hospicePatient: HospicePatient
  hospiceEnabled: boolean
  createDmeOrder(patientId?: string, cachedEncounterId?: string): Promise<void>
  recentOrders: RecentOrder[]
  showDmeOrderUrl(dmeOrderId: string): string
  showSuppliers: boolean
  permissions: {
    updateHospice: boolean
    createOrder: boolean
  }
  sendEmrResetPasswordEmail(): Promise<EmailResponse>
  supplierSystemPatientLinkages: { supplierSystemPatientId: string }[]
  updateHospiceDates(
    values: FormData
  ): Promise<{ hospicePatient: HospicePatient }>
}

const InternalPatientProfile: React.FC<Props> = ({
  context,
  masterPatient,
  emrPatient,
  emrCachedPatients,
  recentOrders,
  createDmeOrder,
  showDmeOrderUrl,
  hospicePatient,
  hospiceEnabled,
  showSuppliers,
  permissions,
  updateHospiceDates,
  sendEmrResetPasswordEmail,
  supplierSystemPatientLinkages,
}) => {
  const [hasPendingOrders, setHasPendingOrders] = useState(false)

  const demographics = () => (
    <div className={classNames("canopy-mbe-8x", styles.demographics)}>
      <div>
        <span className={styles.demographicsHeader}>DOB</span>
        <br />
        {masterPatient.dateOfBirth}
      </div>
      <div>
        <span className={styles.demographicsHeader}>SEX</span>
        <br />
        {capitalize(
          masterPatient.sex === "unknown" ? "Unspecified" : masterPatient.sex
        )}
      </div>
      {supplierSystemPatientLinkages.length > 0 && (
        <div>
          <span className={styles.demographicsHeader}>
            SUPPLIER SYSTEM PATIENT ID
            {supplierSystemPatientLinkages.length > 1 ? "S" : ""}
          </span>
          <br />
          {supplierSystemPatientLinkages
            .map((l) => l.supplierSystemPatientId)
            .join(", ")}
        </div>
      )}
      {hospiceSection()}
    </div>
  )

  const hospiceSection = () => {
    if (hospiceEnabled) {
      return (
        <HospiceDetail
          hospicePatient={hospicePatient}
          updateHospiceDates={updateHospiceDates}
          updateHospicePermission={permissions.updateHospice}
        />
      )
    }
  }

  const newOrderButton = () => {
    if (!permissions.createOrder) {
      return
    }

    if (
      ["supplier", "supplier_organization"].includes(
        context.currentEmployer.employerType
      )
    ) {
      return (
        <div className="float-right">
          <NewOrderForm
            employer={context.currentEmployer}
            masterPatient={masterPatient}
          />
        </div>
      )
    }

    if (!emrPatient) {
      return (
        <div>
          <CreateOrderButton
            createDmeOrder={() => createDmeOrder()}
            isPrimary
            isBrand={false}
          />
        </div>
      )
    }
  }

  const renderHeader = () => {
    return (
      <div className="canopy-pbe-4x">
        <h1 className={styles.patientName}>
          {masterPatient.firstName} {masterPatient.lastName}
        </h1>
        <div className="row">
          <div className="col-md-8">{demographics()}</div>
          <div className="col-md-4 text-right">{newOrderButton()}</div>
        </div>
        <hr className="canopy-my-4x" />
      </div>
    )
  }

  return (
    <div className="container">
      {context.showEmrResetPasswordBanner && (
        <EmrResetPasswordAlert
          sendEmrResetPasswordEmail={() => sendEmrResetPasswordEmail()}
        />
      )}

      {renderHeader()}
      {emrCachedPatients?.map((emrCachedPatient) => (
        <PendingOrderAlert
          key={emrCachedPatient.externalId}
          patientExternalId={emrCachedPatient.externalId}
          showDmeOrderUrl={showDmeOrderUrl}
          onQueryCompleted={({ pendingOrder }) =>
            setHasPendingOrders(hasPendingOrders || pendingOrder !== null)
          }
        />
      ))}
      {emrPatient && emrCachedPatients.length > 0 && (
        <IntegratedData
          emrCachedPatients={emrCachedPatients}
          createDmeOrder={createDmeOrder}
          canCreateOrder={permissions.createOrder}
          hasPrimaryAction={!hasPendingOrders}
        />
      )}
      <RecentOrdersSection
        recentOrders={recentOrders}
        showDmeOrderUrl={showDmeOrderUrl}
        showSuppliers={showSuppliers}
        currentEmployer={context.currentEmployer}
      />
    </div>
  )
}

export default InternalPatientProfile
