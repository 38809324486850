import React from "react"
import Icon from "components/Icon"
import { reviewGenerateInvitePath } from "applications/Workflow/routes"
import { Link } from "react-router-dom"

const GenerateInviteLinkButton = () => {
  return (
    <Link
      type="button"
      className="btn btn-brand-o btn-block"
      to={reviewGenerateInvitePath()}
    >
      <Icon type="link" className="canopy-mie-2x" />
      Generate Order Link
    </Link>
  )
}

export default GenerateInviteLinkButton
