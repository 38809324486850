import React, { useEffect, Dispatch, SetStateAction } from "react"
import MessageForm from "./components/MessageForm"
import Messages from "./components/Messages"
import ChatUnavailable from "./components/ChatUnavailable"
import ChatDisabled from "./components/ChatDisabled"
import { PatientPortalEndpoint } from "services/patientPortal"
import {
  useSupplierChatTemplatesQuery,
  useChatQuery,
  useChatSubscription,
} from "./api"
import { interpolateTemplates } from "./utilities/templateInterpolator"
import { useFeatureFlags } from "components/FeatureFlagContext"
import * as styles from "./PatientPortal.module.scss"

type Props = {
  dmeOrderId: string
  chatId: string
  chatAvailable: boolean
  patientPortalEndpoint: PatientPortalEndpoint
  setUnreadPatientMessages: Dispatch<SetStateAction<boolean>>
  supplierName: string
  portalMessagingSupplierEnabled: boolean
}

const PatientPortal: React.FC<Props> = ({
  dmeOrderId,
  chatId,
  chatAvailable,
  patientPortalEndpoint,
  setUnreadPatientMessages,
  supplierName,
  portalMessagingSupplierEnabled,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()

  const {
    data: supplierChatTemplates,
    isFetching: fetchingTemplates,
  } = useSupplierChatTemplatesQuery(dmeOrderId, chatAvailable)

  const { data: chat, isFetching: fetchingChat } = useChatQuery(
    chatId,
    chatAvailable
  )

  useChatSubscription(chatId, chat?.token)

  useEffect(() => {
    setUnreadPatientMessages(false)
  }, [chatId, chat?.token, setUnreadPatientMessages])

  const onSubmit = (params, { resetForm }) => {
    return patientPortalEndpoint.sendMessage(params).then(() => {
      resetForm()
    })
  }

  if (fetchingTemplates || fetchingChat) {
    return (
      <div data-testid="chat-loading-spinner" className={styles.loadingSpinner}>
        <div className={styles.loadingSpinnerIcon}>
          <div className="fas fa-spinner fa-spin color-primary" />
        </div>
      </div>
    )
  } else if (supplierChatTemplates && chat) {
    const { messages, patient } = chat

    const chatTemplates = interpolateTemplates(supplierChatTemplates, {
      supplierName,
      patientFirstName: patient.firstName,
      patientLastName: patient.lastName,
    })

    return (
      <>
        <MessageForm
          chatId={chatId}
          chatTemplates={chatTemplates}
          patientConsentsToSms={patient.consentsToSms}
          patientOptedOutAt={patient.optedOutAt}
          onSubmit={onSubmit}
        />
        <Messages
          messages={messages}
          chat={{ patient }}
          insuranceUploadUrl={patientPortalEndpoint.insuranceUploadUrl}
        />
      </>
    )
  } else if (
    isFeatureEnabled("chatCommercialization") &&
    !portalMessagingSupplierEnabled
  ) {
    return <ChatDisabled supplierName={supplierName} />
  } else {
    return <ChatUnavailable />
  }
}
export default PatientPortal
