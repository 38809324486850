// @ts-strict-ignore

import axios, { AxiosResponse } from "axios"
import { fetchJwt } from "services/jwt"
import { Nullable } from "sharedTypes"
import { camelizeKeys, decamelizeKeys } from "humps"
import { reportError } from "utilities/error"

const MAX_ATTEMPTS = 5

export type Patient = {
  firstName: string
  lastName: string
  consentsToSms: Nullable<boolean>
  optedOutAt: Nullable<Date>
}

export type SupplierSettings = {
  id: string
  parachuteId: string
  name: string
  portalEnabled: boolean
  portalMessaging: boolean
  automatedWelcomeMessage: Nullable<string>
}

type Supplier = {
  name: string
}

type User = {
  parachuteId: string
  firstName: string
  lastName: string
}

export type ResponseData<T> = {
  data: T
}

export type Message = {
  externalId: string
  content: string
  insertedAt: string
  supplier: Supplier
  userAuthor: Nullable<User>
  readAt?: Nullable<string>
  type: string
  insuranceRank?: string
}

export type Chat = {
  patient: Patient
}

export type ChatWithMessages = Chat & {
  messages: Message[]
  token: string
}

export type FacilityNameData = {
  facilityFullName: string
  facilityShortName: string
}

export type PatientPortalData = {
  facilityEnabled: boolean
  phoneCanReceiveSms: boolean
  phoneNumber: string
  latestMessageScheduledAt: string
  supplierControlPanelUrl: Nullable<string>
  portalMessagingEnabled: boolean
  portalMessagingSupplierEnabled: boolean
  chatId: string
  lastAccessEventType: Nullable<string>
  lastPatientLoginAt: Nullable<string>
  inviteSmsCopy: Nullable<string>
  lastFailedLoginAt: Nullable<string>
  unreadPatientMessages: boolean
  phoneNumberRejectedAt: Nullable<string>
  optedOutAt: Nullable<string>
  supplierName: string
  canForceInviteResend: boolean
  facilityInviteResentAt: Nullable<Date>
}

export type PatientPortalEndpoint = {
  fetchSupplier: (supplierId: string) => Promise<ResponseData<SupplierSettings>>
  updateSupplier: (
    supplierId: string,
    automatedWelcomeMessage: string
  ) => Promise<ResponseData<SupplierSettings>>
  fetchOrder: FetchOrder
  updateOrder: (
    orderId: string | undefined,
    facilityEnabled: boolean
  ) => Promise<ResponseData<PatientPortalData>>
  resendFacilityInvite: (
    orderId: string | undefined
  ) => Promise<ResponseData<PatientPortalData>>
  fetchChat: (chatId: string) => Promise<ResponseData<ChatWithMessages>>
  sendMessage: (params: {
    chatId: string
    content: string
  }) => Promise<ResponseData<Message>>
  confirmFacilityViewImgUrl: (orderId: string | undefined) => string
  insuranceUploadUrl: (orderId: string | undefined) => string
  fetchFacilityNames: (
    facilityId: string
  ) => Promise<ResponseData<FacilityNameData>>
  updateFacilityNames: (
    facilityId: string,
    manualFullName: string,
    manualShortName: string
  ) => Promise<ResponseData<FacilityNameData>>
}

interface FetchOrder {
  (args: {
    orderId: string | undefined
    globalFacilityEnabled: boolean
    globalSupplierEnabled: boolean
    latestPatientPortalEventTimestamp: Nullable<string>
  }): Promise<Nullable<PatientPortalData>>
}

const isSynced = (
  latestEventTimestamp,
  { latestEventTimestamp: remoteLatestEventTimestamp }
) => {
  const latestEventDate = latestEventTimestamp && new Date(latestEventTimestamp)
  const remoteLatestEventDate =
    remoteLatestEventTimestamp && new Date(remoteLatestEventTimestamp)

  return (
    remoteLatestEventDate &&
    (!latestEventDate || latestEventDate <= remoteLatestEventDate)
  )
}

export const build = (): PatientPortalEndpoint => {
  const instance = axios.create({
    withCredentials: true,
    baseURL: window.patientPortalUrl,
    transformRequest: [].concat(
      (data) => decamelizeKeys(data),
      axios.defaults.transformRequest
    ),
    transformResponse: [].concat(axios.defaults.transformResponse, (data) =>
      camelizeKeys(data)
    ),
    validateStatus: (status) => status >= 200 && status < 500,
  })

  const createSession = () => {
    return fetchJwt(window.patientPortalJwtSlug)
      .then(({ status, data }) => {
        if (status !== 201) {
          return Promise.reject(`Invalid jwt response: ${status}`)
        }
        return instance.post("/api/sessions", { jwt: data.token })
      })
      .then(({ status }) => {
        if (status !== 201) {
          return Promise.reject(`Invalid session response: ${status}`)
        }
        return Promise.resolve(null)
      })
  }

  const doRequest = (fn: () => Promise<AxiosResponse<any>>) =>
    fn().then((response) => {
      if (response.status === 401) {
        return createSession().then(fn)
      }
      return response
    })

  const get = (url) => doRequest(() => instance.get(url))
  const put = (url, params) => doRequest(() => instance.put(url, params))
  const post = (url, params) => doRequest(() => instance.post(url, params))
  const patch = (url, params) => doRequest(() => instance.patch(url, params))

  const supplierUrl = (supplierId) => `/api/suppliers/${supplierId}`
  const orderUrl = (orderId) => `/api/orders/${orderId}`
  const chatUrl = (chatId) => `/api/chats/${chatId}`
  const newMessageUrl = "/api/messages"
  const facilityUrl = (facilityId) => `/api/clinical_facilities/${facilityId}`
  const resendInviteUrl = (orderId) => `/api/orders/${orderId}/resend_invite`

  const tryFetchOrder = (attempt, orderUrl, latestEventTimestamp, resolve) => {
    get(orderUrl).then(
      ({ status, data }) => {
        if (status === 404 || status === 403) {
          resolve(null)
        } else if (isSynced(latestEventTimestamp, data)) {
          resolve(data)
        } else if (attempt >= MAX_ATTEMPTS) {
          reportError(new Error("Patient portal data not synced"))
          resolve(null)
        } else {
          setTimeout(
            () =>
              tryFetchOrder(
                attempt + 1,
                orderUrl,
                latestEventTimestamp,
                resolve
              ),
            attempt * 200
          )
        }
      },
      (error) => {
        reportError(error)
        resolve(null)
      }
    )
  }

  const fetchOrder: FetchOrder = (args) => {
    return new Promise<PatientPortalData>((resolve) =>
      tryFetchOrder(
        1,
        orderUrl(args.orderId),
        args.latestPatientPortalEventTimestamp,
        resolve
      )
    )
  }
  const updateOrder = (
    orderId: string | undefined,
    facilityEnabled: boolean
  ): Promise<ResponseData<PatientPortalData>> => {
    return put(orderUrl(orderId), { facilityEnabled })
  }

  const resendFacilityInvite = (
    orderId: string | undefined
  ): Promise<ResponseData<PatientPortalData>> => {
    return post(resendInviteUrl(orderId), {})
  }

  const fetchSupplier = (
    supplierId: string
  ): Promise<ResponseData<SupplierSettings>> => {
    return get(supplierUrl(supplierId))
  }

  const updateSupplier = (
    supplierId: string,
    automatedWelcomeMessage: string
  ): Promise<ResponseData<SupplierSettings>> => {
    return patch(supplierUrl(supplierId), {
      automatedWelcomeMessage: automatedWelcomeMessage,
    })
  }

  const fetchChat = (
    chatId: string
  ): Promise<ResponseData<ChatWithMessages>> => {
    return get(chatUrl(chatId))
  }

  const sendMessage = (params: {
    chatId: string
    content: string
  }): Promise<ResponseData<Message>> => {
    return post(newMessageUrl, params)
  }

  const confirmFacilityViewImgUrl = (orderId) =>
    `${window.patientPortalUrl}${orderUrl(orderId)}/facility_confirm_view.gif`

  const insuranceUploadUrl = (orderId) =>
    `${window.patientPortalUrl}${orderUrl(orderId)}/insurance_uploads`

  const fetchFacilityNames = (facilityId: string) => {
    return get(facilityUrl(facilityId))
  }

  const updateFacilityNames = (
    facilityId: string,
    manualFullName: string,
    manualShortName: string
  ) => {
    return put(facilityUrl(facilityId), {
      manualFullName,
      manualShortName,
    })
  }

  return {
    fetchOrder,
    fetchSupplier,
    updateSupplier,
    updateOrder,
    resendFacilityInvite,
    fetchChat,
    sendMessage,
    confirmFacilityViewImgUrl,
    insuranceUploadUrl,
    fetchFacilityNames,
    updateFacilityNames,
  }
}
