import { FacilityType } from "./sharedTypes"

const sortFacilityTypes = (tuples: [string, string][]) =>
  tuples.sort((tuple1, tuple2) => {
    return tuple1[1] > tuple2[1] ? 1 : -1
  })

const SELECTABLE_TYPES: [FacilityType, string][] = [
  [FacilityType.AmbulatoryPractice, "Ambulatory Practice"],
  [FacilityType.AssistedLiving, "Assisted Living"],
  [FacilityType.CancerCenter, "Cancer Center"],
  [FacilityType.DialysisCenter, "Dialysis Center"],
  [FacilityType.HealthPlan, "Health Plan"],
  [FacilityType.HomeHealth, "Home Health"],
  [FacilityType.Hospice, "Hospice"],
  [FacilityType.Hospital, "Hospital"],
  [FacilityType.NursingOrRehab, "Nursing and/or Rehab"],
  [FacilityType.SleepLab, "Sleep Lab"],
]

export const getSelectableFacilityTypes = (): [string, string][] =>
  sortFacilityTypes(
    SELECTABLE_TYPES.map(([facilityType, label]) => [
      facilityType as string,
      label,
    ])
  )
