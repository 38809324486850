import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"

import { actionsFooter } from "../../../index.module.scss"
import { navigate, reload } from "utilities/navigation"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { useIsMutating } from "@tanstack/react-query"
import { useErrorContext } from "../../../error-context"
import { useFacilityQuery } from "../FacilityData"
import { useDoctorQuery } from "../ClinicianData"
import { createOrder } from "./api"

const useAllRequiredFieldsSetCheck = () => {
  const { data: facilityData } = useFacilityQuery()
  const { data: doctorData } = useDoctorQuery()
  return !!facilityData?.facility?.id || !!doctorData?.id
}

export const ActionsFooter = () => {
  const numberOfActiveMutations = useIsMutating()
  const csrInboxState = useCsrInboxState()
  const { setDisplayErrors } = useErrorContext()
  const allRequiredFieldsSet = useAllRequiredFieldsSetCheck()

  const [saving, setSaving] = useState(false)
  const onClick = () => {
    if (!allRequiredFieldsSet) {
      setDisplayErrors(true)
    } else {
      setSaving(true)
      createOrder({
        supplierOrganizationId: csrInboxState.supplierOrganization.id,
        csrInboxStateId: csrInboxState.id,
      })
        .then((response) => {
          setSaving(false)
          navigate(response.orderPath)
        })
        .catch(() => {
          setSaving(false)
          reload()
        })
    }
  }

  return (
    <div className={actionsFooter}>
      <CanopyButton
        loading={saving}
        onClick={onClick}
        disabled={numberOfActiveMutations > 0}
      >
        Save and go to order
      </CanopyButton>
    </div>
  )
}

export default ActionsFooter
