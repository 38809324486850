import React, { useState, useEffect } from "react"
import InternalSignature from "./components/InternalSignature"
import withInitialData from "components/withInitialData"
import { handleError } from "utilities/error"
import {
  createDoctorContact,
  doctorSearch,
  loadInitialSignatureData,
  selectDoctor,
  updateSignatureChannel,
  updateSignatureType,
} from "./api"
import { ApplicationError, DmeOrder } from "sharedTypes"
import { DoctorData, InitialData, SignableRequirement } from "./sharedTypes"

type Props = {
  dmeOrder: DmeOrder
  initialData: InitialData
  nextPagePath?: string
  isPageChecked?: boolean
  setDmeOrder(dmeOrder: DmeOrder): void
  onSignatureDataChange?(initialData: InitialData): void
}

const Signature = (props: Props) => {
  const {
    dmeOrder,
    initialData,
    nextPagePath,
    isPageChecked,
    setDmeOrder,
    onSignatureDataChange,
  } = props

  const [allowFaxPacketDownload, setAllowFaxPacketDownload] = useState<boolean>(
    initialData.allowFaxPacketDownload
  )
  const [doctorData, setDoctorData] = useState<DoctorData>(
    initialData.doctorData
  )
  const [signableRequirements, setSignableRequirements] = useState<
    SignableRequirement[]
  >(initialData.signableRequirements)

  useEffect(() => {
    onSignatureDataChange?.(initialData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])

  const refreshDmeOrderAndSignatureData = (dmeOrder: DmeOrder) =>
    Promise.all([
      setDmeOrder(dmeOrder),
      loadInitialSignatureData().then((response) => {
        setAllowFaxPacketDownload(response.data.allowFaxPacketDownload)
        setDoctorData(response.data.doctorData)
        setSignableRequirements(response.data.signableRequirements)
        onSignatureDataChange?.(response.data)
      }),
    ])

  const handleCreateDoctorContact = async (attributes: object) => {
    const response = await createDoctorContact(attributes)
    return await refreshDmeOrderAndSignatureData(response.data)
  }

  const handleSelectDoctor = async (doctorNpi) => {
    try {
      const response = await selectDoctor(doctorNpi)
      return await refreshDmeOrderAndSignatureData(response.data)
    } catch (error) {
      return handleError(error as ApplicationError)
    }
  }

  const handleUpdateSignatureChannel = async (channel, isSelected) => {
    const response = await updateSignatureChannel(channel, isSelected)
    return await refreshDmeOrderAndSignatureData(response.data)
  }

  const handleUpdateSignatureType = async (signatureType) => {
    const response = await updateSignatureType(signatureType)
    return await refreshDmeOrderAndSignatureData(response.data)
  }

  const {
    showSignatureChannelSelector,
    suggestedDoctors,
    selfSignDoctorNpi,
    signOnScreenEnabled,
  } = initialData

  const {
    epicSignatureChannel,
    cernerSignatureChannel,
    verifiedChannels,
  } = doctorData

  return (
    <InternalSignature
      allowFaxPacketDownload={allowFaxPacketDownload}
      createDoctorContact={handleCreateDoctorContact}
      dmeOrder={dmeOrder}
      doctorSearch={doctorSearch}
      epicSignatureChannel={epicSignatureChannel}
      cernerSignatureChannel={cernerSignatureChannel}
      nextPagePath={nextPagePath}
      isPageChecked={isPageChecked}
      selectDoctor={handleSelectDoctor}
      showSignatureChannelSelector={showSignatureChannelSelector}
      signableRequirements={signableRequirements}
      suggestedDoctors={suggestedDoctors}
      updateSignatureChannel={handleUpdateSignatureChannel}
      updateSignatureType={handleUpdateSignatureType}
      selfSignDoctorNpi={selfSignDoctorNpi}
      verifiedChannels={verifiedChannels}
      signOnScreenEnabled={signOnScreenEnabled}
    />
  )
}

const fetchInitialData = () =>
  loadInitialSignatureData().then(({ data }) => data)

export default withInitialData(fetchInitialData)(Signature)
