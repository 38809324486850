import React, { FC } from "react"
import { format } from "utilities/date"
import * as styles from "./ActionableOrderCard.module.scss"
import classNames from "classnames"
import { DashboardEvent } from "../../../sharedTypes"
import CommentWithMentions from "./CommentWithMentions"

interface Props {
  lastComment: DashboardEvent | null
  unreadEventCount: number
}

const LastComment: FC<Props> = ({ lastComment, unreadEventCount }) => {
  if (!lastComment) return null
  const isCommentByCurrentUser =
    lastComment.actorId === window?.parachute?.currentUserId
  const isCommentByCurrentEmployer =
    lastComment.actorOrganizationId === window?.parachute?.employerId
  const commentByline = isCommentByCurrentUser
    ? " (you)"
    : isCommentByCurrentEmployer
    ? ""
    : ` (${lastComment.actorOrganizationName})`

  return (
    <article
      className={classNames(
        styles.lastCommentContainer,
        (isCommentByCurrentEmployer || isCommentByCurrentUser) &&
          styles.lastCommentByCurrentEmployer,
        unreadEventCount > 0 && styles.unreadComment
      )}
      aria-label={`Comment body: ${lastComment.content}`}
    >
      <div className="d-flex justify-content-space-between">
        <header className={styles.lastCommentHeader}>
          {lastComment.actorName}
          {commentByline}
        </header>
        {lastComment.createdAt && (
          <div className={styles.lastCommentDate}>
            {format(lastComment.createdAt)}
          </div>
        )}
      </div>
      <CommentWithMentions lastComment={lastComment} />
    </article>
  )
}

export default LastComment
