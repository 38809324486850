// @team @facilitators
import { get } from "services/api"
import { availableSuppliersUrl } from "../../../../urls"
import { AvailableSuppliers } from "./sharedTypes"

export function fetchSuppliers(
  catalogPackageId: number,
  yourOrganizationsSuppliersOnly: boolean,
  selectedServiceAreaState: string,
  packageConfigurationId: string
): Promise<AvailableSuppliers> {
  return get(availableSuppliersUrl(), {
    catalogPackageId,
    yourOrganizationsSuppliersOnly,
    selectedServiceAreaState,
    packageConfigurationId,
  }).then(({ data }) => data)
}
