// @team @cat-crew

export const attributesUrl = (): string => {
  return "/cms/catalog/attributes"
}

export const attributesCreateFormPageUrl = (): string => {
  return "/cms/catalog/attributes/new"
}

export const attributeDetailUrl = (id: string): string => {
  return `/cms/catalog/attributes/${id}`
}
