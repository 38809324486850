// @team @cat-crew

import { get } from "../../../services/api"
import { CatalogPackagingLabel } from "../types/sharedTypes"
import { packagingLabelsUrl } from "../urls/packagingLabels"

export type PackagingLabelsResponse = {
  packagingLabels: CatalogPackagingLabel[]
}

export const getAllPackagingLabels = async (): Promise<
  PackagingLabelsResponse
> => {
  return await get(packagingLabelsUrl("json"))
    .then((response) => response.data)
    .catch((data) => data.response.data)
}
