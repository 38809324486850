import { Chip } from "@material-ui/core"
import classNames from "classnames"
import * as styles from "./ActionableOrderCard.module.scss"
import React from "react"

interface Props {
  cardStatus: { type?: string; text: string }
  classes?: { label: string }
}

const StatusChip: React.FC<Props> = ({ cardStatus, classes }: Props) => {
  const chipStatusClass = (statusType) => ({
    [styles.snoozeChip]: statusType === "snooze",
    [styles.errorChip]: statusType === "error",
    [styles.warningChip]: statusType === "warning",
    [styles.successChip]: statusType === "success",
  })

  return (
    <div className={classNames("d-flex justify-content-end")}>
      {cardStatus.type && (
        <Chip
          label={cardStatus.text}
          className={classNames(styles.chip, chipStatusClass(cardStatus.type))}
          classes={classes}
          variant="outlined"
          data-testid="status-chip"
        />
      )}
    </div>
  )
}

export default StatusChip
