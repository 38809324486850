import React from "react"
import {
  CarrierLineItemAuthorization,
  Doctor,
} from "../../../../../graphql/__generated__/graphql"
import * as styles from "./AuthorizationRequestLineItem.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { LineItemDetail } from "./LineItemDetail/LineItemDetail"
import { LINE_ITEM_DETAILS } from "./constants/lineItemDetails"
import { isNullOrUndefined } from "../../../../../utilities/isNullOrUndefined"

interface AuthorizationLineItemProps {
  lineItem: CarrierLineItemAuthorization
  orderingPhysician: Doctor | undefined
  onEditClick: () => void
  showSupplierInteractions: boolean
}

export const AuthorizationRequestLineItemCard = ({
  lineItem,
  orderingPhysician,
  onEditClick,
  showSupplierInteractions,
}: AuthorizationLineItemProps) => {
  const lineItemHeading = `Prior auth line item: ${lineItem.hcpcs}`
  return (
    <div role="grid" className={styles.authCard}>
      <div className={styles.authCardHeader}>
        <h4 className="canopy-m-0 canopy-py-4x canopy-typography-heading-large">
          {lineItemHeading}
        </h4>
        {!!showSupplierInteractions && (
          <CanopyButton
            variant="secondary"
            size="small"
            onClick={() => onEditClick()}
          >
            Edit
          </CanopyButton>
        )}
      </div>
      <div className={styles.authCardBody}>
        {Object.keys(LINE_ITEM_DETAILS).map((keyName, index) => (
          <LineItemDetail
            key={index}
            title={LINE_ITEM_DETAILS[keyName].title}
            displayValue={
              (!!lineItem[keyName] &&
                LINE_ITEM_DETAILS[keyName].formatFn?.(lineItem[keyName])) ||
              lineItem[keyName]
            }
            requiredField={LINE_ITEM_DETAILS[keyName].required}
          />
        ))}

        <LineItemDetail
          title="Ordering physician"
          displayValue={
            !isNullOrUndefined(orderingPhysician)
              ? `${orderingPhysician?.name}${
                  orderingPhysician?.credential
                    ? `, ${orderingPhysician?.credential}`
                    : ""
                }`
              : orderingPhysician
          }
          requiredField={true}
        />
      </div>
    </div>
  )
}
