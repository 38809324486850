import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyForm } from "@parachutehealth/canopy-form"
import DatePickerInput, { formatDate } from "components/input/DatePickerInput"
import React, { useState } from "react"
import { Formik, FormikValues } from "formik"
import {
  CarrierLineItemAuthorization,
  CarrierLineItemAuthorizationUpdateMutationVariables,
} from "../../../../../graphql/__generated__/graphql"
import * as styles from "./LineItemEditForm.module.scss"
import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { isEmpty } from "lodash"
import {
  formatHcpcsModifiersObject,
  formatHcpcsModifiersString,
} from "../../utilities/hcpcsModifiersFormatters"

interface LineItemEditFormProps {
  onCancel: () => void
  onDelete: (lineItemId: string) => void
  onSubmit: (
    updatedFields: CarrierLineItemAuthorizationUpdateMutationVariables
  ) => void
  lineItem: CarrierLineItemAuthorization
  showPreviousAuthInput?: boolean
}

const PRIORITY_OPTIONS = [
  {
    label: "Routine",
    value: "routine",
  },
  {
    label: "Urgent",
    value: "urgent",
  },
]

export const LineItemEditForm = ({
  onCancel,
  onSubmit,
  onDelete,
  lineItem,
  showPreviousAuthInput,
}: LineItemEditFormProps) => {
  const hcpcDetailsString = !isEmpty(lineItem.hcpcsDetails)
    ? formatHcpcsModifiersString(lineItem.hcpcsDetails)
    : undefined

  const initialValues = {
    hcpcs: lineItem.hcpcs,
    hcpcsModifiers: hcpcDetailsString,
    quantity: lineItem.quantity,
    unitsOfMeasure: lineItem.unitsOfMeasure,
    startDate: formatDate(lineItem.startDate),
    endDate: formatDate(lineItem.endDate),
    priority: lineItem.priority,
  }
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const validate = (values) => {
    const errors = {}
    if (!values.hcpcs) {
      errors["hcpcs"] = "HCPCS required"
    }

    if (!values.quantity) {
      errors["quantity"] = "Quantity required"
    }

    if (!values.startDate) {
      errors["startDate"] = "Start date required"
    }

    if (!values.endDate) {
      errors["endDate"] = "End date required"
    }

    if (!values.priority) {
      errors["priority"] = "Selection required"
    }

    return errors
  }

  const handleOnSubmit = async (values: FormikValues) => {
    const {
      hcpcs,
      hcpcsModifiers,
      quantity,
      unitsOfMeasure,
      startDate,
      endDate,
      priority,
    } = values

    const hcpcsDetails = hcpcsModifiers
      ? formatHcpcsModifiersObject(hcpcsModifiers)
      : null

    const valuesToSubmit = {
      id: lineItem.id,
      hcpcs,
      quantity,
      unitsOfMeasure,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      priority,
    }
    if (hcpcsDetails) {
      valuesToSubmit["hcpcsDetails"] = hcpcsDetails
    }

    onSubmit(valuesToSubmit)
  }

  const handleDelete = async () => {
    onDelete(lineItem.id)
  }

  return (
    <>
      <Formik
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => {
          return (
            <CanopyForm id={lineItem.id} onSubmit={handleSubmit}>
              {/* TODO: Replace all sections with CanopyFlex when released*/}
              <CanopyTextInputField
                name="hcpcs"
                label="HCPCS"
                className={styles.inputField}
                onChange={handleChange}
                value={values.hcpcs}
                required
                feedbackMessage={
                  touched.hcpcs ? (errors.hcpcs as string) : undefined
                }
              />
              <CanopyTextInputField
                name="hcpcsModifiers"
                label="HCPCS modifier(s)"
                className={styles.inputField}
                description="If multiple, separate by comma"
                value={values.hcpcsModifiers}
                onChange={handleChange}
              />
              <section className={styles.formSection}>
                <CanopyTextInputField
                  name="quantity"
                  label="Quantity"
                  value={values.quantity.toString()}
                  onChange={handleChange}
                  type="number"
                  required
                  feedbackMessage={
                    touched.quantity ? (errors.quantity as string) : undefined
                  }
                />
                <CanopyTextInputField
                  name="unitsOfMeasure"
                  label="Unit of measure"
                  value={values.unitsOfMeasure}
                  onChange={handleChange}
                />
              </section>
              <section className={styles.formSection}>
                <CanopyFormFieldGroup
                  label="Start date"
                  id="startDate"
                  required
                  feedbackMessage={
                    touched.startDate ? (errors.startDate as string) : undefined
                  }
                >
                  {({ primaryControlId }) => (
                    <DatePickerInput
                      id={primaryControlId}
                      name="startDate"
                      value={values.startDate}
                      onChange={handleChange}
                    />
                  )}
                </CanopyFormFieldGroup>

                <CanopyFormFieldGroup
                  label="End date"
                  id="endDate"
                  required
                  feedbackMessage={
                    touched.endDate ? (errors.endDate as string) : undefined
                  }
                >
                  {({ primaryControlId }) => (
                    <DatePickerInput
                      id={primaryControlId}
                      name="endDate"
                      value={values.endDate}
                      onChange={handleChange}
                    />
                  )}
                </CanopyFormFieldGroup>
              </section>

              {showPreviousAuthInput && (
                <CanopyTextInputField
                  name="previousAuthorizationNumber"
                  label="Previous auth number"
                />
              )}
              <CanopyRadioInputField
                label="Priority"
                name="priority"
                options={PRIORITY_OPTIONS}
                value={values.priority ? values.priority : undefined}
                onChange={handleChange}
                className={styles.radioInput}
                required
                feedbackMessage={
                  touched.priority ? (errors.priority as string) : undefined
                }
              />
              <p className={styles.urgentMessage}>
                “Urgent” is defined as requiring immediate action to prevent a
                serious deterioration of a member’s health that results from an
                unforeseen illness or an injury, or could jeopardize the ability
                of the individual to regain maximum function.
              </p>

              <div className={styles.formButtonSection}>
                <CanopyButton variant="primary" type="submit">
                  Save
                </CanopyButton>
                <CanopyButton variant="tertiary" onClick={() => onCancel()}>
                  Cancel
                </CanopyButton>

                <CanopyButton
                  variant="secondary"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Delete line item
                </CanopyButton>
              </div>
            </CanopyForm>
          )
        }}
      </Formik>
      <CanopyDialogModal
        header={`Delete line item ${lineItem.hcpcs}`}
        onClose={() => setShowDeleteConfirmation(false)}
        open={showDeleteConfirmation}
        primaryFooterButton={
          <CanopyButton variant="danger" onClick={handleDelete}>
            Delete
          </CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton
            variant="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </CanopyButton>
        }
        size="small"
      >
        Are you sure you would like to delete Prior auth line item:{" "}
        {lineItem.hcpcs}? This action cannot be undone.
      </CanopyDialogModal>
    </>
  )
}
